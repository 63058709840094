import React, {ReactNode} from "react"
import {PaidVideo} from "../../post-blocks/BlockTypes";
import styled from "styled-components";
import {ReactBigPicture} from "../ReactBigPicture";
import * as PostBlocks from '../../post-blocks/BlockTypes'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useChatContext} from "../../context/ChatContext";

const PaidDiv = styled.div`
  
    display: flex;
    flex-flow: column nowrap;
    margin-left: 20px;
  
    img {
      max-height: 200px;
      max-width: 200px;
    }
  
    .title {
      width: 100%;
      color: gray;
    }
 
    &.paid_video_locked {
    }
  
    &.paid_video_unlocked {
    }
  
`

export const PaidVideoDiv = (props: {
    blk: PaidVideo,
    titleEditable ?: boolean,
    onTitleChange ?: (s: string) => void,
    onlyMedia ?: boolean,
}) => {

    const { chat } = useChatContext()

    const { blk } = props

    // let lastImageUrl = ""
    const skip : number[] = []
    
    const renderImageOrVideo = (x : PostBlocks.Image|PostBlocks.Video, key: number,
                                [nextBlock]: (PostBlocks.Image|PostBlocks.Video)[]) : ReactNode => {
        if (x.type === "image") {
            if (skip.indexOf(key)>=0) return; // image was used as video poster
            // lastImageUrl = x.image_url
            return <ReactBigPicture key={key} type="image" src={x.image_url} style={{minHeight: 200, }}>
                <img src={x.image_url} alt="paid video" loading="lazy" />
            </ReactBigPicture>
        } else {
            let u : ReactNode|string = x.poster_url
            if (!u && nextBlock && nextBlock.type === "image") {
                u = nextBlock.image_url
                skip.push(key+1)
            }
            if (!u) {
                u = <FontAwesomeIcon icon={"video"} size={"3x"} />
            }
            return <ReactBigPicture key={key} type="video" src={x.video_url} style={{ minHeight: 200, }}>
                { typeof u === "string" ?
                    <img src={u as string} alt={"video"} loading="lazy" /> :
                    u }
            </ReactBigPicture>
        }
    }

    if (!blk.paid) {
        const payUrl = blk.paymentLink
        return <PaidDiv className={"video_locked"}>
            {!props.onlyMedia ? <div className="title">
                <FontAwesomeIcon icon={"lock"} />
                &nbsp;
                <div
                    contentEditable={props.titleEditable} suppressContentEditableWarning={true}
                    onInput={ev => props.onTitleChange ? props.onTitleChange((ev.target as HTMLDivElement).innerText) : null } >
                {blk.title}</div>
            </div> : null }
            {payUrl && payUrl.length ?
                <button onClick={() => chat.redirect(payUrl) }>
                    Unlock Content for ${blk.price}
                </button> : null }
            { blk.previews.map((sblk, idx) => { return renderImageOrVideo(sblk, idx,
                blk.previews.slice(idx+1, idx+2)
            ) } ) }
        </PaidDiv>
    } else {
        return <PaidDiv className={"video_unlocked"}>
            {!props.onlyMedia ? <div className="title unlocked">
                <FontAwesomeIcon icon={"lock-open"} />
                &nbsp;
                {blk.title}
            </div> : null }
            { blk.previews.map((sblk, idx) => { return renderImageOrVideo(sblk, idx,
                blk.previews.slice(idx+1, idx+2)
            ) } ) }
            { blk.protected.map((sblk, idx) => { return renderImageOrVideo(sblk, idx,
                blk.protected.slice(idx+1, idx+2)
            ) } ) }
        </PaidDiv>
    }
}