import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react"
import styled from "styled-components";
import {GeneratedAvatar} from "./GeneratedAvatar";
import {observer} from "mobx-react";
import {useChatContext} from "../context/ChatContext";
import {RelTime} from "./RelTime";
import {tagsDisplay} from "src/utils/tagUtils";

const lastMessageLen = 40

const ChannelDiv = styled.div<{active:boolean}>`
  border-bottom: solid 1px var(--border-color);
  padding: 12px;
  height: 50px;
  background-color: ${p => p.active ? 'var(--active-channel-color)' : 'inherit' };
  overflow: hidden;
  
  img {
    border-radius: 1000px;
    width: 50px;
    height: 50px;
  }

  .title {
    font-weight: bold;
    padding-left: 10px;
    line-height: 30px;
  }

  .last, .lasttime {
    color: var(--gray-color);
    font-size: 12px;
    padding-left: 10px;
  }
  
  .lasttime {
    float: right;
  }
  
  .last-unread {
    color: black;
    font-weight: bold;
  }
  
  .lasttime-unread {
    color: blue;
    font-weight: bold;
  }
  
  .username {
     font-size: smaller;
  }
  
  .tag {
    margin-left: 4px;
    font-size: smaller;
    background-color: lemonchiffon;
    color: #777;
    padding: 2px 5px;
  }

  .avatar_block {
    float: left;
  }
  
`

type ChannelListItemProps = {
    name: string
    username: string|undefined
    channelId: number
    images: string[]
    avatar: string
    unread: number
    active: boolean
    isDirect: boolean
    lastTime: Date|null
    lastMessage: string
    lastMessageContainsMedia?: boolean
    lastMessageUnread?: boolean
    tags: string[]
}

export const ChannelsListItem = observer((props: ChannelListItemProps) => {

    const {chat} = useChatContext()

    const {lastMessage, lastMessageContainsMedia, lastMessageUnread, lastTime,
        active, avatar, name, username} = props

    let msg = lastMessage
    if (msg.length>lastMessageLen) { msg = msg.substr(0, lastMessageLen-3) + "..." }

    const onClick = (channelId: number) => {
        chat.setActiveChannel(channelId)
    }

    const tag = tagsDisplay(props.tags)

    return (
        <ChannelDiv active={active} onClick={() => onClick(props.channelId) }>
            <div className={"avatar_block"}>
                { avatar ? <img src={avatar} alt={name} /> : <GeneratedAvatar str={name}></GeneratedAvatar> }
            </div>
            {/*<a href="index#tt" style={{ float: "right", textDecoration: 'none', color: 'unset' }}>x</a>*/}
            <span className="title">{name}</span>
            { username ? <span className="username">&nbsp;@{username}</span> : null }
            { tag ? <span className="tag">{tag}</span> : null }
            <br />
            <span className={lastMessageUnread ? "lasttime lasttime-unread" : "lasttime"}>
                {lastTime? <RelTime date={lastTime} /> : null }
                {lastMessageUnread ? (<>&nbsp;<FontAwesomeIcon icon={"circle"}/></>) : null }
            </span>
            <span className={lastMessageUnread ? "last last-unread" : "last"}>
                { !lastMessageContainsMedia ? null :
                    <><FontAwesomeIcon icon={"image"} /> </>
                }
                {msg}
            </span>
        </ChannelDiv>
    )
})