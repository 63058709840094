
export const UtilLiveChatIcon = (props: {width: number, height: number}) =>
		<svg
			width={props.width} height={props.height}
			xmlns="http://www.w3.org/2000/svg"
			x="0"
			y="0"
			enableBackground="new 0 0 511.999 511.999"
			version="1.1"
			viewBox="0 0 511.999 511.999"
			xmlSpace="preserve"
		>
			<path
				fill="#CEE8FA"
				d="M497.847 428.814V219.932H241.394v208.882h130.583c15.255 30.395 47.302 47.583 79.916 44.968a83.909 83.909 0 01-2.408-5.152c-5.49-13.027-7.36-26.671-6.073-39.817h54.435v.001z"
			></path>
			<g fill="#2D527C">
				<path d="M445.372 488.197c-33.645 0-64.185-17.122-81.671-45.229H241.394c-7.816 0-14.154-6.337-14.154-14.154V219.932c0-7.817 6.338-14.154 14.154-14.154h256.452c7.816 0 14.154 6.337 14.154 14.154v208.882c0 7.817-6.338 14.154-14.154 14.154h-40.373a68.287 68.287 0 005.056 20.168c.532 1.262 1.168 2.62 2.001 4.273a14.15 14.15 0 01-11.504 20.483c-2.536.203-5.11.305-7.654.305zm-189.824-73.536h116.431c5.353 0 10.249 3.02 12.651 7.804 9.449 18.826 26.673 31.836 46.742 35.996-2.352-10.152-3.06-20.659-2.044-31.025.709-7.248 6.804-12.775 14.086-12.775h40.28V234.085H255.548v180.576z"></path>
				<path d="M54.74 394.847c-15.798 0-31.704-3.371-46.575-10.318A14.15 14.15 0 01.171 373.89c-.729-4.657.91-9.37 4.368-12.571 1.632-1.51 2.926-2.781 4.072-3.997 21.707-23.035 28.248-55.764 17.071-85.413a14.143 14.143 0 01-.776-3.052c-8.41-21.065-12.672-43.326-12.672-66.229 0-98.604 80.222-178.826 178.826-178.826 67.998 0 129.241 37.758 159.829 98.539 3.513 6.982.702 15.491-6.281 19.006-6.979 3.512-15.493.703-19.006-6.281-25.75-51.168-77.304-82.956-134.543-82.956-82.997 0-150.518 67.523-150.518 150.518 0 20.37 3.997 40.123 11.879 58.709.585 1.377.94 2.819 1.069 4.274 11.51 34.024 5.936 70.652-14.618 99.388 26.448 5.223 54.465-2.838 74.069-22.422a14.148 14.148 0 0115.865-2.87 149.339 149.339 0 0048.657 12.833c7.786.696 13.532 7.574 12.835 15.36-.696 7.786-7.565 13.541-15.36 12.835a177.648 177.648 0 01-49.158-11.579c-20.147 16.843-45.448 25.691-71.039 25.691z"></path>
			</g>
		</svg>
