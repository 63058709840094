import React, {useState} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components";
import {OnlineCircle} from "./OnlineCircle";
import {RelTime} from "./RelTime";

const ChannelHeaderDiv = styled.div`
    padding-bottom: 12px;
    border-bottom: solid 1px var(--border-color);
    padding-left: 12px; 
    padding-top: 14px;
  
    font-size: 18px;
    font-weight: 600;

   .username {
     padding-left: 5px;
     font-weight: normal;
     font-size: smaller;
   }


  .header_second {
       padding-top: 10px;
       font-size: 12px;
       font-weight: normal;
       color: var(--gray-color);
       padding-left: 24px;
    }    
`

const EditableTag : React.VFC<{value: string, onChange: (v: string) => void}> = ({value, onChange}) => {

    const [editorEnabled, toggleEditor] = useState(false)
    const [editorValue, setEditorValue] = useState(value)

    const submit = () => { onChange(editorValue); toggleEditor(false); }

    return <>
        {editorEnabled ?
            <>
            <span>
                &nbsp;
                <input type="text"
                       size={25}
                       placeholder="note, visible to admin only"
                       value={editorValue} onChange={ev => setEditorValue(ev.target.value) }
                       onKeyPress={ev => ev.key === "Enter" ? submit() : null }
                       style={{ borderColor: "#EEE"}}
                />
                <button style={{ borderColor: "#EEE"}} onClick={ submit }>&#9166;</button>
            </span>
            </>
        :  <span onClick={() => toggleEditor(!editorEnabled) } >
                <FontAwesomeIcon icon="sticky-note" style={{ color: "yellowgreen"}} />
                {" "}
                <i>{value}</i>
           </span>
        }
    </>
}

export function ChannelHeaderView(
   props: {
        name: string,
        description ?: string,
        avatar: string,
        avatarSize: number,
        isDirectUser: boolean,
        // folllowing for directUser only
        username ?: string,
        statusOnline ?: boolean,
        status?: string|Date, // Last seen 3 hours ago | Available Now
        onBackClick ?: () => void,
        onSetDescription ?: (v: string) => void,
    }) {

    const status =
        typeof props.status === "string" ? props.status :
            (props.status ? <RelTime date={props.status} /> : null)

    return (
        <ChannelHeaderDiv>
            { props.onBackClick ? <FontAwesomeIcon icon="undo-alt" onClick={props.onBackClick}/> : null }
            {/*<span className="pcchat__chat__header__menu"  style={{float: 'right', position: 'relative', right: '-80px;'}} >*/}
            {/*    <FontAwesomeIcon icon="ellipsis-h"/>*/}
            {/*</span>*/}
            &nbsp;&nbsp;&nbsp;
            {props.name}
            {props.username ? <span className="username">{"@" + props.username}</span> : null}
            <div className="header_second">
                {props.isDirectUser ?
                        <OnlineCircle online={props.statusOnline || false} />
                        : null
                }
                &nbsp;{status}
                &nbsp;
                { props.onSetDescription ?
                    <EditableTag key={props.name+props.username} value={props.description ?? ""} onChange={ v => props.onSetDescription!(v) } /> :
                    null }
                {/*&nbsp;&nbsp;|&nbsp;&nbsp;*/}
                {/*<FontAwesomeIcon icon="star" />*/}
                {/*&nbsp;&nbsp;|&nbsp;&nbsp;*/}
                {/*<FontAwesomeIcon icon="bell" />*/}
                {/*&nbsp;&nbsp;|&nbsp;&nbsp;*/}
                {/*<FontAwesomeIcon icon="images" />&nbsp;Gallery*/}
               {/* &nbsp;&nbsp;|&nbsp;&nbsp;*/}
               {/*<FontAwesomeIcon icon="search" />&nbsp;Find*/}
            </div>
        </ChannelHeaderDiv>
    )
}