// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React from "react"
// import styled from "styled-components";
//
//
// const FilterDiv = styled.div`
//     color: var(--gray-color);
//     font-size: 18px;
//     font-weight: 800;
//     padding: 10px 10px;
//     padding-bottom: 30px;
//     border-bottom: solid 1px var(--border-color);
//
//     svg { float: right; }
// `

export function ChannelsListFilter(props: {}) {
    return ( null
        // <FilterDiv>
        //     <FontAwesomeIcon icon="ellipsis-v" />
        // </FilterDiv>
    )
}
