import {MessageType, UnknownMessage, messageTypeRegistry} from "./typeRegistry";
import {ConsoleLogger, Logger, NullLogger} from "./logger";
import {PongResponse} from "./ngcht";

type FTokenCallback = (apiKey: string, pastToken: string) => string|null


type TPcchtClientOptions = {
    token: string
    tokenCallback ?: FTokenCallback | undefined
    wsUrl : string,
    debug?: boolean,
}

enum TWebSockStatus {
    NONE = "none", // at start
    CONNECTING = "connecting",
    OPEN = "open",
    CLOSING = "closing",
    CLOSED = "closed",
}

type ReqPromiseRecord = {
    resolve: (value: (UnknownMessage | PromiseLike<UnknownMessage>)) => void
    reject: (reason?: any) => void
}


export class PcchtClientEvent extends CustomEvent<PcchtClient> {
    // eslint-disable-next-line
    constructor(type: "open" | "close" | "message" | "error") {
        super(type);
    }
}

export class PcchtClientMessageEvent extends PcchtClientEvent {
    public readonly message: UnknownMessage
    public readonly messageType: MessageType

    // is it reply to message sent by this client or something else?
    // this can be used to do not handle same message twice
    public readonly isReply: boolean
    constructor(client: object, message: UnknownMessage, messageType: MessageType, isReply: boolean) {
        super("message");
        this.message = message
        this.messageType = messageType
        this.isReply = isReply
    }
}

export class PcchtClient {
    protected apiKey: string
    protected token : string
    protected tokenCallback : FTokenCallback | undefined
    protected wsUrl : string

    protected socket!: WebSocket
    protected status: TWebSockStatus

    protected lastReqId : number = 0;

    protected waitReqs : { [clientReqId: number] : ReqPromiseRecord } = {}

    protected log: Logger

    static instances: { [apiKey: string] : PcchtClient } = {}

    protected listeners : {[eventType: string] : EventListenerOrEventListenerObject[] } = {}

    private connectResolve?: (value: PongResponse | PromiseLike<PongResponse>) => void
    private connectReject?: (reason ?: any) => void

    ///////////////////////////////////

    static _clearInstances() {
        PcchtClient.instances = {}
    }

    static getInstance(apiKey: string, options: TPcchtClientOptions) : PcchtClient {
        if (!PcchtClient.instances[apiKey]) {
            PcchtClient.instances[apiKey] = new PcchtClient(apiKey, options)
        }
        return PcchtClient.instances[apiKey]
    }

    constructor(apiKey: string , options: TPcchtClientOptions) {
        this.apiKey = apiKey
        this.token = options.token
        this.tokenCallback = options.tokenCallback
        this.wsUrl = options.wsUrl + "?jwt=" + this.token
        this.status = TWebSockStatus.NONE
        this.log = options.debug ? new ConsoleLogger() : new NullLogger();
    }

    addEventListener(type: "open" | "close" | "message" | "error", callback: EventListenerOrEventListenerObject) {
        this.listeners[type] = [...this.listeners[type]??[], callback]
    }
    dispatchEvent(event: PcchtClientEvent ) {
        if (!this.listeners[event.type]) return
        for (const listener of this.listeners[event.type]) {
            if ((listener as any).handleEvent) {
                (listener as any).handleEvent.apply(listener, [event])
            } else {
                (listener as any).apply(listener, [event])
            }
        }
    }

    protected setStatus(status: TWebSockStatus) {
        this.status = status
        this.log.debug('client status:', status)
    }

    public async connect() : Promise<PongResponse> {
        return new Promise<PongResponse>((resolve, reject) => {
            if (this.status === TWebSockStatus.CLOSED) // due to error ?
                this.status = TWebSockStatus.NONE
            if (this.status === TWebSockStatus.CONNECTING)
            {
                reject('already connecting')
            }
            if (this.status === TWebSockStatus.NONE) {
                if (typeof this.socket === 'object') {
                    reject('already connecting')
                }

                this.socket = new WebSocket(this.wsUrl)
                this.status = TWebSockStatus.CONNECTING
                this.connectResolve = resolve
                this.connectReject = reject

                this.socket.addEventListener('open', () => {
                    this.setStatus(TWebSockStatus.OPEN)
                    this.dispatchEvent(new PcchtClientEvent("open"))
                    // resolve(this)
                    // this.initConnection()
                })

                this.socket.addEventListener('close', () => {
                    this.setStatus(TWebSockStatus.CLOSED)
                    if (this.connectResolve && this.connectReject) {
                        const reject = this.connectReject
                        this.connectResolve = undefined
                        this.connectReject = undefined
                        reject('websocket_closed')
                    }
                    this.dispatchEvent(new PcchtClientEvent("close"))
                })

                this.socket.addEventListener('error', (x) => {
                    this.dispatchEvent(new PcchtClientEvent('error'))
                    this.setStatus(TWebSockStatus.CLOSING)
                })

                this.socket.addEventListener('message', (ev: MessageEvent) => {
                    this.received(JSON.parse(ev.data))
                })
            } else if (this.status === TWebSockStatus.OPEN) {
                // resolve(this)
            } else {
                reject('status is already:' + this.status)
            }
        })
    }

    public send(m: UnknownMessage ) : Promise<UnknownMessage> {
        const clientReqId = ++this.lastReqId
        const promise = new Promise<UnknownMessage>((resolve, reject) => {
            if (this.status !== TWebSockStatus.OPEN) {
                reject("Invalid connection status: " + this.status.toString())
            }
            try {
                const t = messageTypeRegistry.get(m.$type) as MessageType
                const x = t.toJSON(m) as object
                const _ = JSON.stringify({...x, $type: m.$type,  $clientReqId: clientReqId})
                this.log.debug('sent:', _)
                this.waitReqs[clientReqId] = { resolve, reject }
                this.socket.send(_)
            } catch (err) {
                console.log(err, typeof err)
                reject(err)
            }
        })
        return promise
    }

    protected received(m: UnknownMessage) {
        this.log.log('received', m)

        // we are waiting for first message
        if (this.connectResolve && this.connectReject) {
            const [resolve, reject] = [this.connectResolve, this.connectReject]
            this.connectResolve = undefined
            this.connectReject = undefined
            if (m.$type === 'ngmsg.PongResponse') {
                resolve(m as PongResponse)
            } else {
                reject(m)
            }
            return
        }

        const clientReqId = (m as any).$clientReqId as any
        let foundWait : ReqPromiseRecord | undefined
        if ((clientReqId>0)) {
            foundWait = this.waitReqs[clientReqId]
        }

        const t = messageTypeRegistry.get(m.$type)
        if (typeof t != 'undefined')
        {
            this.dispatchEvent(new PcchtClientMessageEvent(this, m, t, foundWait ? true : false))

            if (foundWait) {
                const ok = (m as any).ok
                if (ok === true) {
                    foundWait.resolve(m)
                } else {
                    foundWait.reject(m)
                }
            }
        } else {
            this.log.error('invalid message, unknown or empty type:', m.$type, m)
            if (foundWait) {
                foundWait.reject(m)
            }
        }
    }

}


