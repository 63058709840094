import React, { useEffect, useRef} from "react"
import {observer} from "mobx-react";
import {ChannelMessagesItem} from "./ChannelMessagesItem";
import { Scrollbar } from "react-scrollbars-custom";
import {useChatContext} from "../context/ChatContext";
import {ChannelMessagesTyping} from "./ChannelMessagesTyping";
import {UserRecord} from "../store/UserRecord";
import {PostRecord} from "src/store/PostRecord";

export const ChannelMessages = observer(() => {
    const {chat} = useChatContext()

    const activeChannel = chat.getActiveChannel()

    let { posts, typingUsers } = activeChannel || {
        posts: [],
        typingUsers: {} as { [userId:number]: UserRecord }
    }

    const endDiv = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const scrolltoLast = () => {
            endDiv.current?.scrollIntoView()
            if (activeChannel) activeChannel.markRead()
        }
        scrolltoLast()
        document.addEventListener('chat.img.load', (ev) => {
            // delay scrolls with promises
            setTimeout(scrolltoLast, 100);
        })
        return () => { document.removeEventListener('chat.img.load', scrolltoLast) }
    }, [posts.length, typingUsers, activeChannel])

    let doDeleteMessageHoc = ( p: PostRecord ) => {
        if (!p.mine) return null
        return () => {
            chat.getActiveChannel()?.sendDeleteRequest(p.postId)
        }
    }
    let iamAdmin = false
    if (!chat.me?.getUserCan("create_paid_posts")) {
        iamAdmin = true
        doDeleteMessageHoc = () => null
    }



    const scrl = (
        <Scrollbar
            className={"chat__messages__scroll"}
            style={{flex: '1 1 auto', }}
            // onUpdate={(sc,sc1) => console.log({sc, sc1})} // todo disable scrolltolast when manually scrolled
            noScrollX={true}
        >
            {
                // todo fix key=.. here for submitted posts
                posts.map((p) => <ChannelMessagesItem key={p.postId || JSON.stringify(p.post)}
                    mineStatus={p.mineMessageStatus}
                    isAdminMessage={ !iamAdmin || p.mine }
                    avatar={p.avatar} userName={p.username} userUsername={p.userFullName}
                    doDeleteMessage={ doDeleteMessageHoc(p) }
                    mine={p.mine} body={p.post} isJson={p.isJson} time={p.time.toString()} />
                )
            }
            {
                Object.keys(typingUsers).length ?
                    <ChannelMessagesTyping users={Object.values(typingUsers)} /> : null
            }
            <div ref={endDiv} style={{width: 1, height: 1, opacity: 0}}/>
        </Scrollbar>
    )

    return scrl
})