import {makeAutoObservable} from "mobx";
import {ChatStore} from "./ChatStore";
import {ChannelRecord} from "./ChannelRecord";
import {ClientPostData} from "../client/ngcht";
import {UserRecord} from "./UserRecord";


export enum EnumPostRecordStatus {
    None = 0,
    Submitting = 1,
    Submitted = 2,
    Error = 3,
}

export class PostRecord {
    postId: number = 0
    post:  string = ""
    userId: number = 0
    username: string = ""
    userFullName: string = ""
    avatar: string = "" // url
    mine: boolean
    time: Date
    isJson: boolean

    mineMessageStatus: EnumPostRecordStatus = EnumPostRecordStatus.None


    private chatStore: ChatStore
    private channelStore: ChannelRecord

    constructor(chatStore: ChatStore, channelStore: ChannelRecord, source: ClientPostData, user: UserRecord) {
        this.chatStore = chatStore
        this.channelStore = channelStore

        this.postId = source.postId
        this.userId = source.userId
        this.post = source.post
        this.isJson = source.isJson
        this.time = new Date(source.time * 1000)

        this.mine = source.userId === chatStore.myUserId

        // if !user -> set from fixed dead user record

        this.avatar = user.avatar
        this.username = user.username
        this.userFullName = user.name

        makeAutoObservable(this)
    }

    updateFromPostResponse(post: string, status: EnumPostRecordStatus) {
        console.log('replace post with', {post, status})
        this.post = post
        this.mineMessageStatus = status
    }

    getHaveMedia() : boolean {
        if (this.isJson) {
            for (const blk of JSON.parse(this.post)?.blocks) {
                if (blk?.type !== 'plain_text') {
                    return true
                }
            }
        }
        return false
    }

    getPlainText() : string {
        if (this.isJson) {
            for (const blk of JSON.parse(this.post)?.blocks) {
                if (blk?.type === 'plain_text') {
                    return blk.text
                }
            }
            return ""
        }
        return this.post
    }

}

