import React from "react"
import {Channels} from "../components/Channels";
import {ChannelsListHeader} from "../components/ChannelsListHeader";
import {ChannelsListFilter} from "../components/ChannelsListFilter";
import {ChannelsListItems} from "../components/ChannelsListItems";
import {Channel} from "../components/Channel";
import {ChannelHeader} from "../components/ChannelHeader";
import {ChannelMessages} from "../components/ChannelMessages";
import {ChannelMessageInput} from "../components/ChannelMessageinput/ChannelMessageInput";

import "./OfLayout.scss"
import {observer} from "mobx-react";
import {useChatContext} from "../context/ChatContext";
import {EditorPlaintext} from "../components/ChannelMessageinput/EditorPlaintext";
import {EditorCustomerTip} from "../components/ChannelMessageinput/EditorCustomerTip";
import {EditorMediaUploader} from "../components/ChannelMessageinput/EditorMediaUploader";
import {EditorPaidPost} from "../components/ChannelMessageinput/EditorPaidPost";
import {EditorSelectPaidPost} from "../components/ChannelMessageinput/EditorSelectPaidPost";
import {EditorNoEditor} from "../components/ChannelMessageinput/EditorNoEditor";
import {MediaBrowserPickerPanel} from "../components/MediaBrowser/MediaBrowserPickerPanel";
import {EditorMediaBrowserPreview} from "../components/ChannelMessageinput/EditorMediaBrowserPreview";


const customerEditors = [
    { id: "text", title: "Text", editor: <EditorPlaintext /> },
    { id: "customer-tip", icon: "dollar-sign", title: "Send Tip", editor: <EditorCustomerTip /> },
    { id: "media-upload", icon: "image", title: "Media Upload",   editor: <EditorMediaUploader /> }
]

const modEditors = [
    { id: "text", title: "Text", editor: <EditorPlaintext /> },
    { id: "paid-post", icon: "comments-dollar", title: "Paid Post", editor: <EditorPaidPost /> },
    { id: "select-paid-post", icon: "file-invoice-dollar", title: "Select Paid Post", editor: <EditorSelectPaidPost /> },
    { id: "media-upload", icon: "image", title: "Media Upload",   editor: <EditorMediaUploader /> },
    { id: "media-picker", icon: "plus", title: "Media Picker",   editor: <EditorMediaBrowserPreview /> },
]

export const OfLayout = observer(() => {

    const {chat} = useChatContext()

    let editors : React.ReactNode = null
    let picker : React.ReactNode = null
    if (chat.me?.getUserCan("create_paid_posts")) {
        picker = <MediaBrowserPickerPanel />
        editors = <ChannelMessageInput>{modEditors}</ChannelMessageInput>
    } else if (chat.me?.getUserCan("message")) {
        editors = <ChannelMessageInput>{customerEditors}</ChannelMessageInput>
    } else {
        editors = <EditorNoEditor />
    }

    return <div className={"chat__ofull__layout"}>
        <div className="header">
            {picker}
        </div>
        {/*<main className="content">*/}
                <div className={"chat__channels " + (chat.getHaveActiveChannel() ? "" : "chat__active__page")}>
                    <Channels>
                        <ChannelsListHeader />
                        <ChannelsListFilter />
                        <ChannelsListItems />
                    </Channels>
                </div>
                <div className={"chat__channel " + (chat.getHaveActiveChannel() ? "chat__active__page" : "")}>
                    <Channel>
                        <ChannelHeader />
                        <ChannelMessages />
                        { editors }
                    </Channel>
                </div>
        {/*</main>*/}
    </div>
})