import React from "react"
import {CustomerTip} from "../../post-blocks/BlockTypes";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useChatContext} from "../../context/ChatContext";

const PaidDiv = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-left: 20px;
  
  
  .tip {
    color: #6B8068;
    font-weight: bold;
  }
  
  
`

export const PaidTipDiv = (props: { blk: CustomerTip }) => {

    const { chat } = useChatContext()

    const { blk } = props

    const content =  <>
        <span className="tip">
            {!blk.paid ? "Sending " : "Sent "} <FontAwesomeIcon icon={"dollar-sign"} />
            {blk.price}
        </span>
        </>

    const payUrl = (blk as any).paymentLink as string
    if (payUrl && !blk.paid) {
        return <PaidDiv className={""}>
            {payUrl && payUrl.length ?
                <button onClick={() => chat.redirect(payUrl) }>
                    Send Payment
                </button> : null }
            {content}
        </PaidDiv>
    } else {
        return <PaidDiv className={blk.paid ? "paid" : "not-paid"}>
            {content}
        </PaidDiv>

    }
}