import React, {useState} from "react"

import "./UserMediaViewLayout.scss"
import {observer} from "mobx-react";
import {useChatContext} from "../context/ChatContext";
import * as Blocks from '../post-blocks/BlockTypes'
import {PostBlocks} from "../components/PostBlocks/PostBlocks";

const NotPurchased = () => <div>
    You do not have any purchased photos or videos yet
</div>


const ChannelMediaItems = observer( (props: {
    showVideo: boolean,
    showPhoto: boolean,
}) => {
    const {chat} = useChatContext()
    const ch = chat.getActiveChannel()
    if (!ch) return null

    const blocks : (Blocks.Video | Blocks.Image | Blocks.PaidVideo)[] = []
    ch.posts.forEach((post) => {
        if (post.mine || !post.getHaveMedia()) return
        const bl = JSON.parse(post.post)
        if (bl && bl.blocks && typeof bl.blocks === "object") {
            bl.blocks.forEach((bl : Blocks.Types) => {
                if (props.showPhoto && bl.type === "image") {
                    blocks.push(bl)
                }
                if (props.showVideo && (bl.type === "video")) {
                    blocks.push(bl)
                }
                if (props.showVideo && (bl.type === "paid_video")) {
                    blocks.push(...bl.previews)
                    if (bl.paid) {
                        blocks.push(...bl.protected)
                    }
                }
            })
        }
    })

    return <>
        { (blocks && blocks.length) ? blocks.map((b, idx) =>
            <div className={"media_flexblock"}>
                {/*<img src="https://pcchat.s3.ap-south-1.amazonaws.com/media/MainSocialTeaser.thumb.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&amp;X-Amz-Credential=AKIAT5RICZTLDGGAOQMI%2F20211107%2Fap-south-1%2Fs3%2Faws4_request&amp;X-Amz-Date=20211107T140110Z&amp;X-Amz-Expires=900&amp;X-Amz-Signature=75ccc550d0ba9a9f203d938a65e13f0f12dc9e1f696230b48d4b6479577f66ed&amp;X-Amz-SignedHeaders=host"*/}
                {/*    alt="" className="chat_post_blocks_video_preview" />*/}
            <PostBlocks body={{ blocks: [b] }} key={idx} onlyMedia={true} />
            </div>
        ) : <NotPurchased />}
    </>
})


export const UserMediaViewLayout = () => {

    const [showPhoto, togglePhoto] = useState(true)
    const [showVideo, toggleVideo] = useState(true)

    return <div className={"chat__user_media_view__iframe"}>

        <div className={"filter"}>
            <label>
                <input type={"checkbox"} checked={showPhoto} onChange={ev => togglePhoto(!showPhoto)}/>
                Photo
            </label>
            &nbsp;&nbsp;&nbsp;
            <label>
                <input type={"checkbox"} checked={showVideo} onChange={ev => toggleVideo(!showVideo)}/>
                Video
            </label>
        </div>

        <main className="content">
            <ChannelMediaItems showVideo={showVideo} showPhoto={showPhoto}/>
        </main>
    </div>
}