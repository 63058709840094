

export interface Logger {
    debug(message?: any, ...optionalParams: any[]): void;
    error(message?: any, ...optionalParams: any[]): void;
    info(message?: any, ...optionalParams: any[]): void;
    log(message?: any, ...optionalParams: any[]): void;
}


export class NullLogger implements Logger {
    debug(message?: any, ...optionalParams: any[]): void { }
    error(message?: any, ...optionalParams: any[]): void { }
    info(message?: any, ...optionalParams: any[]): void { }
    log(message?: any, ...optionalParams: any[]): void {}
}

export class ConsoleLogger implements Logger {
    debug(message?: any, ...optionalParams: any[]): void { console.debug.apply(console, [message, ...optionalParams]) }
    error(message?: any, ...optionalParams: any[]): void { console.error.apply(console, [message, ...optionalParams]) }
    info(message?: any, ...optionalParams: any[]): void { console.info.apply(console, [message, ...optionalParams]) }
    log(message?: any, ...optionalParams: any[]): void { console.log.apply(console, [message, ...optionalParams]) }

}
