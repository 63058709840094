import {makeAutoObservable, runInAction} from "mobx";
import {TPcchtClient} from "./ChatStore";
import {CustomRequest, CustomResponse} from "../client/ngcht";
import * as Blocks from '../post-blocks/BlockTypes'

export type TMediaRecord = ( (Blocks.Image | Blocks.Video) & {last_modified:Date} )[]

export const UPLOADS_RECENT_UPLOADS = "..RecentUploads.."

export class UploadsStore {
    private client: TPcchtClient;

    loading: boolean = true
    folders: string[] = []
    folder: string = "" // current folder
    items: { [folder: string] : TMediaRecord[] } = {}

    itemsReload: { [folder:string] : number } = {}

    constructor(client: TPcchtClient) {
        this.client = client;
        makeAutoObservable(this)
    }

    isLoaded(folder: string = '/') {
        return this.folders.length !== 0
    }

    chdir(folder: string) {
        if (folder === this.folder) return
        this.folder = folder
        if (undefined === this.items[this.folder]) this.load(this.folder)
    }

    getItems() : undefined|TMediaRecord[] {
        if (this.folder === "")
        {
            this.chdir("/")
            return undefined // first run
        }
        const ret = this.items[this.folder]
        if (ret === undefined) {
            this.load(this.folder)
        } else {
            const nowTm = (new Date()).getTime()
            if (this.itemsReload[this.folder] && (nowTm>this.itemsReload[this.folder])) {
                this.load(this.folder)
            }
            return ret
        }
    }

    invalidate(folder: string) {
        delete this.items[folder]
    }

    load(folder : string) {
        const now = new Date()
        this.itemsReload[this.folder] = now.getTime()+1000*10 // do not ask reload in near 10 seconds until we get response
        const m = CustomRequest.fromJSON({})
        m.customRequestType = "browse_uploads"
        m.requestData = folder
        this.client.send(m).then(resp => {
            const rr = resp as CustomResponse
            const data = JSON.parse(rr.responseData) as {
                folders ?: string[],
                folder : string,
                items : TMediaRecord[],
            }

            runInAction(() => {
                if (!this.folders.length && data.folders) {
                    this.folders = data.folders!
                }
                if (data.folder && (data.items !== undefined))
                    this.items[data.folder] = data.items

                const reload = data.folder === UPLOADS_RECENT_UPLOADS ? 10 : 180
                const now = new Date()
                this.itemsReload[data.folder] = now.getTime()+1000*reload // 2minutes cache
            })
        })

    }

    getFolders() : string[] {
        return this.folders
    }

}