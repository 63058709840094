import * as Blocks from '../post-blocks/BlockTypes'
import {action, computed, makeAutoObservable} from "mobx";
import {composeBlocks} from "../post-blocks/BlockCompose";
import {PostRequest} from "../client/ngcht";

export class MessageDraft {
    private text : string = ""

    // private customBlocks: Blocks.Any[] = []

    private activeEditors : string[] = []

    private editorBlocks: Map<string, Blocks.Any[]> = new Map()
    private editorState:  Map<string, unknown> = new Map()


    // private uploadBlocks: Blocks.Any[] = []
    private onTyping: (isTyping: boolean, text ?: string) => void;


    constructor(onTyping: (isTyping: boolean, text ?: string) => void) {
        this.onTyping = onTyping;
        makeAutoObservable(this, {
                isEmpty: computed,
                clear: action,
                clearEditorState: action,
                clearEditorBlocks: action,
        })
    }

    private onChange() {
        if (this.text.length || this.haveEditorBlocks()) {
            this.onTyping(true, this.text)
        } else {
            this.onTyping(false)
        }
    }

    public setText(text: string) {
        const changed = this.text !== text
        this.text = text
        if (changed) this.onChange()
    }
    public getText() : string {
        return this.text
    }

    public clear() {
        this.text = ""
        this.clearEditorState()
        this.clearEditorBlocks()
        this.onTyping(false)
    }

    public getEditorState(editorId: string) : unknown {
        return this.editorState.get(editorId)
    }

    public clearEditorState() {
        this.editorState = new Map()
    }

    public setEditorState(editorId:string, state: unknown) {
        this.editorState.set(editorId, state)
    }

    public setEditorBlocks(editorId: string, blocks: Blocks.Any[]) {
        this.editorBlocks.set(editorId, blocks)
        this.onChange()
    }
    public getEditorBlocks(editorId: string) : Blocks.Any[] {
        return this.editorBlocks.get(editorId) || []
    }

    public clearEditorBlocks() {
        this.editorBlocks.clear()
    }

    get isEmpty() : boolean {
        return !this.text.trim().length && !this.editorBlocks.size
    }

    public haveEditorBlocks() {
        return this.activeEditors.some(id => this.editorBlocks.get(id)?.length! > 0 )
    }

    private compose() : Blocks.Message {
        const blocks : Blocks.Any[] =
            this.activeEditors.map(id => this.editorBlocks.get(id) || [] ).flat(1)
        blocks.push(this.text)
        return composeBlocks(...blocks)
    }

    getPostRequest() : PostRequest {
        const req = PostRequest.fromJSON({})
        if (!this.haveEditorBlocks()) {
            req.post = this.text
        } else {
            req.post = JSON.stringify(this.compose())
            req.isJson = true
        }
        return req
    }

    public setActiveEditors(editors: string[]) {
        this.activeEditors = editors
    }

    public getActiveEditors() : string[] {
        return this.activeEditors
    }
}


