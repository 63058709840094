import React, {useEffect, useRef} from "react"
import {TEditorProps} from "./ChannelMessageInput";
import {observer} from "mobx-react";

import * as Blocks from '../../post-blocks/BlockTypes'
import {MediaUploaderDiv} from "../Upload/MediaUploader";
import {MediaUploaderFileItem} from "../Upload/MediaUploaderFileItem";
import {useChatContext} from "../../context/ChatContext";

type TEditorMediaBrowserPreviewStateItem = [Blocks.Image, Blocks.Image] | [Blocks.Video, Blocks.Video]
export type TEditorMediaBrowserPreviewState = TEditorMediaBrowserPreviewStateItem[]


export const EditorMediaBrowserPreview = observer(( props: TEditorProps) => {

    const { chat } = useChatContext()

    const ref = useRef({});

    useEffect(() => {
        chat.mediaBrowser.registerListener((insert, preview) => {
            const st : TEditorMediaBrowserPreviewState = (props.getState!() || [] ) as []
            props.setState!( [...st, [ insert, preview ]])
            const bl  = props.getBlocks!() || []
            props.setBlocks!( [...bl, insert])
        }, ref.current)
        // destructor
        const current = ref.current
        return () => chat.mediaBrowser.clearListener(current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const deleteItem = (key: number) => {
        let st : TEditorMediaBrowserPreviewState = (props.getState!() || [] ) as any
        st = st.filter((v, k) => k !== key)
        props.setState!(st)
        const bl = props.getBlocks!() || []
        props.setBlocks!(bl.filter((v, k) => k !== key) || [])
    }

    const st : TEditorMediaBrowserPreviewState = (props.getState!() || [] ) as any

    return <MediaUploaderDiv><div className={"media-uploader-file-items "}>
        {
            st.map((it, k) => {
                const mainUrl = it[1].type === "image" ? it[1].image_url : it[1].video_url
                const thumbUrl = it[1].type === "image" ? it[1].thumb_url : it[1].poster_url
                return <MediaUploaderFileItem key={k} progress={100} hideSuccess={true}
                    mainUrl={mainUrl} thumbUrl={thumbUrl} onDeleteClick={() => deleteItem(k)}  error={false}/>
            })
        }
    </div></MediaUploaderDiv>
})