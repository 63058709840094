import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReactBigPicture} from "../ReactBigPicture";
import React from "react";

export const MediaUploaderFileItem = (props: {
                                          thumbUrl?: string,
                                          mainUrl?: string,
                                          progress: number,
                                          error ?: boolean,
                                          hideSuccess?: boolean,
                                          uploading ?: boolean,
                                          onDeleteClick: () => void
                                      }
) => {
    const {progress, error, onDeleteClick, uploading} = props

    let className = "media-uploader-file-item "
    let showProgress = false
    switch (true) {
        case error:
            className += "media-uploader-file-item-error"
            break
        case progress >= 100 && !props.uploading:
            className += "media-uploader-file-item-success"
            break
        default:
            showProgress = true
            className += "media-uploader-file-item-uploading"
    }

    let imgCnt = <FontAwesomeIcon icon={"file-image"} size={"3x"} className={"icon-uploading-progress"}/>
    if (props.mainUrl && props.thumbUrl) {
        imgCnt =
            <ReactBigPicture type={"image"} src={props.mainUrl}><img src={props.thumbUrl} alt={""} loading="lazy" /></ReactBigPicture>
    } else if (props.thumbUrl) {
        imgCnt = <img src={props.thumbUrl} alt={""}/>
    }

    return <>
        <div className={className}>
            {(!props.hideSuccess && (progress >= 100) && !uploading) ?
                <FontAwesomeIcon className={"media-uploader-file-succ"} icon={"check"} size={"1x"}/>
                : null}
            {showProgress ?
                <div className={"media-uploader-file-progress"}>
                    {progress + "%"}
                </div> : null}
            {imgCnt}
            {progress >= 100 ?
                <FontAwesomeIcon className={"media-uploader-file-del"} icon={"trash-alt"} size={"1x"}
                                 onClick={onDeleteClick}/>
                : null}
        </div>
    </>
}