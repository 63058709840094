import {observer} from "mobx-react";
import * as Blocks from "../../post-blocks/BlockTypes";
import {useChatContext} from "../../context/ChatContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PostBlocks} from "../PostBlocks/PostBlocks";
import React, {useState} from "react";
import styled from "styled-components";
import {TMediaRecord} from "../../store/UploadsStore";
import {format, parseISO} from "date-fns";
import {MediaBrowserUpload} from "./MediaBrowserUpload";

export const MediaBrowseDiv  = styled.div`
  max-height: 100vh;
  height: 100vh;
  // z-index: 10001;
  
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  
  .media-browse-filters {
    text-align: right;
    font-size: 18px;
    //background-color: #8a96a3;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: solid 1px var(--border-color);

    .filters-left-buttons {
      svg {
        height: 24px;
        padding-right: 5px;
      }
      float: left; 
    }
  }
  
  .media-browse-all-items {
    overflow-y: scroll;
    //background-color: #cccccc;
    flex: 1 1 0;
  }
  
  .media-browse-blocks {
    width: 99%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .media-uploader-file-browse {
    height: 56px;
    width: 48px;
    margin: 2px;
    padding-left: 8px;
    padding-top: 8px;
    color: #6c757d;
  }
  .media-browse {
    // z-index: 3000;
    background: white;
    position: fixed;
    //border: solid 1px black;
    left: 60px;
    right: 60px;
    top: 40px;
    bottom: 40px;
    overflow-y: scroll;

    .close_btn {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 20px;
      font-weight: bold;
      padding: 10px;
    }

  }
  .media-browse h2 {
    margin: 20px;
  }

  .media-browse-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(100, 100, 100, 0.75)    
  }

  .media-browse-blocks-date {
    font-size: 14px;
    font-style: italic;
    text-align: center;
    
  }
  .media-browse-block-type-icon {
    position: absolute;
    left: 12px;
    top: 10px;
    float: left;
    padding-right: 4px;
    color: gray;
    mix-blend-mode: exclusion;
    // margin-top: 6px;
  }
  
  .media-browse-block-insert {
    display: none;
    position: absolute;
    right: 6px;
    bottom: 6px;
    font-size: 200%;
    opacity: 60%;
    padding: 10px 30px;
    border: solid 3px gray;
    border-radius: 10px;
  }
  
  .media-browse-block {
    position: relative;
    border: solid 3px transparent;
    flex: 1 1 0;
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    min-height: 120px;
    box-sizing: border-box;
    word-wrap:break-word;
    img {
      max-width: 110px;
    }
  }
  
  .media-browse-block:hover {
    border: solid 3px blue;
    .media-browse-block-insert {
      display: block;
    }
  }

  //.media-browse-folders {
  //  overflow-x: scroll;
  //  display: flex;
  //  word-wrap:break-word;
  //  .media-browse-folder {
  //    width: 120px;
  //    text-align: center;
  //    svg {
  //      width: 100%;
  //      color: yellowgreen;
  //      display: block;
  //    }
  //    border: solid 3px transparent;
  //  }
  //  .media-browse-folder:hover {
  //    border: solid 3px blue;
  //  }
  //  .media-browse-folder-recent {
  //    svg {
  //      color: lightblue;
  //    }
  //  }
  //}
  
  
`

export const MediaBrowserPickerItself = observer((props: {
    btnLabel: string,
    onClick : (insert: Blocks.Image|Blocks.Video, preview: Blocks.Image|Blocks.Video) => void,
}) => {
    const { chat } = useChatContext()

    const [showPhoto, setShowPhoto] = useState(true)
    const [showVideo, setShowVideo] = useState(true)
    const [textFilter, setTextFilter] = useState("")

    const [showUpload, toggleUpload] = useState(false)

    const uploadsStore = chat.uploadsStore

    const dateFormat = (last_modified : string) : string => {
        return last_modified ? format(parseISO(last_modified), 'MMMM dd, yyyy') : ""
    }

    const sqlFormat = (last_modifed ?: Date) : string => {
        const dd = typeof last_modifed === "string" ? parseISO(last_modifed) : last_modifed
        return dd ? format(dd, 'yyyy-MM-dd') : ""
    }


    let blll = <h3>Loading...</h3>
    if (uploadsStore.getItems() !== undefined) {

        let acc : { [date: string] : TMediaRecord[] } = {}

        uploadsStore.getItems()
        uploadsStore.items[uploadsStore.folder || "/"]?.forEach((curr) => {
            const d = sqlFormat(curr[0].last_modified)
            if (!acc[d]) acc[d] = []
            acc[d].push(curr)
            return acc
        })

        console.log({showUpload});

        blll = <MediaBrowseDiv>
            { showUpload ? <MediaBrowserUpload onClose={() => toggleUpload(false)} /> : null }

            {/*<div className="media-browse-folders">*/}
            {/*    { uploadsStore.getFolders().map(fld =>*/}
            {/*        <div*/}
            {/*        className={fld === UPLOADS_RECENT_UPLOADS ? "media-browse-folder media-browse-folder-recent" : "media-browse-folder"}*/}
            {/*        key={fld} onClick={() => uploadsStore.chdir(fld)}>*/}
            {/*            <FontAwesomeIcon icon={"folder"} size={"2x"} />*/}
            {/*            {fld === UPLOADS_RECENT_UPLOADS ? "Recent Uploads" : fld}*/}
            {/*        </div> )*/}
            {/*    }*/}
            {/*</div>*/}
            <div className="media-browse-filters">
                {/*<button style={{float: "left", fontWeight: "bold"}}*/}
                {/*        onClick={() => {toggleUpload(!showUpload)}}*/}
                {/*>+</button>*/}

                <div className={"filters-left-buttons"}>
                    <FontAwesomeIcon icon={"undo"} size={"2x"}
                        onClick={() => chat.mediaBrowser.clearListener() }
                    />
                    <span onClick={() => toggleUpload(!showUpload) }>
                        <FontAwesomeIcon icon={"cloud-upload-alt"} size={"2x"} />
                    </span>
                </div>

                {/*<b>Media Library&nbsp;</b>*/}

                <label>
                    <input type={"checkbox"}
                           checked={showPhoto} onChange={() => setShowPhoto(!showPhoto)}/>
                    Image
                    &nbsp;&nbsp;
                </label>
                <label>
                    <input type={"checkbox"}
                           checked={showVideo} onChange={() => setShowVideo(!showVideo)}/>
                    Video
                    &nbsp;&nbsp;
                </label>
                <label >
                    <input type={"text"} placeholder={"Filter"} value={textFilter}
                           style={{maxWidth: '55%', width: '300px'}}
                           onChange={(ev) => setTextFilter(ev.target.value) }/>
                    &nbsp;&nbsp;
                </label>
            </div>
            <div className="media-browse-all-items">
            { Object.entries(acc).sort((a, b) => {
                return b[0].localeCompare(a[0])
            }).map(([date, records]) =>
                <div className="media-browse-blocks-container" key={date}>
                    <div className="media-browse-blocks-date">
                        {dateFormat(date)}
                    </div>
                    <div className="media-browse-blocks">
                    { (records).map(([insBlock, visBlock]) => {
                            // console.log({i:{...insBlock}, v:{...visBlock}})
                            const body = { blocks : [visBlock] }
                            if (!showVideo && insBlock.type === "video") return null
                            if (!showPhoto && insBlock.type === "image") return null

                            const mainUri = insBlock.type === "image" ? insBlock.image_url : insBlock.video_url
                            const filename = (mainUri.replace(/\?.+/, '')
                                .replace(/^.+?([^/]+)$/, '$1')
                                .replace(/^pAwDKixq1p_([a-zA-Z0-9])+_/, '') || "") as string

                            if (textFilter && filename &&
                                !filename.toLowerCase().includes(textFilter.toLowerCase())) return null

                            return <div
                                className="media-browse-block" key={mainUri} >
                                <PostBlocks body={body} />
                                <div className="media-browse-block-type-icon">
                                    <FontAwesomeIcon icon={visBlock.type === "video" ? "video" : "image"} size={"1x"}/>
                                </div>
                                <button className="media-browse-block-insert"
                                        onClick={() => props.onClick(insBlock, visBlock) }
                                >{props.btnLabel}</button>
                            </div>
                        })
                    }
                    </div>
                </div>
            ) }
            </div>
        </MediaBrowseDiv>
    }

    return blll
})

