import {makeAutoObservable} from "mobx";
import {TPcchtClient} from "./ChatStore";
import {ClientUploadRequest, ClientUploadResponse} from "../client/ngcht";
import {UploaderState} from "./UploaderState";

export type TSignedUrlResponse = {
    fileKey : string,
    signedUrl: string,
    filename: string,
    httpMethod ?: string,
}

type TUploadJsonResponseItem = {
    temp: string,
    uri: string,
}

export type TUploadJsonResponse = {
    ok: boolean,
    main: TUploadJsonResponseItem,
    thumb : TUploadJsonResponseItem,
    preview ?: TUploadJsonResponseItem,
}



export class UploaderStore {

    private client: TPcchtClient

    constructor(client: TPcchtClient) {
        this.client = client
        makeAutoObservable(this)
    }

    protected requestUpload(filename: string, contentType: string, size: number, channelId ?: number ) : Promise<ClientUploadResponse> {
        const m : ClientUploadRequest = ClientUploadRequest.fromJSON({})
        m.channelId = channelId || 0
        m.filename = filename
        m.contentType = contentType
        m.size = size
        return this.client.send(m) as Promise<ClientUploadResponse>
    }

    upload(file: File, channelId : number, onProgress ?: (p: number) => void,
           uploaderState ?: UploaderState) : Promise<TUploadJsonResponse> {

        let id = (10000*Math.random()).toFixed()
        if (uploaderState) id = uploaderState.start(file)

       if (onProgress) onProgress(0)

        return this.requestUpload(file.name, file.type, file.size, channelId)
        .then(reply => { // got POST URL and other params from the server
           if (onProgress) onProgress(2)
           uploaderState?.progress(id, 2)
           return {
               fileKey: reply.storageKey,
               filename: reply.data.filename,
               signedUrl: reply.url,
               httpMethod: 'post',
           }
        })
        .then((signedUrlResponse) =>
            new Promise<TUploadJsonResponse>(resolve => {
                const formData = new FormData()
                formData.append('file', file);

                const xhr = new XMLHttpRequest()
                xhr.open(signedUrlResponse.httpMethod, signedUrlResponse.signedUrl, true)
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

                const cb = (status: string) => {
                    return (ev: ProgressEvent) => {
                        const v = Math.round(ev.total ? (ev.total/ev.loaded)*100 : 3)
                        if ((v < 0 && v>101)) return
                        if (onProgress) onProgress(v)
                        uploaderState?.progress(id, v)
                    }
                }
                xhr.upload.onprogress = cb("progress")
                xhr.upload.onload = cb("load")
                xhr.upload.onerror = cb("error")
                xhr.upload.onloadstart = cb("loadstart")
                xhr.upload.onabort = cb("abort")
                xhr.upload.onloadend = cb("loadend")
                xhr.upload.ontimeout = cb("timeout")

                xhr.onreadystatechange = (ev: Event) => {
                    if ((ev.target as XMLHttpRequest).readyState !== XMLHttpRequest.DONE) return
                    const js = JSON.parse(xhr.response) as TUploadJsonResponse
                    if (js && js.ok) {
                        resolve(js)
                        // props.onFinish(js, p.file)
                    } else {
                        throw new Error(xhr.responseText)
                    }
                }

                xhr.send(formData)
            })
        ).then(r => {
            if (onProgress) onProgress(100)



            uploaderState?.done(id, {
                thumbUrl: r.thumb.temp,
                uploadResponse: r
            })

            return r
        }).catch((e) => {
            uploaderState?.error(id, e)
            throw e
        }).finally(() => {
            uploaderState?.finished(id)
        })

    }


}

