import React, {useRef} from "react"
import styled from "styled-components";
import {observer} from "mobx-react";
import {TEditorProps} from "./ChannelMessageInput";

const PostTextarea = styled.textarea`
    border: none;
    width: 99%;
    font-size: 16px;
    outline: none;
    // font-family: Roboto, sans-serif;
    resize: none;
    overflow: hidden; 
    overflow-wrap: break-word;
    height: 55px;
`

export const EditorPlaintext = observer(( props: TEditorProps) => {
    const { _draft,  sendMsg } = props

    const inputRef = useRef<HTMLTextAreaElement>(null)

    const keyPress = (ev: any) => {
        const el = inputRef.current!

        // we got Enter without modifiers?
        if ((ev.key === "Enter")){
            if (ev.ctrlKey || ev.altKey) {
                if (el.value.length)
                    el.value += "\n"
                _draft?.setText(el.value)
            } else {
                ev.preventDefault()
                if (sendMsg) { sendMsg() }
                return
            }
        }
    }

    return <>
        <PostTextarea rows={1}
                      ref={inputRef}
                      onKeyPress={(ev) => keyPress(ev) }
                      onChange={ev => _draft?.setText(ev.target.value)}
                      value={_draft?.getText()}
                      spellCheck="false" maxLength={3000} placeholder="Type your message..."/>
    </>
})