/* eslint-disable */
import { messageTypeRegistry } from "./typeRegistry";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Any } from "./any";

export const protobufPackage = "ngmsg";

export enum EnumClientStatus {
  StatusOffline = 0,
  StatusOnline = 1,
  StatusAway = 2,
}

export function enumClientStatusFromJSON(object: any): EnumClientStatus {
  switch (object) {
    case 0:
    case "StatusOffline":
      return EnumClientStatus.StatusOffline;
    case 1:
    case "StatusOnline":
      return EnumClientStatus.StatusOnline;
    case 2:
    case "StatusAway":
      return EnumClientStatus.StatusAway;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumClientStatus"
      );
  }
}

export function enumClientStatusToJSON(object: EnumClientStatus): string {
  switch (object) {
    case EnumClientStatus.StatusOffline:
      return "StatusOffline";
    case EnumClientStatus.StatusOnline:
      return "StatusOnline";
    case EnumClientStatus.StatusAway:
      return "StatusAway";
    default:
      return "UNKNOWN";
  }
}

export enum EnumCrudAction {
  Insert = 0,
  Update = 1,
  Upsert = 2,
}

export function enumCrudActionFromJSON(object: any): EnumCrudAction {
  switch (object) {
    case 0:
    case "Insert":
      return EnumCrudAction.Insert;
    case 1:
    case "Update":
      return EnumCrudAction.Update;
    case 2:
    case "Upsert":
      return EnumCrudAction.Upsert;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumCrudAction"
      );
  }
}

export function enumCrudActionToJSON(object: EnumCrudAction): string {
  switch (object) {
    case EnumCrudAction.Insert:
      return "Insert";
    case EnumCrudAction.Update:
      return "Update";
    case EnumCrudAction.Upsert:
      return "Upsert";
    default:
      return "UNKNOWN";
  }
}

export enum EnumChannelPublic {
  Private = 0,
  Public = 1,
  Invite = 2,
}

export function enumChannelPublicFromJSON(object: any): EnumChannelPublic {
  switch (object) {
    case 0:
    case "Private":
      return EnumChannelPublic.Private;
    case 1:
    case "Public":
      return EnumChannelPublic.Public;
    case 2:
    case "Invite":
      return EnumChannelPublic.Invite;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumChannelPublic"
      );
  }
}

export function enumChannelPublicToJSON(object: EnumChannelPublic): string {
  switch (object) {
    case EnumChannelPublic.Private:
      return "Private";
    case EnumChannelPublic.Public:
      return "Public";
    case EnumChannelPublic.Invite:
      return "Invite";
    default:
      return "UNKNOWN";
  }
}

export enum EnumChannelRole {
  None = 0,
  Member = 1,
  Moderator = 2,
}

export function enumChannelRoleFromJSON(object: any): EnumChannelRole {
  switch (object) {
    case 0:
    case "None":
      return EnumChannelRole.None;
    case 1:
    case "Member":
      return EnumChannelRole.Member;
    case 2:
    case "Moderator":
      return EnumChannelRole.Moderator;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumChannelRole"
      );
  }
}

export function enumChannelRoleToJSON(object: EnumChannelRole): string {
  switch (object) {
    case EnumChannelRole.None:
      return "None";
    case EnumChannelRole.Member:
      return "Member";
    case EnumChannelRole.Moderator:
      return "Moderator";
    default:
      return "UNKNOWN";
  }
}

export enum AppUserRole {
  /** Anonymous - no recordable */
  Anonymous = 0,
  /** Guest - recordable, no login */
  Guest = 1,
  User = 2,
  Admin = 3,
  /** Api - not recordable? */
  Api = 4,
}

export function appUserRoleFromJSON(object: any): AppUserRole {
  switch (object) {
    case 0:
    case "Anonymous":
      return AppUserRole.Anonymous;
    case 1:
    case "Guest":
      return AppUserRole.Guest;
    case 2:
    case "User":
      return AppUserRole.User;
    case 3:
    case "Admin":
      return AppUserRole.Admin;
    case 4:
    case "Api":
      return AppUserRole.Api;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum AppUserRole"
      );
  }
}

export function appUserRoleToJSON(object: AppUserRole): string {
  switch (object) {
    case AppUserRole.Anonymous:
      return "Anonymous";
    case AppUserRole.Guest:
      return "Guest";
    case AppUserRole.User:
      return "User";
    case AppUserRole.Admin:
      return "Admin";
    case AppUserRole.Api:
      return "Api";
    default:
      return "UNKNOWN";
  }
}

export enum ClientRole {
  ClientAnonymous = 0,
  ClientGuest = 1,
  ClientUser = 2,
  ClientAdmin = 3,
}

export function clientRoleFromJSON(object: any): ClientRole {
  switch (object) {
    case 0:
    case "ClientAnonymous":
      return ClientRole.ClientAnonymous;
    case 1:
    case "ClientGuest":
      return ClientRole.ClientGuest;
    case 2:
    case "ClientUser":
      return ClientRole.ClientUser;
    case 3:
    case "ClientAdmin":
      return ClientRole.ClientAdmin;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum ClientRole"
      );
  }
}

export function clientRoleToJSON(object: ClientRole): string {
  switch (object) {
    case ClientRole.ClientAnonymous:
      return "ClientAnonymous";
    case ClientRole.ClientGuest:
      return "ClientGuest";
    case ClientRole.ClientUser:
      return "ClientUser";
    case ClientRole.ClientAdmin:
      return "ClientAdmin";
    default:
      return "UNKNOWN";
  }
}

/** ///////////////// Notifications ///////////////////////////////////////////// */
export enum EnumUserEvent {
  UserNew = 0,
  UserUpdated = 1,
  UserDeleted = 2,
  UserBanned = 3,
  UserUnbanned = 4,
}

export function enumUserEventFromJSON(object: any): EnumUserEvent {
  switch (object) {
    case 0:
    case "UserNew":
      return EnumUserEvent.UserNew;
    case 1:
    case "UserUpdated":
      return EnumUserEvent.UserUpdated;
    case 2:
    case "UserDeleted":
      return EnumUserEvent.UserDeleted;
    case 3:
    case "UserBanned":
      return EnumUserEvent.UserBanned;
    case 4:
    case "UserUnbanned":
      return EnumUserEvent.UserUnbanned;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumUserEvent"
      );
  }
}

export function enumUserEventToJSON(object: EnumUserEvent): string {
  switch (object) {
    case EnumUserEvent.UserNew:
      return "UserNew";
    case EnumUserEvent.UserUpdated:
      return "UserUpdated";
    case EnumUserEvent.UserDeleted:
      return "UserDeleted";
    case EnumUserEvent.UserBanned:
      return "UserBanned";
    case EnumUserEvent.UserUnbanned:
      return "UserUnbanned";
    default:
      return "UNKNOWN";
  }
}

export enum EnumChannelEvent {
  ChannelNew = 0,
  ChannelUpdated = 1,
  ChannelDeleted = 2,
  ChannelTruncated = 3,
  ChannelPublic = 4,
}

export function enumChannelEventFromJSON(object: any): EnumChannelEvent {
  switch (object) {
    case 0:
    case "ChannelNew":
      return EnumChannelEvent.ChannelNew;
    case 1:
    case "ChannelUpdated":
      return EnumChannelEvent.ChannelUpdated;
    case 2:
    case "ChannelDeleted":
      return EnumChannelEvent.ChannelDeleted;
    case 3:
    case "ChannelTruncated":
      return EnumChannelEvent.ChannelTruncated;
    case 4:
    case "ChannelPublic":
      return EnumChannelEvent.ChannelPublic;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumChannelEvent"
      );
  }
}

export function enumChannelEventToJSON(object: EnumChannelEvent): string {
  switch (object) {
    case EnumChannelEvent.ChannelNew:
      return "ChannelNew";
    case EnumChannelEvent.ChannelUpdated:
      return "ChannelUpdated";
    case EnumChannelEvent.ChannelDeleted:
      return "ChannelDeleted";
    case EnumChannelEvent.ChannelTruncated:
      return "ChannelTruncated";
    case EnumChannelEvent.ChannelPublic:
      return "ChannelPublic";
    default:
      return "UNKNOWN";
  }
}

export enum EnumPostEvent {
  PostNew = 0,
  PostUpdated = 1,
  PostDeleted = 2,
}

export function enumPostEventFromJSON(object: any): EnumPostEvent {
  switch (object) {
    case 0:
    case "PostNew":
      return EnumPostEvent.PostNew;
    case 1:
    case "PostUpdated":
      return EnumPostEvent.PostUpdated;
    case 2:
    case "PostDeleted":
      return EnumPostEvent.PostDeleted;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumPostEvent"
      );
  }
}

export function enumPostEventToJSON(object: EnumPostEvent): string {
  switch (object) {
    case EnumPostEvent.PostNew:
      return "PostNew";
    case EnumPostEvent.PostUpdated:
      return "PostUpdated";
    case EnumPostEvent.PostDeleted:
      return "PostDeleted";
    default:
      return "UNKNOWN";
  }
}

export enum EnumReactionEvent {
  ReactionNew = 0,
  ReactionUpdated = 1,
  ReactionDeleted = 2,
}

export function enumReactionEventFromJSON(object: any): EnumReactionEvent {
  switch (object) {
    case 0:
    case "ReactionNew":
      return EnumReactionEvent.ReactionNew;
    case 1:
    case "ReactionUpdated":
      return EnumReactionEvent.ReactionUpdated;
    case 2:
    case "ReactionDeleted":
      return EnumReactionEvent.ReactionDeleted;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumReactionEvent"
      );
  }
}

export function enumReactionEventToJSON(object: EnumReactionEvent): string {
  switch (object) {
    case EnumReactionEvent.ReactionNew:
      return "ReactionNew";
    case EnumReactionEvent.ReactionUpdated:
      return "ReactionUpdated";
    case EnumReactionEvent.ReactionDeleted:
      return "ReactionDeleted";
    default:
      return "UNKNOWN";
  }
}

export enum EnumEventSource {
  SourceInternal = 0,
  SourceApi = 1,
  SourceClient = 2,
}

export function enumEventSourceFromJSON(object: any): EnumEventSource {
  switch (object) {
    case 0:
    case "SourceInternal":
      return EnumEventSource.SourceInternal;
    case 1:
    case "SourceApi":
      return EnumEventSource.SourceApi;
    case 2:
    case "SourceClient":
      return EnumEventSource.SourceClient;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum EnumEventSource"
      );
  }
}

export function enumEventSourceToJSON(object: EnumEventSource): string {
  switch (object) {
    case EnumEventSource.SourceInternal:
      return "SourceInternal";
    case EnumEventSource.SourceApi:
      return "SourceApi";
    case EnumEventSource.SourceClient:
      return "SourceClient";
    default:
      return "UNKNOWN";
  }
}

/** ////////////////////////////////////////////// error codes /////////////////////////////////////////////////////////// */
export enum Err {
  NoError = 0,
  Unknown = 1,
  InvalidRequest = 2,
  UserNotFound = 3,
  ChannelNotFound = 4,
  DuplicateRecord = 5,
  PermissionDenied = 6,
  PostNotFound = 7,
  AuthError = 401,
  ServerError = 403,
}

export function errFromJSON(object: any): Err {
  switch (object) {
    case 0:
    case "NoError":
      return Err.NoError;
    case 1:
    case "Unknown":
      return Err.Unknown;
    case 2:
    case "InvalidRequest":
      return Err.InvalidRequest;
    case 3:
    case "UserNotFound":
      return Err.UserNotFound;
    case 4:
    case "ChannelNotFound":
      return Err.ChannelNotFound;
    case 5:
    case "DuplicateRecord":
      return Err.DuplicateRecord;
    case 6:
    case "PermissionDenied":
      return Err.PermissionDenied;
    case 7:
    case "PostNotFound":
      return Err.PostNotFound;
    case 401:
    case "AuthError":
      return Err.AuthError;
    case 403:
    case "ServerError":
      return Err.ServerError;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum Err"
      );
  }
}

export function errToJSON(object: Err): string {
  switch (object) {
    case Err.NoError:
      return "NoError";
    case Err.Unknown:
      return "Unknown";
    case Err.InvalidRequest:
      return "InvalidRequest";
    case Err.UserNotFound:
      return "UserNotFound";
    case Err.ChannelNotFound:
      return "ChannelNotFound";
    case Err.DuplicateRecord:
      return "DuplicateRecord";
    case Err.PermissionDenied:
      return "PermissionDenied";
    case Err.PostNotFound:
      return "PostNotFound";
    case Err.AuthError:
      return "AuthError";
    case Err.ServerError:
      return "ServerError";
    default:
      return "UNKNOWN";
  }
}

export enum TypeId {
  MsgInvalid = 0,
  MsgPingRequest = 1,
  MsgUserDataRequest = 2,
  MsgUserDeleteRequest = 3,
  MsgChannelDataRequest = 4,
  MsgChannelDeleteRequest = 5,
  MsgChannelTruncateRequest = 6,
  MsgChannelPublicRequest = 7,
  MsgChannelMemberRequest = 8,
  MsgUserMuteRequest = 9,
  MsgUserBanRequest = 10,
  MsgUserUnbanRequest = 11,
  MsgPostDeleteRequest = 12,
  MsgReactionRequest = 13,
  MsgPostRequest = 14,
  MsgInviteDirectRequest = 15,
  MsgSendInviteRequest = 16,
  MsgReplyInviteRequest = 17,
  MsgChannelMarkReadRequest = 18,
  MsgChannelMuteRequest = 19,
  MsgClientGetChannelsRequest = 20,
  MsgClientWatchRequest = 21,
  MsgClientStatusRequest = 22,
  MsgClientTypingRequest = 23,
  MsgUserGetRequest = 24,
  MsgPongResponse = 32,
  MsgGenericResponse = 33,
  MsgUserDataResponse = 34,
  MsgChannelDataResponse = 35,
  MsgPostResponse = 36,
  MsgClientGetChannelsResponse = 37,
  MsgClientFetchPostsRequest = 38,
  MsgClientFetchPostsResponse = 39,
  MsgClientUserData = 65,
  MsgClientPostData = 66,
  MsgClientChannelData = 67,
  MsgUserEvent = 128,
  MsgUserMutedEvent = 129,
  MsgChannelEvent = 130,
  MsgChannelMembersEvent = 131,
  MsgPostEvent = 132,
  MsgReactionEvent = 133,
  /** MsgEventInternal - internal for serve */
  MsgEventInternal = 240,
  MsgUserInternal = 241,
  MsgChannelInternal = 242,
  MsgPostInternal = 243,
}

export function typeIdFromJSON(object: any): TypeId {
  switch (object) {
    case 0:
    case "MsgInvalid":
      return TypeId.MsgInvalid;
    case 1:
    case "MsgPingRequest":
      return TypeId.MsgPingRequest;
    case 2:
    case "MsgUserDataRequest":
      return TypeId.MsgUserDataRequest;
    case 3:
    case "MsgUserDeleteRequest":
      return TypeId.MsgUserDeleteRequest;
    case 4:
    case "MsgChannelDataRequest":
      return TypeId.MsgChannelDataRequest;
    case 5:
    case "MsgChannelDeleteRequest":
      return TypeId.MsgChannelDeleteRequest;
    case 6:
    case "MsgChannelTruncateRequest":
      return TypeId.MsgChannelTruncateRequest;
    case 7:
    case "MsgChannelPublicRequest":
      return TypeId.MsgChannelPublicRequest;
    case 8:
    case "MsgChannelMemberRequest":
      return TypeId.MsgChannelMemberRequest;
    case 9:
    case "MsgUserMuteRequest":
      return TypeId.MsgUserMuteRequest;
    case 10:
    case "MsgUserBanRequest":
      return TypeId.MsgUserBanRequest;
    case 11:
    case "MsgUserUnbanRequest":
      return TypeId.MsgUserUnbanRequest;
    case 12:
    case "MsgPostDeleteRequest":
      return TypeId.MsgPostDeleteRequest;
    case 13:
    case "MsgReactionRequest":
      return TypeId.MsgReactionRequest;
    case 14:
    case "MsgPostRequest":
      return TypeId.MsgPostRequest;
    case 15:
    case "MsgInviteDirectRequest":
      return TypeId.MsgInviteDirectRequest;
    case 16:
    case "MsgSendInviteRequest":
      return TypeId.MsgSendInviteRequest;
    case 17:
    case "MsgReplyInviteRequest":
      return TypeId.MsgReplyInviteRequest;
    case 18:
    case "MsgChannelMarkReadRequest":
      return TypeId.MsgChannelMarkReadRequest;
    case 19:
    case "MsgChannelMuteRequest":
      return TypeId.MsgChannelMuteRequest;
    case 20:
    case "MsgClientGetChannelsRequest":
      return TypeId.MsgClientGetChannelsRequest;
    case 21:
    case "MsgClientWatchRequest":
      return TypeId.MsgClientWatchRequest;
    case 22:
    case "MsgClientStatusRequest":
      return TypeId.MsgClientStatusRequest;
    case 23:
    case "MsgClientTypingRequest":
      return TypeId.MsgClientTypingRequest;
    case 24:
    case "MsgUserGetRequest":
      return TypeId.MsgUserGetRequest;
    case 32:
    case "MsgPongResponse":
      return TypeId.MsgPongResponse;
    case 33:
    case "MsgGenericResponse":
      return TypeId.MsgGenericResponse;
    case 34:
    case "MsgUserDataResponse":
      return TypeId.MsgUserDataResponse;
    case 35:
    case "MsgChannelDataResponse":
      return TypeId.MsgChannelDataResponse;
    case 36:
    case "MsgPostResponse":
      return TypeId.MsgPostResponse;
    case 37:
    case "MsgClientGetChannelsResponse":
      return TypeId.MsgClientGetChannelsResponse;
    case 38:
    case "MsgClientFetchPostsRequest":
      return TypeId.MsgClientFetchPostsRequest;
    case 39:
    case "MsgClientFetchPostsResponse":
      return TypeId.MsgClientFetchPostsResponse;
    case 65:
    case "MsgClientUserData":
      return TypeId.MsgClientUserData;
    case 66:
    case "MsgClientPostData":
      return TypeId.MsgClientPostData;
    case 67:
    case "MsgClientChannelData":
      return TypeId.MsgClientChannelData;
    case 128:
    case "MsgUserEvent":
      return TypeId.MsgUserEvent;
    case 129:
    case "MsgUserMutedEvent":
      return TypeId.MsgUserMutedEvent;
    case 130:
    case "MsgChannelEvent":
      return TypeId.MsgChannelEvent;
    case 131:
    case "MsgChannelMembersEvent":
      return TypeId.MsgChannelMembersEvent;
    case 132:
    case "MsgPostEvent":
      return TypeId.MsgPostEvent;
    case 133:
    case "MsgReactionEvent":
      return TypeId.MsgReactionEvent;
    case 240:
    case "MsgEventInternal":
      return TypeId.MsgEventInternal;
    case 241:
    case "MsgUserInternal":
      return TypeId.MsgUserInternal;
    case 242:
    case "MsgChannelInternal":
      return TypeId.MsgChannelInternal;
    case 243:
    case "MsgPostInternal":
      return TypeId.MsgPostInternal;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum TypeId"
      );
  }
}

export function typeIdToJSON(object: TypeId): string {
  switch (object) {
    case TypeId.MsgInvalid:
      return "MsgInvalid";
    case TypeId.MsgPingRequest:
      return "MsgPingRequest";
    case TypeId.MsgUserDataRequest:
      return "MsgUserDataRequest";
    case TypeId.MsgUserDeleteRequest:
      return "MsgUserDeleteRequest";
    case TypeId.MsgChannelDataRequest:
      return "MsgChannelDataRequest";
    case TypeId.MsgChannelDeleteRequest:
      return "MsgChannelDeleteRequest";
    case TypeId.MsgChannelTruncateRequest:
      return "MsgChannelTruncateRequest";
    case TypeId.MsgChannelPublicRequest:
      return "MsgChannelPublicRequest";
    case TypeId.MsgChannelMemberRequest:
      return "MsgChannelMemberRequest";
    case TypeId.MsgUserMuteRequest:
      return "MsgUserMuteRequest";
    case TypeId.MsgUserBanRequest:
      return "MsgUserBanRequest";
    case TypeId.MsgUserUnbanRequest:
      return "MsgUserUnbanRequest";
    case TypeId.MsgPostDeleteRequest:
      return "MsgPostDeleteRequest";
    case TypeId.MsgReactionRequest:
      return "MsgReactionRequest";
    case TypeId.MsgPostRequest:
      return "MsgPostRequest";
    case TypeId.MsgInviteDirectRequest:
      return "MsgInviteDirectRequest";
    case TypeId.MsgSendInviteRequest:
      return "MsgSendInviteRequest";
    case TypeId.MsgReplyInviteRequest:
      return "MsgReplyInviteRequest";
    case TypeId.MsgChannelMarkReadRequest:
      return "MsgChannelMarkReadRequest";
    case TypeId.MsgChannelMuteRequest:
      return "MsgChannelMuteRequest";
    case TypeId.MsgClientGetChannelsRequest:
      return "MsgClientGetChannelsRequest";
    case TypeId.MsgClientWatchRequest:
      return "MsgClientWatchRequest";
    case TypeId.MsgClientStatusRequest:
      return "MsgClientStatusRequest";
    case TypeId.MsgClientTypingRequest:
      return "MsgClientTypingRequest";
    case TypeId.MsgUserGetRequest:
      return "MsgUserGetRequest";
    case TypeId.MsgPongResponse:
      return "MsgPongResponse";
    case TypeId.MsgGenericResponse:
      return "MsgGenericResponse";
    case TypeId.MsgUserDataResponse:
      return "MsgUserDataResponse";
    case TypeId.MsgChannelDataResponse:
      return "MsgChannelDataResponse";
    case TypeId.MsgPostResponse:
      return "MsgPostResponse";
    case TypeId.MsgClientGetChannelsResponse:
      return "MsgClientGetChannelsResponse";
    case TypeId.MsgClientFetchPostsRequest:
      return "MsgClientFetchPostsRequest";
    case TypeId.MsgClientFetchPostsResponse:
      return "MsgClientFetchPostsResponse";
    case TypeId.MsgClientUserData:
      return "MsgClientUserData";
    case TypeId.MsgClientPostData:
      return "MsgClientPostData";
    case TypeId.MsgClientChannelData:
      return "MsgClientChannelData";
    case TypeId.MsgUserEvent:
      return "MsgUserEvent";
    case TypeId.MsgUserMutedEvent:
      return "MsgUserMutedEvent";
    case TypeId.MsgChannelEvent:
      return "MsgChannelEvent";
    case TypeId.MsgChannelMembersEvent:
      return "MsgChannelMembersEvent";
    case TypeId.MsgPostEvent:
      return "MsgPostEvent";
    case TypeId.MsgReactionEvent:
      return "MsgReactionEvent";
    case TypeId.MsgEventInternal:
      return "MsgEventInternal";
    case TypeId.MsgUserInternal:
      return "MsgUserInternal";
    case TypeId.MsgChannelInternal:
      return "MsgChannelInternal";
    case TypeId.MsgPostInternal:
      return "MsgPostInternal";
    default:
      return "UNKNOWN";
  }
}

export interface PingRequest {
  $type: "ngmsg.PingRequest";
}

export interface PongResponse {
  $type: "ngmsg.PongResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
  me: ClientUserData | undefined;
  /** some custom data (chat settings, etc.) */
  data: { [key: string]: string };
}

export interface PongResponse_DataEntry {
  $type: "ngmsg.PongResponse.DataEntry";
  key: string;
  value: string;
}

export interface UserDataRequest {
  $type: "ngmsg.UserDataRequest";
  action: EnumCrudAction;
  userId: number;
  apiId: string;
  username: string;
  name: string;
  avatar: string;
  images: string[];
  tags: string[];
  email: string;
  phone: string;
  data: { [key: string]: string };
}

export interface UserDataRequest_DataEntry {
  $type: "ngmsg.UserDataRequest.DataEntry";
  key: string;
  value: string;
}

export interface UserGetRequest {
  $type: "ngmsg.UserGetRequest";
  userId: number;
  apiId: string;
  username: string;
}

export interface UserDataResponse {
  $type: "ngmsg.UserDataResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
  userId: number;
  apiId: string;
  username: string;
  name: string;
  avatar: string;
  images: string[];
  tags: string[];
  email: string;
  phone: string;
  data: { [key: string]: string };
}

export interface UserDataResponse_DataEntry {
  $type: "ngmsg.UserDataResponse.DataEntry";
  key: string;
  value: string;
}

export interface UserDeleteRequest {
  $type: "ngmsg.UserDeleteRequest";
  userId: number;
  apiId: string;
}

export interface ChannelDataRequest {
  $type: "ngmsg.ChannelDataRequest";
  action: EnumCrudAction;
  channelId: number;
  apiId: string;
  title: string;
  description: string;
  owner: number;
  avatar: string;
  images: string[];
  /** data with keys starting with _ will be filtered out from clients */
  data: { [key: string]: string };
  public: EnumChannelPublic;
}

export interface ChannelDataRequest_DataEntry {
  $type: "ngmsg.ChannelDataRequest.DataEntry";
  key: string;
  value: string;
}

export interface ChannelDataResponse {
  $type: "ngmsg.ChannelDataResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
  channelId: number;
  apiId: string;
  title: string;
  description: string;
  owner: number;
  avatar: string;
  images: string[];
  /** direct messaging channel - 2 members only */
  direct: boolean;
  public: EnumChannelPublic;
}

export interface ChannelGetRequest {
  $type: "ngmsg.ChannelGetRequest";
  channelId: number;
  apiId: string;
  title: string;
}

export interface ChannelDeleteRequest {
  $type: "ngmsg.ChannelDeleteRequest";
  channelId: number;
  apiId: string;
}

/** remove all messages in the channel */
export interface ChannelTruncateRequest {
  $type: "ngmsg.ChannelTruncateRequest";
  channelId: number;
}

/** remove all messages in the channel */
export interface ChannelPublicRequest {
  $type: "ngmsg.ChannelPublicRequest";
  channelId: number;
  public: EnumChannelPublic;
}

export interface ChannelMemberRequest {
  $type: "ngmsg.ChannelMemberRequest";
  channelId: number;
  userId: number[];
  role: EnumChannelRole;
}

export interface GenericResponse {
  $type: "ngmsg.GenericResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
}

export interface UserMuteRequest {
  $type: "ngmsg.UserMuteRequest";
  userId: number;
  channelId: number;
}

export interface UserBanRequest {
  $type: "ngmsg.UserBanRequest";
  userId: number;
}

export interface UserUnbanRequest {
  $type: "ngmsg.UserUnbanRequest";
  userId: number;
}

export interface PostRequest {
  $type: "ngmsg.PostRequest";
  channelId: number;
  /** field must be double checked, user_id will be set as author of message */
  userId: number;
  post: string;
  isJson: boolean;
  replyTo: number;
  /** set only for PostUpdate request */
  updatePostId: number;
  data: { [key: string]: string };
}

export interface PostRequest_DataEntry {
  $type: "ngmsg.PostRequest.DataEntry";
  key: string;
  value: string;
}

export interface PostResponse {
  $type: "ngmsg.PostResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
  postId: number;
  time: number;
  post: string;
  isJson: boolean;
  replyTo: number;
  data: { [key: string]: string };
}

export interface PostResponse_DataEntry {
  $type: "ngmsg.PostResponse.DataEntry";
  key: string;
  value: string;
}

export interface PostDeleteRequest {
  $type: "ngmsg.PostDeleteRequest";
  postId: number;
  userId: number;
}

export interface ReactionRequest {
  $type: "ngmsg.ReactionRequest";
  /** set only for PostUpdate request */
  postId: number;
  /** field must be double checked, user_id will be set as author of message */
  userId: number;
  reactionType: number;
  reaction: string;
  /** if set to true, will delete reaction on given post instead of adding new */
  delete: boolean;
}

export interface InviteDirectRequest {
  $type: "ngmsg.InviteDirectRequest";
  /** one who invites */
  userId: number;
  /** one who we will send invite to */
  inviteUserId: number;
  post: string;
  isJson: boolean;
}

export interface SendInviteRequest {
  $type: "ngmsg.SendInviteRequest";
  channelId: number;
  /** one who we will send invite to */
  userIds: number[];
  post: string;
  isJson: boolean;
}

export interface ReplyInviteRequest {
  $type: "ngmsg.ReplyInviteRequest";
  inviteId: number;
  accepted: boolean;
}

export interface ChannelMarkReadRequest {
  $type: "ngmsg.ChannelMarkReadRequest";
  channelId: number;
  userId: number;
  /** last post_id this user has read */
  readPostId: number;
}

export interface ChannelMuteRequest {
  $type: "ngmsg.ChannelMuteRequest";
  channelId: number;
  mute: boolean;
}

export interface CustomRequest {
  $type: "ngmsg.CustomRequest";
  userId: number;
  /** optional */
  channelId: number;
  /** optional */
  postId: number;
  /** like "paid-post" */
  customRequestType: string;
  /** request type, like "insert" */
  request: string;
  /** json encoded data for request */
  requestData: string;
}

export interface CustomResponse {
  $type: "ngmsg.CustomResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
  userId: number;
  /** optional */
  channelId: number;
  /** optional */
  postId: number;
  /** json encoded data for response */
  responseData: string;
}

export interface ClientGetChannelsRequest {
  $type: "ngmsg.ClientGetChannelsRequest";
  userId: number;
  /** start listen for channel events on received */
  watch: boolean;
  /** json encoded conditions */
  sort: string;
  /** json encoded conditions */
  filters: string;
  limit: number;
  start: number;
  postsLimit: number;
  usersLimit: number;
}

/** that is all we show to clients regarding user records */
export interface ClientUserData {
  $type: "ngmsg.ClientUserData";
  userId: number;
  username: string;
  /** we probably need to hide it */
  name: string;
  avatar: string;
  images: string[];
  tags: string[];
  /**
   * === 1 : online now
   * === 0 : long ago
   * > : #minutes since last visit
   * may be empty depending on user/chatserv configuration
   */
  clientLastOnline: number;
  /** data with keys starting with _ will be filtered out from clients */
  data: { [key: string]: string };
}

export interface ClientUserData_DataEntry {
  $type: "ngmsg.ClientUserData.DataEntry";
  key: string;
  value: string;
}

export interface ClientPostData {
  $type: "ngmsg.ClientPostData";
  postId: number;
  channelId: number;
  userId: number;
  time: number;
  post: string;
  isJson: boolean;
  replyTo: number;
  updatePostId: number;
}

export interface ClientChannelData {
  $type: "ngmsg.ClientChannelData";
  channelId: number;
  title: string;
  description: string;
  belongsToYou: boolean;
  public: EnumChannelPublic;
  avatar: string;
  images: string[];
  lastPostId: number;
  /** your last read id */
  lastReadId: number;
  /** at least the latest post me here */
  lastPosts: ClientPostData[];
  /** direct messaging channel - 2 members only - then it is set to another member id */
  directUserId: number;
}

export interface ClientGetChannelsResponse {
  $type: "ngmsg.ClientGetChannelsResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
  hasMore: boolean;
  channels: ClientChannelData[];
  /** users that mentioned in channel list */
  users: ClientUserData[];
}

export interface ClientFetchPostsRequest {
  $type: "ngmsg.ClientFetchPostsRequest";
  userId: number;
  /** channel id - required */
  channelId: number;
  /** where to start.  -1 === last message in channel on request time */
  postIdStart: number;
  /** -100 - 100 messsages up, 200 == 200 messages down */
  messagesLimit: number;
}

export interface ClientFetchPostsResponse {
  $type: "ngmsg.ClientFetchPostsResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
  posts: ClientPostData[];
  /** users that mentioned in channel list */
  users: ClientUserData[];
  lastPostId: number;
  /** there are more messages available in selected direction */
  hasMore: boolean;
}

export interface ClientWatchRequest {
  $type: "ngmsg.ClientWatchRequest";
  userId: number;
  /**  */
  watchChannelIds: number[];
  /**  */
  unwatchChannelIds: number[];
}

export interface ClientStatusRequest {
  $type: "ngmsg.ClientStatusRequest";
  userId: number;
  status: EnumClientStatus;
  data: { [key: string]: string };
}

export interface ClientStatusRequest_DataEntry {
  $type: "ngmsg.ClientStatusRequest.DataEntry";
  key: string;
  value: string;
}

export interface ClientTypingRequest {
  $type: "ngmsg.ClientTypingRequest";
  userId: number;
  channelId: number;
  typing: boolean;
  /** usually is empty, depending on the chat policy */
  post: string;
  replyTo: number;
}

export interface ClientUploadRequest {
  $type: "ngmsg.ClientUploadRequest";
  userId: number;
  channelId: number;
  filename: string;
  contentType: string;
  size: number;
  data: { [key: string]: string };
}

export interface ClientUploadRequest_DataEntry {
  $type: "ngmsg.ClientUploadRequest.DataEntry";
  key: string;
  value: string;
}

export interface ClientUploadResponse {
  $type: "ngmsg.ClientUploadResponse";
  ok: boolean;
  errorCode: Err;
  errorMessage: string;
  storageKey: string;
  storageId: string;
  url: string;
  data: { [key: string]: string };
}

export interface ClientUploadResponse_DataEntry {
  $type: "ngmsg.ClientUploadResponse.DataEntry";
  key: string;
  value: string;
}

export interface UserEvent {
  $type: "ngmsg.UserEvent";
  action: EnumUserEvent;
  user: ClientUserData | undefined;
}

export interface UserMutedEvent {
  $type: "ngmsg.UserMutedEvent";
  userId: number;
  channelId: number;
  muted: boolean;
}

export interface ChannelEvent {
  $type: "ngmsg.ChannelEvent";
  action: EnumChannelEvent;
  channel: ClientChannelData | undefined;
}

export interface ChannelMemberEvent {
  $type: "ngmsg.ChannelMemberEvent";
  channelId: number;
  users: number[];
  role: EnumChannelRole;
}

export interface PostEvent {
  $type: "ngmsg.PostEvent";
  action: EnumPostEvent;
  post: ClientPostData | undefined;
  user: ClientUserData | undefined;
}

export interface ReactionEvent {
  $type: "ngmsg.ReactionEvent";
  action: EnumReactionEvent;
  postId: number;
  userId: number;
  reactionType: number;
  reaction: string;
  /** optimize it! remove full data from this point */
  user: ClientUserData | undefined;
}

export interface TypingEvent {
  $type: "ngmsg.TypingEvent";
  userId: number;
  channelId: number;
  typing: boolean;
  /** usually is empty, depending on the chat policy */
  post: string;
  replyTo: number;
  /** optimize it! remove full data from this point */
  user: ClientUserData | undefined;
}

export interface UserStatusEvent {
  $type: "ngmsg.UserStatusEvent";
  userId: number;
  status: EnumClientStatus;
  clientLastOnline: number;
  data: { [key: string]: string };
  /** may be empty */
  user: ClientUserData | undefined;
}

export interface UserStatusEvent_DataEntry {
  $type: "ngmsg.UserStatusEvent.DataEntry";
  key: string;
  value: string;
}

/** emitted when CustomRequest processed */
export interface CustomRequestEvent {
  $type: "ngmsg.CustomRequestEvent";
  userId: number;
  /** optional */
  channelId: number;
  /** optional */
  postId: number;
  /** like "paid-post" */
  customRequestType: string;
  /** request type, like "insert" */
  request: string;
  /** json encoded data for response */
  responseData: string;
}

/** used internally to record and hold message information during processing */
export interface EventInternal {
  $type: "ngmsg.EventInternal";
  eventId: number;
  time: number;
  ip: string;
  /** api, client, internal? */
  source: EnumEventSource;
  /** one who submits the event - either API or admin or the user itself */
  actor: number;
  /** regarding user_id */
  userId: number;
  /** regarding channel id */
  channelId: number;
  /** can be used to get ack regarding message */
  clientMessageId: number;
  processed: boolean;
  typeId: TypeId;
  request: Any | undefined;
  response: Any | undefined;
}

export interface UserInternal {
  $type: "ngmsg.UserInternal";
  userId: number;
  apiId: string;
  username: string;
  /** we probably need to hide it */
  name: string;
  avatar: string;
  images: string[];
  /** we probably need to hide it */
  email: string;
  /** we probably need to hide it */
  phone: string;
  /** data with keys starting with _ will be filtered out from clients */
  data: { [key: string]: string };
  /** good idea to store it in separate table M=>N */
  tags: string[];
  deleted: boolean;
  /** banned */
  banned: boolean;
  /** global level role */
  role: AppUserRole;
  /** channel subscriptions */
  channelRoles: { [key: number]: EnumChannelRole };
}

export interface UserInternal_DataEntry {
  $type: "ngmsg.UserInternal.DataEntry";
  key: string;
  value: string;
}

export interface UserInternal_ChannelRolesEntry {
  $type: "ngmsg.UserInternal.ChannelRolesEntry";
  key: number;
  value: EnumChannelRole;
}

export interface ChannelInternal {
  $type: "ngmsg.ChannelInternal";
  channelId: number;
  apiId: string;
  title: string;
  description: string;
  owner: number;
  avatar: string;
  images: string[];
  /** direct messaging channel - 2 members only */
  direct: boolean;
  public: EnumChannelPublic;
}

export interface PostInternal {
  $type: "ngmsg.PostInternal";
  postId: number;
  channelId: number;
  userId: number;
  time: number;
  post: string;
  isJson: boolean;
  replyTo: number;
  ip: string;
  /** api, client, internal? */
  source: EnumEventSource;
  /** one who submits the event - either API or admin or the user itself */
  actor: number;
  history: PostInternal[];
}

export interface UploadInternal {
  $type: "ngmsg.UploadInternal";
  uploadId: number;
  userId: number;
  channelId: number;
  time: number;
  /** storage id from app like 's3' */
  storageId: string;
  /** file reference in storage */
  storageKey: string;
  ip: string;
  filename: string;
  mediaType: string;
  size: number;
}

export interface DataInternal {
  $type: "ngmsg.DataInternal";
  dataId: number;
  type: string;
  key: string;
  /** json */
  data: string;
  /** reference only, use [key] */
  userId: number;
  /** reference only, use [key] */
  channelId: number;
  /** reference only, use [key] */
  postId: number;
}

const basePingRequest: object = { $type: "ngmsg.PingRequest" };

export const PingRequest = {
  $type: "ngmsg.PingRequest" as const,

  fromJSON(_: any): PingRequest {
    const message = { ...basePingRequest } as PingRequest;
    return message;
  },

  toJSON(_: PingRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<PingRequest>): PingRequest {
    const message = { ...basePingRequest } as PingRequest;
    return message;
  },
};

messageTypeRegistry.set(PingRequest.$type, PingRequest);

const basePongResponse: object = {
  $type: "ngmsg.PongResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
};

export const PongResponse = {
  $type: "ngmsg.PongResponse" as const,

  fromJSON(object: any): PongResponse {
    const message = { ...basePongResponse } as PongResponse;
    message.data = {};
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    if (object.me !== undefined && object.me !== null) {
      message.me = ClientUserData.fromJSON(object.me);
    } else {
      message.me = undefined;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: PongResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    message.me !== undefined &&
      (obj.me = message.me ? ClientUserData.toJSON(message.me) : undefined);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<PongResponse>): PongResponse {
    const message = { ...basePongResponse } as PongResponse;
    message.data = {};
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    if (object.me !== undefined && object.me !== null) {
      message.me = ClientUserData.fromPartial(object.me);
    } else {
      message.me = undefined;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(PongResponse.$type, PongResponse);

const basePongResponse_DataEntry: object = {
  $type: "ngmsg.PongResponse.DataEntry",
  key: "",
  value: "",
};

export const PongResponse_DataEntry = {
  $type: "ngmsg.PongResponse.DataEntry" as const,

  fromJSON(object: any): PongResponse_DataEntry {
    const message = { ...basePongResponse_DataEntry } as PongResponse_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: PongResponse_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<PongResponse_DataEntry>
  ): PongResponse_DataEntry {
    const message = { ...basePongResponse_DataEntry } as PongResponse_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(PongResponse_DataEntry.$type, PongResponse_DataEntry);

const baseUserDataRequest: object = {
  $type: "ngmsg.UserDataRequest",
  action: 0,
  userId: 0,
  apiId: "",
  username: "",
  name: "",
  avatar: "",
  images: "",
  tags: "",
  email: "",
  phone: "",
};

export const UserDataRequest = {
  $type: "ngmsg.UserDataRequest" as const,

  fromJSON(object: any): UserDataRequest {
    const message = { ...baseUserDataRequest } as UserDataRequest;
    message.images = [];
    message.tags = [];
    message.data = {};
    if (object.action !== undefined && object.action !== null) {
      message.action = enumCrudActionFromJSON(object.action);
    } else {
      message.action = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = String(object.username);
    } else {
      message.username = "";
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = "";
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = String(object.avatar);
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(String(e));
      }
    }
    if (object.tags !== undefined && object.tags !== null) {
      for (const e of object.tags) {
        message.tags.push(String(e));
      }
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = String(object.phone);
    } else {
      message.phone = "";
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: UserDataRequest): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = enumCrudActionToJSON(message.action));
    message.userId !== undefined && (obj.userId = message.userId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    message.username !== undefined && (obj.username = message.username);
    message.name !== undefined && (obj.name = message.name);
    message.avatar !== undefined && (obj.avatar = message.avatar);
    if (message.images) {
      obj.images = message.images.map((e) => e);
    } else {
      obj.images = [];
    }
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.email !== undefined && (obj.email = message.email);
    message.phone !== undefined && (obj.phone = message.phone);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<UserDataRequest>): UserDataRequest {
    const message = { ...baseUserDataRequest } as UserDataRequest;
    message.images = [];
    message.tags = [];
    message.data = {};
    if (object.action !== undefined && object.action !== null) {
      message.action = object.action;
    } else {
      message.action = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = object.username;
    } else {
      message.username = "";
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = "";
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = object.avatar;
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(e);
      }
    }
    if (object.tags !== undefined && object.tags !== null) {
      for (const e of object.tags) {
        message.tags.push(e);
      }
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = object.phone;
    } else {
      message.phone = "";
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(UserDataRequest.$type, UserDataRequest);

const baseUserDataRequest_DataEntry: object = {
  $type: "ngmsg.UserDataRequest.DataEntry",
  key: "",
  value: "",
};

export const UserDataRequest_DataEntry = {
  $type: "ngmsg.UserDataRequest.DataEntry" as const,

  fromJSON(object: any): UserDataRequest_DataEntry {
    const message = {
      ...baseUserDataRequest_DataEntry,
    } as UserDataRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: UserDataRequest_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UserDataRequest_DataEntry>
  ): UserDataRequest_DataEntry {
    const message = {
      ...baseUserDataRequest_DataEntry,
    } as UserDataRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(
  UserDataRequest_DataEntry.$type,
  UserDataRequest_DataEntry
);

const baseUserGetRequest: object = {
  $type: "ngmsg.UserGetRequest",
  userId: 0,
  apiId: "",
  username: "",
};

export const UserGetRequest = {
  $type: "ngmsg.UserGetRequest" as const,

  fromJSON(object: any): UserGetRequest {
    const message = { ...baseUserGetRequest } as UserGetRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = String(object.username);
    } else {
      message.username = "";
    }
    return message;
  },

  toJSON(message: UserGetRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    message.username !== undefined && (obj.username = message.username);
    return obj;
  },

  fromPartial(object: DeepPartial<UserGetRequest>): UserGetRequest {
    const message = { ...baseUserGetRequest } as UserGetRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = object.username;
    } else {
      message.username = "";
    }
    return message;
  },
};

messageTypeRegistry.set(UserGetRequest.$type, UserGetRequest);

const baseUserDataResponse: object = {
  $type: "ngmsg.UserDataResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
  userId: 0,
  apiId: "",
  username: "",
  name: "",
  avatar: "",
  images: "",
  tags: "",
  email: "",
  phone: "",
};

export const UserDataResponse = {
  $type: "ngmsg.UserDataResponse" as const,

  fromJSON(object: any): UserDataResponse {
    const message = { ...baseUserDataResponse } as UserDataResponse;
    message.images = [];
    message.tags = [];
    message.data = {};
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = String(object.username);
    } else {
      message.username = "";
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = "";
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = String(object.avatar);
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(String(e));
      }
    }
    if (object.tags !== undefined && object.tags !== null) {
      for (const e of object.tags) {
        message.tags.push(String(e));
      }
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = String(object.phone);
    } else {
      message.phone = "";
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: UserDataResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    message.userId !== undefined && (obj.userId = message.userId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    message.username !== undefined && (obj.username = message.username);
    message.name !== undefined && (obj.name = message.name);
    message.avatar !== undefined && (obj.avatar = message.avatar);
    if (message.images) {
      obj.images = message.images.map((e) => e);
    } else {
      obj.images = [];
    }
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.email !== undefined && (obj.email = message.email);
    message.phone !== undefined && (obj.phone = message.phone);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<UserDataResponse>): UserDataResponse {
    const message = { ...baseUserDataResponse } as UserDataResponse;
    message.images = [];
    message.tags = [];
    message.data = {};
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = object.username;
    } else {
      message.username = "";
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = "";
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = object.avatar;
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(e);
      }
    }
    if (object.tags !== undefined && object.tags !== null) {
      for (const e of object.tags) {
        message.tags.push(e);
      }
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = object.phone;
    } else {
      message.phone = "";
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(UserDataResponse.$type, UserDataResponse);

const baseUserDataResponse_DataEntry: object = {
  $type: "ngmsg.UserDataResponse.DataEntry",
  key: "",
  value: "",
};

export const UserDataResponse_DataEntry = {
  $type: "ngmsg.UserDataResponse.DataEntry" as const,

  fromJSON(object: any): UserDataResponse_DataEntry {
    const message = {
      ...baseUserDataResponse_DataEntry,
    } as UserDataResponse_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: UserDataResponse_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UserDataResponse_DataEntry>
  ): UserDataResponse_DataEntry {
    const message = {
      ...baseUserDataResponse_DataEntry,
    } as UserDataResponse_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(
  UserDataResponse_DataEntry.$type,
  UserDataResponse_DataEntry
);

const baseUserDeleteRequest: object = {
  $type: "ngmsg.UserDeleteRequest",
  userId: 0,
  apiId: "",
};

export const UserDeleteRequest = {
  $type: "ngmsg.UserDeleteRequest" as const,

  fromJSON(object: any): UserDeleteRequest {
    const message = { ...baseUserDeleteRequest } as UserDeleteRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    return message;
  },

  toJSON(message: UserDeleteRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    return obj;
  },

  fromPartial(object: DeepPartial<UserDeleteRequest>): UserDeleteRequest {
    const message = { ...baseUserDeleteRequest } as UserDeleteRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    return message;
  },
};

messageTypeRegistry.set(UserDeleteRequest.$type, UserDeleteRequest);

const baseChannelDataRequest: object = {
  $type: "ngmsg.ChannelDataRequest",
  action: 0,
  channelId: 0,
  apiId: "",
  title: "",
  description: "",
  owner: 0,
  avatar: "",
  images: "",
  public: 0,
};

export const ChannelDataRequest = {
  $type: "ngmsg.ChannelDataRequest" as const,

  fromJSON(object: any): ChannelDataRequest {
    const message = { ...baseChannelDataRequest } as ChannelDataRequest;
    message.images = [];
    message.data = {};
    if (object.action !== undefined && object.action !== null) {
      message.action = enumCrudActionFromJSON(object.action);
    } else {
      message.action = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = "";
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = String(object.description);
    } else {
      message.description = "";
    }
    if (object.owner !== undefined && object.owner !== null) {
      message.owner = Number(object.owner);
    } else {
      message.owner = 0;
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = String(object.avatar);
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(String(e));
      }
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = enumChannelPublicFromJSON(object.public);
    } else {
      message.public = 0;
    }
    return message;
  },

  toJSON(message: ChannelDataRequest): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = enumCrudActionToJSON(message.action));
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined &&
      (obj.description = message.description);
    message.owner !== undefined && (obj.owner = message.owner);
    message.avatar !== undefined && (obj.avatar = message.avatar);
    if (message.images) {
      obj.images = message.images.map((e) => e);
    } else {
      obj.images = [];
    }
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    message.public !== undefined &&
      (obj.public = enumChannelPublicToJSON(message.public));
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelDataRequest>): ChannelDataRequest {
    const message = { ...baseChannelDataRequest } as ChannelDataRequest;
    message.images = [];
    message.data = {};
    if (object.action !== undefined && object.action !== null) {
      message.action = object.action;
    } else {
      message.action = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = "";
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    } else {
      message.description = "";
    }
    if (object.owner !== undefined && object.owner !== null) {
      message.owner = object.owner;
    } else {
      message.owner = 0;
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = object.avatar;
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(e);
      }
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = object.public;
    } else {
      message.public = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelDataRequest.$type, ChannelDataRequest);

const baseChannelDataRequest_DataEntry: object = {
  $type: "ngmsg.ChannelDataRequest.DataEntry",
  key: "",
  value: "",
};

export const ChannelDataRequest_DataEntry = {
  $type: "ngmsg.ChannelDataRequest.DataEntry" as const,

  fromJSON(object: any): ChannelDataRequest_DataEntry {
    const message = {
      ...baseChannelDataRequest_DataEntry,
    } as ChannelDataRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: ChannelDataRequest_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ChannelDataRequest_DataEntry>
  ): ChannelDataRequest_DataEntry {
    const message = {
      ...baseChannelDataRequest_DataEntry,
    } as ChannelDataRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(
  ChannelDataRequest_DataEntry.$type,
  ChannelDataRequest_DataEntry
);

const baseChannelDataResponse: object = {
  $type: "ngmsg.ChannelDataResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
  channelId: 0,
  apiId: "",
  title: "",
  description: "",
  owner: 0,
  avatar: "",
  images: "",
  direct: false,
  public: 0,
};

export const ChannelDataResponse = {
  $type: "ngmsg.ChannelDataResponse" as const,

  fromJSON(object: any): ChannelDataResponse {
    const message = { ...baseChannelDataResponse } as ChannelDataResponse;
    message.images = [];
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = "";
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = String(object.description);
    } else {
      message.description = "";
    }
    if (object.owner !== undefined && object.owner !== null) {
      message.owner = Number(object.owner);
    } else {
      message.owner = 0;
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = String(object.avatar);
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(String(e));
      }
    }
    if (object.direct !== undefined && object.direct !== null) {
      message.direct = Boolean(object.direct);
    } else {
      message.direct = false;
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = enumChannelPublicFromJSON(object.public);
    } else {
      message.public = 0;
    }
    return message;
  },

  toJSON(message: ChannelDataResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined &&
      (obj.description = message.description);
    message.owner !== undefined && (obj.owner = message.owner);
    message.avatar !== undefined && (obj.avatar = message.avatar);
    if (message.images) {
      obj.images = message.images.map((e) => e);
    } else {
      obj.images = [];
    }
    message.direct !== undefined && (obj.direct = message.direct);
    message.public !== undefined &&
      (obj.public = enumChannelPublicToJSON(message.public));
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelDataResponse>): ChannelDataResponse {
    const message = { ...baseChannelDataResponse } as ChannelDataResponse;
    message.images = [];
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = "";
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    } else {
      message.description = "";
    }
    if (object.owner !== undefined && object.owner !== null) {
      message.owner = object.owner;
    } else {
      message.owner = 0;
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = object.avatar;
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(e);
      }
    }
    if (object.direct !== undefined && object.direct !== null) {
      message.direct = object.direct;
    } else {
      message.direct = false;
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = object.public;
    } else {
      message.public = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelDataResponse.$type, ChannelDataResponse);

const baseChannelGetRequest: object = {
  $type: "ngmsg.ChannelGetRequest",
  channelId: 0,
  apiId: "",
  title: "",
};

export const ChannelGetRequest = {
  $type: "ngmsg.ChannelGetRequest" as const,

  fromJSON(object: any): ChannelGetRequest {
    const message = { ...baseChannelGetRequest } as ChannelGetRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = "";
    }
    return message;
  },

  toJSON(message: ChannelGetRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelGetRequest>): ChannelGetRequest {
    const message = { ...baseChannelGetRequest } as ChannelGetRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = "";
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelGetRequest.$type, ChannelGetRequest);

const baseChannelDeleteRequest: object = {
  $type: "ngmsg.ChannelDeleteRequest",
  channelId: 0,
  apiId: "",
};

export const ChannelDeleteRequest = {
  $type: "ngmsg.ChannelDeleteRequest" as const,

  fromJSON(object: any): ChannelDeleteRequest {
    const message = { ...baseChannelDeleteRequest } as ChannelDeleteRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    return message;
  },

  toJSON(message: ChannelDeleteRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelDeleteRequest>): ChannelDeleteRequest {
    const message = { ...baseChannelDeleteRequest } as ChannelDeleteRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelDeleteRequest.$type, ChannelDeleteRequest);

const baseChannelTruncateRequest: object = {
  $type: "ngmsg.ChannelTruncateRequest",
  channelId: 0,
};

export const ChannelTruncateRequest = {
  $type: "ngmsg.ChannelTruncateRequest" as const,

  fromJSON(object: any): ChannelTruncateRequest {
    const message = { ...baseChannelTruncateRequest } as ChannelTruncateRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    return message;
  },

  toJSON(message: ChannelTruncateRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ChannelTruncateRequest>
  ): ChannelTruncateRequest {
    const message = { ...baseChannelTruncateRequest } as ChannelTruncateRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelTruncateRequest.$type, ChannelTruncateRequest);

const baseChannelPublicRequest: object = {
  $type: "ngmsg.ChannelPublicRequest",
  channelId: 0,
  public: 0,
};

export const ChannelPublicRequest = {
  $type: "ngmsg.ChannelPublicRequest" as const,

  fromJSON(object: any): ChannelPublicRequest {
    const message = { ...baseChannelPublicRequest } as ChannelPublicRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = enumChannelPublicFromJSON(object.public);
    } else {
      message.public = 0;
    }
    return message;
  },

  toJSON(message: ChannelPublicRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.public !== undefined &&
      (obj.public = enumChannelPublicToJSON(message.public));
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelPublicRequest>): ChannelPublicRequest {
    const message = { ...baseChannelPublicRequest } as ChannelPublicRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = object.public;
    } else {
      message.public = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelPublicRequest.$type, ChannelPublicRequest);

const baseChannelMemberRequest: object = {
  $type: "ngmsg.ChannelMemberRequest",
  channelId: 0,
  userId: 0,
  role: 0,
};

export const ChannelMemberRequest = {
  $type: "ngmsg.ChannelMemberRequest" as const,

  fromJSON(object: any): ChannelMemberRequest {
    const message = { ...baseChannelMemberRequest } as ChannelMemberRequest;
    message.userId = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      for (const e of object.userId) {
        message.userId.push(Number(e));
      }
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = enumChannelRoleFromJSON(object.role);
    } else {
      message.role = 0;
    }
    return message;
  },

  toJSON(message: ChannelMemberRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    if (message.userId) {
      obj.userId = message.userId.map((e) => e);
    } else {
      obj.userId = [];
    }
    message.role !== undefined &&
      (obj.role = enumChannelRoleToJSON(message.role));
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelMemberRequest>): ChannelMemberRequest {
    const message = { ...baseChannelMemberRequest } as ChannelMemberRequest;
    message.userId = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      for (const e of object.userId) {
        message.userId.push(e);
      }
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = object.role;
    } else {
      message.role = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelMemberRequest.$type, ChannelMemberRequest);

const baseGenericResponse: object = {
  $type: "ngmsg.GenericResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
};

export const GenericResponse = {
  $type: "ngmsg.GenericResponse" as const,

  fromJSON(object: any): GenericResponse {
    const message = { ...baseGenericResponse } as GenericResponse;
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    return message;
  },

  toJSON(message: GenericResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    return obj;
  },

  fromPartial(object: DeepPartial<GenericResponse>): GenericResponse {
    const message = { ...baseGenericResponse } as GenericResponse;
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    return message;
  },
};

messageTypeRegistry.set(GenericResponse.$type, GenericResponse);

const baseUserMuteRequest: object = {
  $type: "ngmsg.UserMuteRequest",
  userId: 0,
  channelId: 0,
};

export const UserMuteRequest = {
  $type: "ngmsg.UserMuteRequest" as const,

  fromJSON(object: any): UserMuteRequest {
    const message = { ...baseUserMuteRequest } as UserMuteRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    return message;
  },

  toJSON(message: UserMuteRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    return obj;
  },

  fromPartial(object: DeepPartial<UserMuteRequest>): UserMuteRequest {
    const message = { ...baseUserMuteRequest } as UserMuteRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(UserMuteRequest.$type, UserMuteRequest);

const baseUserBanRequest: object = { $type: "ngmsg.UserBanRequest", userId: 0 };

export const UserBanRequest = {
  $type: "ngmsg.UserBanRequest" as const,

  fromJSON(object: any): UserBanRequest {
    const message = { ...baseUserBanRequest } as UserBanRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    return message;
  },

  toJSON(message: UserBanRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  fromPartial(object: DeepPartial<UserBanRequest>): UserBanRequest {
    const message = { ...baseUserBanRequest } as UserBanRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(UserBanRequest.$type, UserBanRequest);

const baseUserUnbanRequest: object = {
  $type: "ngmsg.UserUnbanRequest",
  userId: 0,
};

export const UserUnbanRequest = {
  $type: "ngmsg.UserUnbanRequest" as const,

  fromJSON(object: any): UserUnbanRequest {
    const message = { ...baseUserUnbanRequest } as UserUnbanRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    return message;
  },

  toJSON(message: UserUnbanRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  fromPartial(object: DeepPartial<UserUnbanRequest>): UserUnbanRequest {
    const message = { ...baseUserUnbanRequest } as UserUnbanRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(UserUnbanRequest.$type, UserUnbanRequest);

const basePostRequest: object = {
  $type: "ngmsg.PostRequest",
  channelId: 0,
  userId: 0,
  post: "",
  isJson: false,
  replyTo: 0,
  updatePostId: 0,
};

export const PostRequest = {
  $type: "ngmsg.PostRequest" as const,

  fromJSON(object: any): PostRequest {
    const message = { ...basePostRequest } as PostRequest;
    message.data = {};
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = String(object.post);
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = Boolean(object.isJson);
    } else {
      message.isJson = false;
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = Number(object.replyTo);
    } else {
      message.replyTo = 0;
    }
    if (object.updatePostId !== undefined && object.updatePostId !== null) {
      message.updatePostId = Number(object.updatePostId);
    } else {
      message.updatePostId = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: PostRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.post !== undefined && (obj.post = message.post);
    message.isJson !== undefined && (obj.isJson = message.isJson);
    message.replyTo !== undefined && (obj.replyTo = message.replyTo);
    message.updatePostId !== undefined &&
      (obj.updatePostId = message.updatePostId);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<PostRequest>): PostRequest {
    const message = { ...basePostRequest } as PostRequest;
    message.data = {};
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = object.post;
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = object.isJson;
    } else {
      message.isJson = false;
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = object.replyTo;
    } else {
      message.replyTo = 0;
    }
    if (object.updatePostId !== undefined && object.updatePostId !== null) {
      message.updatePostId = object.updatePostId;
    } else {
      message.updatePostId = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(PostRequest.$type, PostRequest);

const basePostRequest_DataEntry: object = {
  $type: "ngmsg.PostRequest.DataEntry",
  key: "",
  value: "",
};

export const PostRequest_DataEntry = {
  $type: "ngmsg.PostRequest.DataEntry" as const,

  fromJSON(object: any): PostRequest_DataEntry {
    const message = { ...basePostRequest_DataEntry } as PostRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: PostRequest_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<PostRequest_DataEntry>
  ): PostRequest_DataEntry {
    const message = { ...basePostRequest_DataEntry } as PostRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(PostRequest_DataEntry.$type, PostRequest_DataEntry);

const basePostResponse: object = {
  $type: "ngmsg.PostResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
  postId: 0,
  time: 0,
  post: "",
  isJson: false,
  replyTo: 0,
};

export const PostResponse = {
  $type: "ngmsg.PostResponse" as const,

  fromJSON(object: any): PostResponse {
    const message = { ...basePostResponse } as PostResponse;
    message.data = {};
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = Number(object.time);
    } else {
      message.time = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = String(object.post);
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = Boolean(object.isJson);
    } else {
      message.isJson = false;
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = Number(object.replyTo);
    } else {
      message.replyTo = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: PostResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    message.postId !== undefined && (obj.postId = message.postId);
    message.time !== undefined && (obj.time = message.time);
    message.post !== undefined && (obj.post = message.post);
    message.isJson !== undefined && (obj.isJson = message.isJson);
    message.replyTo !== undefined && (obj.replyTo = message.replyTo);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<PostResponse>): PostResponse {
    const message = { ...basePostResponse } as PostResponse;
    message.data = {};
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = object.time;
    } else {
      message.time = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = object.post;
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = object.isJson;
    } else {
      message.isJson = false;
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = object.replyTo;
    } else {
      message.replyTo = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(PostResponse.$type, PostResponse);

const basePostResponse_DataEntry: object = {
  $type: "ngmsg.PostResponse.DataEntry",
  key: "",
  value: "",
};

export const PostResponse_DataEntry = {
  $type: "ngmsg.PostResponse.DataEntry" as const,

  fromJSON(object: any): PostResponse_DataEntry {
    const message = { ...basePostResponse_DataEntry } as PostResponse_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: PostResponse_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<PostResponse_DataEntry>
  ): PostResponse_DataEntry {
    const message = { ...basePostResponse_DataEntry } as PostResponse_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(PostResponse_DataEntry.$type, PostResponse_DataEntry);

const basePostDeleteRequest: object = {
  $type: "ngmsg.PostDeleteRequest",
  postId: 0,
  userId: 0,
};

export const PostDeleteRequest = {
  $type: "ngmsg.PostDeleteRequest" as const,

  fromJSON(object: any): PostDeleteRequest {
    const message = { ...basePostDeleteRequest } as PostDeleteRequest;
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    return message;
  },

  toJSON(message: PostDeleteRequest): unknown {
    const obj: any = {};
    message.postId !== undefined && (obj.postId = message.postId);
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  fromPartial(object: DeepPartial<PostDeleteRequest>): PostDeleteRequest {
    const message = { ...basePostDeleteRequest } as PostDeleteRequest;
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(PostDeleteRequest.$type, PostDeleteRequest);

const baseReactionRequest: object = {
  $type: "ngmsg.ReactionRequest",
  postId: 0,
  userId: 0,
  reactionType: 0,
  reaction: "",
  delete: false,
};

export const ReactionRequest = {
  $type: "ngmsg.ReactionRequest" as const,

  fromJSON(object: any): ReactionRequest {
    const message = { ...baseReactionRequest } as ReactionRequest;
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.reactionType !== undefined && object.reactionType !== null) {
      message.reactionType = Number(object.reactionType);
    } else {
      message.reactionType = 0;
    }
    if (object.reaction !== undefined && object.reaction !== null) {
      message.reaction = String(object.reaction);
    } else {
      message.reaction = "";
    }
    if (object.delete !== undefined && object.delete !== null) {
      message.delete = Boolean(object.delete);
    } else {
      message.delete = false;
    }
    return message;
  },

  toJSON(message: ReactionRequest): unknown {
    const obj: any = {};
    message.postId !== undefined && (obj.postId = message.postId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.reactionType !== undefined &&
      (obj.reactionType = message.reactionType);
    message.reaction !== undefined && (obj.reaction = message.reaction);
    message.delete !== undefined && (obj.delete = message.delete);
    return obj;
  },

  fromPartial(object: DeepPartial<ReactionRequest>): ReactionRequest {
    const message = { ...baseReactionRequest } as ReactionRequest;
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.reactionType !== undefined && object.reactionType !== null) {
      message.reactionType = object.reactionType;
    } else {
      message.reactionType = 0;
    }
    if (object.reaction !== undefined && object.reaction !== null) {
      message.reaction = object.reaction;
    } else {
      message.reaction = "";
    }
    if (object.delete !== undefined && object.delete !== null) {
      message.delete = object.delete;
    } else {
      message.delete = false;
    }
    return message;
  },
};

messageTypeRegistry.set(ReactionRequest.$type, ReactionRequest);

const baseInviteDirectRequest: object = {
  $type: "ngmsg.InviteDirectRequest",
  userId: 0,
  inviteUserId: 0,
  post: "",
  isJson: false,
};

export const InviteDirectRequest = {
  $type: "ngmsg.InviteDirectRequest" as const,

  fromJSON(object: any): InviteDirectRequest {
    const message = { ...baseInviteDirectRequest } as InviteDirectRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.inviteUserId !== undefined && object.inviteUserId !== null) {
      message.inviteUserId = Number(object.inviteUserId);
    } else {
      message.inviteUserId = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = String(object.post);
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = Boolean(object.isJson);
    } else {
      message.isJson = false;
    }
    return message;
  },

  toJSON(message: InviteDirectRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.inviteUserId !== undefined &&
      (obj.inviteUserId = message.inviteUserId);
    message.post !== undefined && (obj.post = message.post);
    message.isJson !== undefined && (obj.isJson = message.isJson);
    return obj;
  },

  fromPartial(object: DeepPartial<InviteDirectRequest>): InviteDirectRequest {
    const message = { ...baseInviteDirectRequest } as InviteDirectRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.inviteUserId !== undefined && object.inviteUserId !== null) {
      message.inviteUserId = object.inviteUserId;
    } else {
      message.inviteUserId = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = object.post;
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = object.isJson;
    } else {
      message.isJson = false;
    }
    return message;
  },
};

messageTypeRegistry.set(InviteDirectRequest.$type, InviteDirectRequest);

const baseSendInviteRequest: object = {
  $type: "ngmsg.SendInviteRequest",
  channelId: 0,
  userIds: 0,
  post: "",
  isJson: false,
};

export const SendInviteRequest = {
  $type: "ngmsg.SendInviteRequest" as const,

  fromJSON(object: any): SendInviteRequest {
    const message = { ...baseSendInviteRequest } as SendInviteRequest;
    message.userIds = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.userIds !== undefined && object.userIds !== null) {
      for (const e of object.userIds) {
        message.userIds.push(Number(e));
      }
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = String(object.post);
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = Boolean(object.isJson);
    } else {
      message.isJson = false;
    }
    return message;
  },

  toJSON(message: SendInviteRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    if (message.userIds) {
      obj.userIds = message.userIds.map((e) => e);
    } else {
      obj.userIds = [];
    }
    message.post !== undefined && (obj.post = message.post);
    message.isJson !== undefined && (obj.isJson = message.isJson);
    return obj;
  },

  fromPartial(object: DeepPartial<SendInviteRequest>): SendInviteRequest {
    const message = { ...baseSendInviteRequest } as SendInviteRequest;
    message.userIds = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.userIds !== undefined && object.userIds !== null) {
      for (const e of object.userIds) {
        message.userIds.push(e);
      }
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = object.post;
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = object.isJson;
    } else {
      message.isJson = false;
    }
    return message;
  },
};

messageTypeRegistry.set(SendInviteRequest.$type, SendInviteRequest);

const baseReplyInviteRequest: object = {
  $type: "ngmsg.ReplyInviteRequest",
  inviteId: 0,
  accepted: false,
};

export const ReplyInviteRequest = {
  $type: "ngmsg.ReplyInviteRequest" as const,

  fromJSON(object: any): ReplyInviteRequest {
    const message = { ...baseReplyInviteRequest } as ReplyInviteRequest;
    if (object.inviteId !== undefined && object.inviteId !== null) {
      message.inviteId = Number(object.inviteId);
    } else {
      message.inviteId = 0;
    }
    if (object.accepted !== undefined && object.accepted !== null) {
      message.accepted = Boolean(object.accepted);
    } else {
      message.accepted = false;
    }
    return message;
  },

  toJSON(message: ReplyInviteRequest): unknown {
    const obj: any = {};
    message.inviteId !== undefined && (obj.inviteId = message.inviteId);
    message.accepted !== undefined && (obj.accepted = message.accepted);
    return obj;
  },

  fromPartial(object: DeepPartial<ReplyInviteRequest>): ReplyInviteRequest {
    const message = { ...baseReplyInviteRequest } as ReplyInviteRequest;
    if (object.inviteId !== undefined && object.inviteId !== null) {
      message.inviteId = object.inviteId;
    } else {
      message.inviteId = 0;
    }
    if (object.accepted !== undefined && object.accepted !== null) {
      message.accepted = object.accepted;
    } else {
      message.accepted = false;
    }
    return message;
  },
};

messageTypeRegistry.set(ReplyInviteRequest.$type, ReplyInviteRequest);

const baseChannelMarkReadRequest: object = {
  $type: "ngmsg.ChannelMarkReadRequest",
  channelId: 0,
  userId: 0,
  readPostId: 0,
};

export const ChannelMarkReadRequest = {
  $type: "ngmsg.ChannelMarkReadRequest" as const,

  fromJSON(object: any): ChannelMarkReadRequest {
    const message = { ...baseChannelMarkReadRequest } as ChannelMarkReadRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.readPostId !== undefined && object.readPostId !== null) {
      message.readPostId = Number(object.readPostId);
    } else {
      message.readPostId = 0;
    }
    return message;
  },

  toJSON(message: ChannelMarkReadRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.readPostId !== undefined && (obj.readPostId = message.readPostId);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ChannelMarkReadRequest>
  ): ChannelMarkReadRequest {
    const message = { ...baseChannelMarkReadRequest } as ChannelMarkReadRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.readPostId !== undefined && object.readPostId !== null) {
      message.readPostId = object.readPostId;
    } else {
      message.readPostId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelMarkReadRequest.$type, ChannelMarkReadRequest);

const baseChannelMuteRequest: object = {
  $type: "ngmsg.ChannelMuteRequest",
  channelId: 0,
  mute: false,
};

export const ChannelMuteRequest = {
  $type: "ngmsg.ChannelMuteRequest" as const,

  fromJSON(object: any): ChannelMuteRequest {
    const message = { ...baseChannelMuteRequest } as ChannelMuteRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.mute !== undefined && object.mute !== null) {
      message.mute = Boolean(object.mute);
    } else {
      message.mute = false;
    }
    return message;
  },

  toJSON(message: ChannelMuteRequest): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.mute !== undefined && (obj.mute = message.mute);
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelMuteRequest>): ChannelMuteRequest {
    const message = { ...baseChannelMuteRequest } as ChannelMuteRequest;
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.mute !== undefined && object.mute !== null) {
      message.mute = object.mute;
    } else {
      message.mute = false;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelMuteRequest.$type, ChannelMuteRequest);

const baseCustomRequest: object = {
  $type: "ngmsg.CustomRequest",
  userId: 0,
  channelId: 0,
  postId: 0,
  customRequestType: "",
  request: "",
  requestData: "",
};

export const CustomRequest = {
  $type: "ngmsg.CustomRequest" as const,

  fromJSON(object: any): CustomRequest {
    const message = { ...baseCustomRequest } as CustomRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (
      object.customRequestType !== undefined &&
      object.customRequestType !== null
    ) {
      message.customRequestType = String(object.customRequestType);
    } else {
      message.customRequestType = "";
    }
    if (object.request !== undefined && object.request !== null) {
      message.request = String(object.request);
    } else {
      message.request = "";
    }
    if (object.requestData !== undefined && object.requestData !== null) {
      message.requestData = String(object.requestData);
    } else {
      message.requestData = "";
    }
    return message;
  },

  toJSON(message: CustomRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.postId !== undefined && (obj.postId = message.postId);
    message.customRequestType !== undefined &&
      (obj.customRequestType = message.customRequestType);
    message.request !== undefined && (obj.request = message.request);
    message.requestData !== undefined &&
      (obj.requestData = message.requestData);
    return obj;
  },

  fromPartial(object: DeepPartial<CustomRequest>): CustomRequest {
    const message = { ...baseCustomRequest } as CustomRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (
      object.customRequestType !== undefined &&
      object.customRequestType !== null
    ) {
      message.customRequestType = object.customRequestType;
    } else {
      message.customRequestType = "";
    }
    if (object.request !== undefined && object.request !== null) {
      message.request = object.request;
    } else {
      message.request = "";
    }
    if (object.requestData !== undefined && object.requestData !== null) {
      message.requestData = object.requestData;
    } else {
      message.requestData = "";
    }
    return message;
  },
};

messageTypeRegistry.set(CustomRequest.$type, CustomRequest);

const baseCustomResponse: object = {
  $type: "ngmsg.CustomResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
  userId: 0,
  channelId: 0,
  postId: 0,
  responseData: "",
};

export const CustomResponse = {
  $type: "ngmsg.CustomResponse" as const,

  fromJSON(object: any): CustomResponse {
    const message = { ...baseCustomResponse } as CustomResponse;
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (object.responseData !== undefined && object.responseData !== null) {
      message.responseData = String(object.responseData);
    } else {
      message.responseData = "";
    }
    return message;
  },

  toJSON(message: CustomResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.postId !== undefined && (obj.postId = message.postId);
    message.responseData !== undefined &&
      (obj.responseData = message.responseData);
    return obj;
  },

  fromPartial(object: DeepPartial<CustomResponse>): CustomResponse {
    const message = { ...baseCustomResponse } as CustomResponse;
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (object.responseData !== undefined && object.responseData !== null) {
      message.responseData = object.responseData;
    } else {
      message.responseData = "";
    }
    return message;
  },
};

messageTypeRegistry.set(CustomResponse.$type, CustomResponse);

const baseClientGetChannelsRequest: object = {
  $type: "ngmsg.ClientGetChannelsRequest",
  userId: 0,
  watch: false,
  sort: "",
  filters: "",
  limit: 0,
  start: 0,
  postsLimit: 0,
  usersLimit: 0,
};

export const ClientGetChannelsRequest = {
  $type: "ngmsg.ClientGetChannelsRequest" as const,

  fromJSON(object: any): ClientGetChannelsRequest {
    const message = {
      ...baseClientGetChannelsRequest,
    } as ClientGetChannelsRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.watch !== undefined && object.watch !== null) {
      message.watch = Boolean(object.watch);
    } else {
      message.watch = false;
    }
    if (object.sort !== undefined && object.sort !== null) {
      message.sort = String(object.sort);
    } else {
      message.sort = "";
    }
    if (object.filters !== undefined && object.filters !== null) {
      message.filters = String(object.filters);
    } else {
      message.filters = "";
    }
    if (object.limit !== undefined && object.limit !== null) {
      message.limit = Number(object.limit);
    } else {
      message.limit = 0;
    }
    if (object.start !== undefined && object.start !== null) {
      message.start = Number(object.start);
    } else {
      message.start = 0;
    }
    if (object.postsLimit !== undefined && object.postsLimit !== null) {
      message.postsLimit = Number(object.postsLimit);
    } else {
      message.postsLimit = 0;
    }
    if (object.usersLimit !== undefined && object.usersLimit !== null) {
      message.usersLimit = Number(object.usersLimit);
    } else {
      message.usersLimit = 0;
    }
    return message;
  },

  toJSON(message: ClientGetChannelsRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.watch !== undefined && (obj.watch = message.watch);
    message.sort !== undefined && (obj.sort = message.sort);
    message.filters !== undefined && (obj.filters = message.filters);
    message.limit !== undefined && (obj.limit = message.limit);
    message.start !== undefined && (obj.start = message.start);
    message.postsLimit !== undefined && (obj.postsLimit = message.postsLimit);
    message.usersLimit !== undefined && (obj.usersLimit = message.usersLimit);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ClientGetChannelsRequest>
  ): ClientGetChannelsRequest {
    const message = {
      ...baseClientGetChannelsRequest,
    } as ClientGetChannelsRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.watch !== undefined && object.watch !== null) {
      message.watch = object.watch;
    } else {
      message.watch = false;
    }
    if (object.sort !== undefined && object.sort !== null) {
      message.sort = object.sort;
    } else {
      message.sort = "";
    }
    if (object.filters !== undefined && object.filters !== null) {
      message.filters = object.filters;
    } else {
      message.filters = "";
    }
    if (object.limit !== undefined && object.limit !== null) {
      message.limit = object.limit;
    } else {
      message.limit = 0;
    }
    if (object.start !== undefined && object.start !== null) {
      message.start = object.start;
    } else {
      message.start = 0;
    }
    if (object.postsLimit !== undefined && object.postsLimit !== null) {
      message.postsLimit = object.postsLimit;
    } else {
      message.postsLimit = 0;
    }
    if (object.usersLimit !== undefined && object.usersLimit !== null) {
      message.usersLimit = object.usersLimit;
    } else {
      message.usersLimit = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(
  ClientGetChannelsRequest.$type,
  ClientGetChannelsRequest
);

const baseClientUserData: object = {
  $type: "ngmsg.ClientUserData",
  userId: 0,
  username: "",
  name: "",
  avatar: "",
  images: "",
  tags: "",
  clientLastOnline: 0,
};

export const ClientUserData = {
  $type: "ngmsg.ClientUserData" as const,

  fromJSON(object: any): ClientUserData {
    const message = { ...baseClientUserData } as ClientUserData;
    message.images = [];
    message.tags = [];
    message.data = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = String(object.username);
    } else {
      message.username = "";
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = "";
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = String(object.avatar);
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(String(e));
      }
    }
    if (object.tags !== undefined && object.tags !== null) {
      for (const e of object.tags) {
        message.tags.push(String(e));
      }
    }
    if (
      object.clientLastOnline !== undefined &&
      object.clientLastOnline !== null
    ) {
      message.clientLastOnline = Number(object.clientLastOnline);
    } else {
      message.clientLastOnline = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: ClientUserData): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.username !== undefined && (obj.username = message.username);
    message.name !== undefined && (obj.name = message.name);
    message.avatar !== undefined && (obj.avatar = message.avatar);
    if (message.images) {
      obj.images = message.images.map((e) => e);
    } else {
      obj.images = [];
    }
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.clientLastOnline !== undefined &&
      (obj.clientLastOnline = message.clientLastOnline);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ClientUserData>): ClientUserData {
    const message = { ...baseClientUserData } as ClientUserData;
    message.images = [];
    message.tags = [];
    message.data = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = object.username;
    } else {
      message.username = "";
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = "";
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = object.avatar;
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(e);
      }
    }
    if (object.tags !== undefined && object.tags !== null) {
      for (const e of object.tags) {
        message.tags.push(e);
      }
    }
    if (
      object.clientLastOnline !== undefined &&
      object.clientLastOnline !== null
    ) {
      message.clientLastOnline = object.clientLastOnline;
    } else {
      message.clientLastOnline = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(ClientUserData.$type, ClientUserData);

const baseClientUserData_DataEntry: object = {
  $type: "ngmsg.ClientUserData.DataEntry",
  key: "",
  value: "",
};

export const ClientUserData_DataEntry = {
  $type: "ngmsg.ClientUserData.DataEntry" as const,

  fromJSON(object: any): ClientUserData_DataEntry {
    const message = {
      ...baseClientUserData_DataEntry,
    } as ClientUserData_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: ClientUserData_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ClientUserData_DataEntry>
  ): ClientUserData_DataEntry {
    const message = {
      ...baseClientUserData_DataEntry,
    } as ClientUserData_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(
  ClientUserData_DataEntry.$type,
  ClientUserData_DataEntry
);

const baseClientPostData: object = {
  $type: "ngmsg.ClientPostData",
  postId: 0,
  channelId: 0,
  userId: 0,
  time: 0,
  post: "",
  isJson: false,
  replyTo: 0,
  updatePostId: 0,
};

export const ClientPostData = {
  $type: "ngmsg.ClientPostData" as const,

  fromJSON(object: any): ClientPostData {
    const message = { ...baseClientPostData } as ClientPostData;
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = Number(object.time);
    } else {
      message.time = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = String(object.post);
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = Boolean(object.isJson);
    } else {
      message.isJson = false;
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = Number(object.replyTo);
    } else {
      message.replyTo = 0;
    }
    if (object.updatePostId !== undefined && object.updatePostId !== null) {
      message.updatePostId = Number(object.updatePostId);
    } else {
      message.updatePostId = 0;
    }
    return message;
  },

  toJSON(message: ClientPostData): unknown {
    const obj: any = {};
    message.postId !== undefined && (obj.postId = message.postId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.time !== undefined && (obj.time = message.time);
    message.post !== undefined && (obj.post = message.post);
    message.isJson !== undefined && (obj.isJson = message.isJson);
    message.replyTo !== undefined && (obj.replyTo = message.replyTo);
    message.updatePostId !== undefined &&
      (obj.updatePostId = message.updatePostId);
    return obj;
  },

  fromPartial(object: DeepPartial<ClientPostData>): ClientPostData {
    const message = { ...baseClientPostData } as ClientPostData;
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = object.time;
    } else {
      message.time = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = object.post;
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = object.isJson;
    } else {
      message.isJson = false;
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = object.replyTo;
    } else {
      message.replyTo = 0;
    }
    if (object.updatePostId !== undefined && object.updatePostId !== null) {
      message.updatePostId = object.updatePostId;
    } else {
      message.updatePostId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ClientPostData.$type, ClientPostData);

const baseClientChannelData: object = {
  $type: "ngmsg.ClientChannelData",
  channelId: 0,
  title: "",
  description: "",
  belongsToYou: false,
  public: 0,
  avatar: "",
  images: "",
  lastPostId: 0,
  lastReadId: 0,
  directUserId: 0,
};

export const ClientChannelData = {
  $type: "ngmsg.ClientChannelData" as const,

  fromJSON(object: any): ClientChannelData {
    const message = { ...baseClientChannelData } as ClientChannelData;
    message.images = [];
    message.lastPosts = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = "";
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = String(object.description);
    } else {
      message.description = "";
    }
    if (object.belongsToYou !== undefined && object.belongsToYou !== null) {
      message.belongsToYou = Boolean(object.belongsToYou);
    } else {
      message.belongsToYou = false;
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = enumChannelPublicFromJSON(object.public);
    } else {
      message.public = 0;
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = String(object.avatar);
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(String(e));
      }
    }
    if (object.lastPostId !== undefined && object.lastPostId !== null) {
      message.lastPostId = Number(object.lastPostId);
    } else {
      message.lastPostId = 0;
    }
    if (object.lastReadId !== undefined && object.lastReadId !== null) {
      message.lastReadId = Number(object.lastReadId);
    } else {
      message.lastReadId = 0;
    }
    if (object.lastPosts !== undefined && object.lastPosts !== null) {
      for (const e of object.lastPosts) {
        message.lastPosts.push(ClientPostData.fromJSON(e));
      }
    }
    if (object.directUserId !== undefined && object.directUserId !== null) {
      message.directUserId = Number(object.directUserId);
    } else {
      message.directUserId = 0;
    }
    return message;
  },

  toJSON(message: ClientChannelData): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined &&
      (obj.description = message.description);
    message.belongsToYou !== undefined &&
      (obj.belongsToYou = message.belongsToYou);
    message.public !== undefined &&
      (obj.public = enumChannelPublicToJSON(message.public));
    message.avatar !== undefined && (obj.avatar = message.avatar);
    if (message.images) {
      obj.images = message.images.map((e) => e);
    } else {
      obj.images = [];
    }
    message.lastPostId !== undefined && (obj.lastPostId = message.lastPostId);
    message.lastReadId !== undefined && (obj.lastReadId = message.lastReadId);
    if (message.lastPosts) {
      obj.lastPosts = message.lastPosts.map((e) =>
        e ? ClientPostData.toJSON(e) : undefined
      );
    } else {
      obj.lastPosts = [];
    }
    message.directUserId !== undefined &&
      (obj.directUserId = message.directUserId);
    return obj;
  },

  fromPartial(object: DeepPartial<ClientChannelData>): ClientChannelData {
    const message = { ...baseClientChannelData } as ClientChannelData;
    message.images = [];
    message.lastPosts = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = "";
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    } else {
      message.description = "";
    }
    if (object.belongsToYou !== undefined && object.belongsToYou !== null) {
      message.belongsToYou = object.belongsToYou;
    } else {
      message.belongsToYou = false;
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = object.public;
    } else {
      message.public = 0;
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = object.avatar;
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(e);
      }
    }
    if (object.lastPostId !== undefined && object.lastPostId !== null) {
      message.lastPostId = object.lastPostId;
    } else {
      message.lastPostId = 0;
    }
    if (object.lastReadId !== undefined && object.lastReadId !== null) {
      message.lastReadId = object.lastReadId;
    } else {
      message.lastReadId = 0;
    }
    if (object.lastPosts !== undefined && object.lastPosts !== null) {
      for (const e of object.lastPosts) {
        message.lastPosts.push(ClientPostData.fromPartial(e));
      }
    }
    if (object.directUserId !== undefined && object.directUserId !== null) {
      message.directUserId = object.directUserId;
    } else {
      message.directUserId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ClientChannelData.$type, ClientChannelData);

const baseClientGetChannelsResponse: object = {
  $type: "ngmsg.ClientGetChannelsResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
  hasMore: false,
};

export const ClientGetChannelsResponse = {
  $type: "ngmsg.ClientGetChannelsResponse" as const,

  fromJSON(object: any): ClientGetChannelsResponse {
    const message = {
      ...baseClientGetChannelsResponse,
    } as ClientGetChannelsResponse;
    message.channels = [];
    message.users = [];
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    if (object.hasMore !== undefined && object.hasMore !== null) {
      message.hasMore = Boolean(object.hasMore);
    } else {
      message.hasMore = false;
    }
    if (object.channels !== undefined && object.channels !== null) {
      for (const e of object.channels) {
        message.channels.push(ClientChannelData.fromJSON(e));
      }
    }
    if (object.users !== undefined && object.users !== null) {
      for (const e of object.users) {
        message.users.push(ClientUserData.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: ClientGetChannelsResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    message.hasMore !== undefined && (obj.hasMore = message.hasMore);
    if (message.channels) {
      obj.channels = message.channels.map((e) =>
        e ? ClientChannelData.toJSON(e) : undefined
      );
    } else {
      obj.channels = [];
    }
    if (message.users) {
      obj.users = message.users.map((e) =>
        e ? ClientUserData.toJSON(e) : undefined
      );
    } else {
      obj.users = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<ClientGetChannelsResponse>
  ): ClientGetChannelsResponse {
    const message = {
      ...baseClientGetChannelsResponse,
    } as ClientGetChannelsResponse;
    message.channels = [];
    message.users = [];
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    if (object.hasMore !== undefined && object.hasMore !== null) {
      message.hasMore = object.hasMore;
    } else {
      message.hasMore = false;
    }
    if (object.channels !== undefined && object.channels !== null) {
      for (const e of object.channels) {
        message.channels.push(ClientChannelData.fromPartial(e));
      }
    }
    if (object.users !== undefined && object.users !== null) {
      for (const e of object.users) {
        message.users.push(ClientUserData.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(
  ClientGetChannelsResponse.$type,
  ClientGetChannelsResponse
);

const baseClientFetchPostsRequest: object = {
  $type: "ngmsg.ClientFetchPostsRequest",
  userId: 0,
  channelId: 0,
  postIdStart: 0,
  messagesLimit: 0,
};

export const ClientFetchPostsRequest = {
  $type: "ngmsg.ClientFetchPostsRequest" as const,

  fromJSON(object: any): ClientFetchPostsRequest {
    const message = {
      ...baseClientFetchPostsRequest,
    } as ClientFetchPostsRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.postIdStart !== undefined && object.postIdStart !== null) {
      message.postIdStart = Number(object.postIdStart);
    } else {
      message.postIdStart = 0;
    }
    if (object.messagesLimit !== undefined && object.messagesLimit !== null) {
      message.messagesLimit = Number(object.messagesLimit);
    } else {
      message.messagesLimit = 0;
    }
    return message;
  },

  toJSON(message: ClientFetchPostsRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.postIdStart !== undefined &&
      (obj.postIdStart = message.postIdStart);
    message.messagesLimit !== undefined &&
      (obj.messagesLimit = message.messagesLimit);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ClientFetchPostsRequest>
  ): ClientFetchPostsRequest {
    const message = {
      ...baseClientFetchPostsRequest,
    } as ClientFetchPostsRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.postIdStart !== undefined && object.postIdStart !== null) {
      message.postIdStart = object.postIdStart;
    } else {
      message.postIdStart = 0;
    }
    if (object.messagesLimit !== undefined && object.messagesLimit !== null) {
      message.messagesLimit = object.messagesLimit;
    } else {
      message.messagesLimit = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ClientFetchPostsRequest.$type, ClientFetchPostsRequest);

const baseClientFetchPostsResponse: object = {
  $type: "ngmsg.ClientFetchPostsResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
  lastPostId: 0,
  hasMore: false,
};

export const ClientFetchPostsResponse = {
  $type: "ngmsg.ClientFetchPostsResponse" as const,

  fromJSON(object: any): ClientFetchPostsResponse {
    const message = {
      ...baseClientFetchPostsResponse,
    } as ClientFetchPostsResponse;
    message.posts = [];
    message.users = [];
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    if (object.posts !== undefined && object.posts !== null) {
      for (const e of object.posts) {
        message.posts.push(ClientPostData.fromJSON(e));
      }
    }
    if (object.users !== undefined && object.users !== null) {
      for (const e of object.users) {
        message.users.push(ClientUserData.fromJSON(e));
      }
    }
    if (object.lastPostId !== undefined && object.lastPostId !== null) {
      message.lastPostId = Number(object.lastPostId);
    } else {
      message.lastPostId = 0;
    }
    if (object.hasMore !== undefined && object.hasMore !== null) {
      message.hasMore = Boolean(object.hasMore);
    } else {
      message.hasMore = false;
    }
    return message;
  },

  toJSON(message: ClientFetchPostsResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    if (message.posts) {
      obj.posts = message.posts.map((e) =>
        e ? ClientPostData.toJSON(e) : undefined
      );
    } else {
      obj.posts = [];
    }
    if (message.users) {
      obj.users = message.users.map((e) =>
        e ? ClientUserData.toJSON(e) : undefined
      );
    } else {
      obj.users = [];
    }
    message.lastPostId !== undefined && (obj.lastPostId = message.lastPostId);
    message.hasMore !== undefined && (obj.hasMore = message.hasMore);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ClientFetchPostsResponse>
  ): ClientFetchPostsResponse {
    const message = {
      ...baseClientFetchPostsResponse,
    } as ClientFetchPostsResponse;
    message.posts = [];
    message.users = [];
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    if (object.posts !== undefined && object.posts !== null) {
      for (const e of object.posts) {
        message.posts.push(ClientPostData.fromPartial(e));
      }
    }
    if (object.users !== undefined && object.users !== null) {
      for (const e of object.users) {
        message.users.push(ClientUserData.fromPartial(e));
      }
    }
    if (object.lastPostId !== undefined && object.lastPostId !== null) {
      message.lastPostId = object.lastPostId;
    } else {
      message.lastPostId = 0;
    }
    if (object.hasMore !== undefined && object.hasMore !== null) {
      message.hasMore = object.hasMore;
    } else {
      message.hasMore = false;
    }
    return message;
  },
};

messageTypeRegistry.set(
  ClientFetchPostsResponse.$type,
  ClientFetchPostsResponse
);

const baseClientWatchRequest: object = {
  $type: "ngmsg.ClientWatchRequest",
  userId: 0,
  watchChannelIds: 0,
  unwatchChannelIds: 0,
};

export const ClientWatchRequest = {
  $type: "ngmsg.ClientWatchRequest" as const,

  fromJSON(object: any): ClientWatchRequest {
    const message = { ...baseClientWatchRequest } as ClientWatchRequest;
    message.watchChannelIds = [];
    message.unwatchChannelIds = [];
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (
      object.watchChannelIds !== undefined &&
      object.watchChannelIds !== null
    ) {
      for (const e of object.watchChannelIds) {
        message.watchChannelIds.push(Number(e));
      }
    }
    if (
      object.unwatchChannelIds !== undefined &&
      object.unwatchChannelIds !== null
    ) {
      for (const e of object.unwatchChannelIds) {
        message.unwatchChannelIds.push(Number(e));
      }
    }
    return message;
  },

  toJSON(message: ClientWatchRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    if (message.watchChannelIds) {
      obj.watchChannelIds = message.watchChannelIds.map((e) => e);
    } else {
      obj.watchChannelIds = [];
    }
    if (message.unwatchChannelIds) {
      obj.unwatchChannelIds = message.unwatchChannelIds.map((e) => e);
    } else {
      obj.unwatchChannelIds = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ClientWatchRequest>): ClientWatchRequest {
    const message = { ...baseClientWatchRequest } as ClientWatchRequest;
    message.watchChannelIds = [];
    message.unwatchChannelIds = [];
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (
      object.watchChannelIds !== undefined &&
      object.watchChannelIds !== null
    ) {
      for (const e of object.watchChannelIds) {
        message.watchChannelIds.push(e);
      }
    }
    if (
      object.unwatchChannelIds !== undefined &&
      object.unwatchChannelIds !== null
    ) {
      for (const e of object.unwatchChannelIds) {
        message.unwatchChannelIds.push(e);
      }
    }
    return message;
  },
};

messageTypeRegistry.set(ClientWatchRequest.$type, ClientWatchRequest);

const baseClientStatusRequest: object = {
  $type: "ngmsg.ClientStatusRequest",
  userId: 0,
  status: 0,
};

export const ClientStatusRequest = {
  $type: "ngmsg.ClientStatusRequest" as const,

  fromJSON(object: any): ClientStatusRequest {
    const message = { ...baseClientStatusRequest } as ClientStatusRequest;
    message.data = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = enumClientStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: ClientStatusRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.status !== undefined &&
      (obj.status = enumClientStatusToJSON(message.status));
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ClientStatusRequest>): ClientStatusRequest {
    const message = { ...baseClientStatusRequest } as ClientStatusRequest;
    message.data = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(ClientStatusRequest.$type, ClientStatusRequest);

const baseClientStatusRequest_DataEntry: object = {
  $type: "ngmsg.ClientStatusRequest.DataEntry",
  key: "",
  value: "",
};

export const ClientStatusRequest_DataEntry = {
  $type: "ngmsg.ClientStatusRequest.DataEntry" as const,

  fromJSON(object: any): ClientStatusRequest_DataEntry {
    const message = {
      ...baseClientStatusRequest_DataEntry,
    } as ClientStatusRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: ClientStatusRequest_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ClientStatusRequest_DataEntry>
  ): ClientStatusRequest_DataEntry {
    const message = {
      ...baseClientStatusRequest_DataEntry,
    } as ClientStatusRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(
  ClientStatusRequest_DataEntry.$type,
  ClientStatusRequest_DataEntry
);

const baseClientTypingRequest: object = {
  $type: "ngmsg.ClientTypingRequest",
  userId: 0,
  channelId: 0,
  typing: false,
  post: "",
  replyTo: 0,
};

export const ClientTypingRequest = {
  $type: "ngmsg.ClientTypingRequest" as const,

  fromJSON(object: any): ClientTypingRequest {
    const message = { ...baseClientTypingRequest } as ClientTypingRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.typing !== undefined && object.typing !== null) {
      message.typing = Boolean(object.typing);
    } else {
      message.typing = false;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = String(object.post);
    } else {
      message.post = "";
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = Number(object.replyTo);
    } else {
      message.replyTo = 0;
    }
    return message;
  },

  toJSON(message: ClientTypingRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.typing !== undefined && (obj.typing = message.typing);
    message.post !== undefined && (obj.post = message.post);
    message.replyTo !== undefined && (obj.replyTo = message.replyTo);
    return obj;
  },

  fromPartial(object: DeepPartial<ClientTypingRequest>): ClientTypingRequest {
    const message = { ...baseClientTypingRequest } as ClientTypingRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.typing !== undefined && object.typing !== null) {
      message.typing = object.typing;
    } else {
      message.typing = false;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = object.post;
    } else {
      message.post = "";
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = object.replyTo;
    } else {
      message.replyTo = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ClientTypingRequest.$type, ClientTypingRequest);

const baseClientUploadRequest: object = {
  $type: "ngmsg.ClientUploadRequest",
  userId: 0,
  channelId: 0,
  filename: "",
  contentType: "",
  size: 0,
};

export const ClientUploadRequest = {
  $type: "ngmsg.ClientUploadRequest" as const,

  fromJSON(object: any): ClientUploadRequest {
    const message = { ...baseClientUploadRequest } as ClientUploadRequest;
    message.data = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.filename !== undefined && object.filename !== null) {
      message.filename = String(object.filename);
    } else {
      message.filename = "";
    }
    if (object.contentType !== undefined && object.contentType !== null) {
      message.contentType = String(object.contentType);
    } else {
      message.contentType = "";
    }
    if (object.size !== undefined && object.size !== null) {
      message.size = Number(object.size);
    } else {
      message.size = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: ClientUploadRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.filename !== undefined && (obj.filename = message.filename);
    message.contentType !== undefined &&
      (obj.contentType = message.contentType);
    message.size !== undefined && (obj.size = message.size);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ClientUploadRequest>): ClientUploadRequest {
    const message = { ...baseClientUploadRequest } as ClientUploadRequest;
    message.data = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.filename !== undefined && object.filename !== null) {
      message.filename = object.filename;
    } else {
      message.filename = "";
    }
    if (object.contentType !== undefined && object.contentType !== null) {
      message.contentType = object.contentType;
    } else {
      message.contentType = "";
    }
    if (object.size !== undefined && object.size !== null) {
      message.size = object.size;
    } else {
      message.size = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(ClientUploadRequest.$type, ClientUploadRequest);

const baseClientUploadRequest_DataEntry: object = {
  $type: "ngmsg.ClientUploadRequest.DataEntry",
  key: "",
  value: "",
};

export const ClientUploadRequest_DataEntry = {
  $type: "ngmsg.ClientUploadRequest.DataEntry" as const,

  fromJSON(object: any): ClientUploadRequest_DataEntry {
    const message = {
      ...baseClientUploadRequest_DataEntry,
    } as ClientUploadRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: ClientUploadRequest_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ClientUploadRequest_DataEntry>
  ): ClientUploadRequest_DataEntry {
    const message = {
      ...baseClientUploadRequest_DataEntry,
    } as ClientUploadRequest_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(
  ClientUploadRequest_DataEntry.$type,
  ClientUploadRequest_DataEntry
);

const baseClientUploadResponse: object = {
  $type: "ngmsg.ClientUploadResponse",
  ok: false,
  errorCode: 0,
  errorMessage: "",
  storageKey: "",
  storageId: "",
  url: "",
};

export const ClientUploadResponse = {
  $type: "ngmsg.ClientUploadResponse" as const,

  fromJSON(object: any): ClientUploadResponse {
    const message = { ...baseClientUploadResponse } as ClientUploadResponse;
    message.data = {};
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = Boolean(object.ok);
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = errFromJSON(object.errorCode);
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = String(object.errorMessage);
    } else {
      message.errorMessage = "";
    }
    if (object.storageKey !== undefined && object.storageKey !== null) {
      message.storageKey = String(object.storageKey);
    } else {
      message.storageKey = "";
    }
    if (object.storageId !== undefined && object.storageId !== null) {
      message.storageId = String(object.storageId);
    } else {
      message.storageId = "";
    }
    if (object.url !== undefined && object.url !== null) {
      message.url = String(object.url);
    } else {
      message.url = "";
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    return message;
  },

  toJSON(message: ClientUploadResponse): unknown {
    const obj: any = {};
    message.ok !== undefined && (obj.ok = message.ok);
    message.errorCode !== undefined &&
      (obj.errorCode = errToJSON(message.errorCode));
    message.errorMessage !== undefined &&
      (obj.errorMessage = message.errorMessage);
    message.storageKey !== undefined && (obj.storageKey = message.storageKey);
    message.storageId !== undefined && (obj.storageId = message.storageId);
    message.url !== undefined && (obj.url = message.url);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<ClientUploadResponse>): ClientUploadResponse {
    const message = { ...baseClientUploadResponse } as ClientUploadResponse;
    message.data = {};
    if (object.ok !== undefined && object.ok !== null) {
      message.ok = object.ok;
    } else {
      message.ok = false;
    }
    if (object.errorCode !== undefined && object.errorCode !== null) {
      message.errorCode = object.errorCode;
    } else {
      message.errorCode = 0;
    }
    if (object.errorMessage !== undefined && object.errorMessage !== null) {
      message.errorMessage = object.errorMessage;
    } else {
      message.errorMessage = "";
    }
    if (object.storageKey !== undefined && object.storageKey !== null) {
      message.storageKey = object.storageKey;
    } else {
      message.storageKey = "";
    }
    if (object.storageId !== undefined && object.storageId !== null) {
      message.storageId = object.storageId;
    } else {
      message.storageId = "";
    }
    if (object.url !== undefined && object.url !== null) {
      message.url = object.url;
    } else {
      message.url = "";
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(ClientUploadResponse.$type, ClientUploadResponse);

const baseClientUploadResponse_DataEntry: object = {
  $type: "ngmsg.ClientUploadResponse.DataEntry",
  key: "",
  value: "",
};

export const ClientUploadResponse_DataEntry = {
  $type: "ngmsg.ClientUploadResponse.DataEntry" as const,

  fromJSON(object: any): ClientUploadResponse_DataEntry {
    const message = {
      ...baseClientUploadResponse_DataEntry,
    } as ClientUploadResponse_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: ClientUploadResponse_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<ClientUploadResponse_DataEntry>
  ): ClientUploadResponse_DataEntry {
    const message = {
      ...baseClientUploadResponse_DataEntry,
    } as ClientUploadResponse_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(
  ClientUploadResponse_DataEntry.$type,
  ClientUploadResponse_DataEntry
);

const baseUserEvent: object = { $type: "ngmsg.UserEvent", action: 0 };

export const UserEvent = {
  $type: "ngmsg.UserEvent" as const,

  fromJSON(object: any): UserEvent {
    const message = { ...baseUserEvent } as UserEvent;
    if (object.action !== undefined && object.action !== null) {
      message.action = enumUserEventFromJSON(object.action);
    } else {
      message.action = 0;
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromJSON(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },

  toJSON(message: UserEvent): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = enumUserEventToJSON(message.action));
    message.user !== undefined &&
      (obj.user = message.user
        ? ClientUserData.toJSON(message.user)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<UserEvent>): UserEvent {
    const message = { ...baseUserEvent } as UserEvent;
    if (object.action !== undefined && object.action !== null) {
      message.action = object.action;
    } else {
      message.action = 0;
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromPartial(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },
};

messageTypeRegistry.set(UserEvent.$type, UserEvent);

const baseUserMutedEvent: object = {
  $type: "ngmsg.UserMutedEvent",
  userId: 0,
  channelId: 0,
  muted: false,
};

export const UserMutedEvent = {
  $type: "ngmsg.UserMutedEvent" as const,

  fromJSON(object: any): UserMutedEvent {
    const message = { ...baseUserMutedEvent } as UserMutedEvent;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.muted !== undefined && object.muted !== null) {
      message.muted = Boolean(object.muted);
    } else {
      message.muted = false;
    }
    return message;
  },

  toJSON(message: UserMutedEvent): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.muted !== undefined && (obj.muted = message.muted);
    return obj;
  },

  fromPartial(object: DeepPartial<UserMutedEvent>): UserMutedEvent {
    const message = { ...baseUserMutedEvent } as UserMutedEvent;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.muted !== undefined && object.muted !== null) {
      message.muted = object.muted;
    } else {
      message.muted = false;
    }
    return message;
  },
};

messageTypeRegistry.set(UserMutedEvent.$type, UserMutedEvent);

const baseChannelEvent: object = { $type: "ngmsg.ChannelEvent", action: 0 };

export const ChannelEvent = {
  $type: "ngmsg.ChannelEvent" as const,

  fromJSON(object: any): ChannelEvent {
    const message = { ...baseChannelEvent } as ChannelEvent;
    if (object.action !== undefined && object.action !== null) {
      message.action = enumChannelEventFromJSON(object.action);
    } else {
      message.action = 0;
    }
    if (object.channel !== undefined && object.channel !== null) {
      message.channel = ClientChannelData.fromJSON(object.channel);
    } else {
      message.channel = undefined;
    }
    return message;
  },

  toJSON(message: ChannelEvent): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = enumChannelEventToJSON(message.action));
    message.channel !== undefined &&
      (obj.channel = message.channel
        ? ClientChannelData.toJSON(message.channel)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelEvent>): ChannelEvent {
    const message = { ...baseChannelEvent } as ChannelEvent;
    if (object.action !== undefined && object.action !== null) {
      message.action = object.action;
    } else {
      message.action = 0;
    }
    if (object.channel !== undefined && object.channel !== null) {
      message.channel = ClientChannelData.fromPartial(object.channel);
    } else {
      message.channel = undefined;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelEvent.$type, ChannelEvent);

const baseChannelMemberEvent: object = {
  $type: "ngmsg.ChannelMemberEvent",
  channelId: 0,
  users: 0,
  role: 0,
};

export const ChannelMemberEvent = {
  $type: "ngmsg.ChannelMemberEvent" as const,

  fromJSON(object: any): ChannelMemberEvent {
    const message = { ...baseChannelMemberEvent } as ChannelMemberEvent;
    message.users = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.users !== undefined && object.users !== null) {
      for (const e of object.users) {
        message.users.push(Number(e));
      }
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = enumChannelRoleFromJSON(object.role);
    } else {
      message.role = 0;
    }
    return message;
  },

  toJSON(message: ChannelMemberEvent): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    if (message.users) {
      obj.users = message.users.map((e) => e);
    } else {
      obj.users = [];
    }
    message.role !== undefined &&
      (obj.role = enumChannelRoleToJSON(message.role));
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelMemberEvent>): ChannelMemberEvent {
    const message = { ...baseChannelMemberEvent } as ChannelMemberEvent;
    message.users = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.users !== undefined && object.users !== null) {
      for (const e of object.users) {
        message.users.push(e);
      }
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = object.role;
    } else {
      message.role = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelMemberEvent.$type, ChannelMemberEvent);

const basePostEvent: object = { $type: "ngmsg.PostEvent", action: 0 };

export const PostEvent = {
  $type: "ngmsg.PostEvent" as const,

  fromJSON(object: any): PostEvent {
    const message = { ...basePostEvent } as PostEvent;
    if (object.action !== undefined && object.action !== null) {
      message.action = enumPostEventFromJSON(object.action);
    } else {
      message.action = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = ClientPostData.fromJSON(object.post);
    } else {
      message.post = undefined;
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromJSON(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },

  toJSON(message: PostEvent): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = enumPostEventToJSON(message.action));
    message.post !== undefined &&
      (obj.post = message.post
        ? ClientPostData.toJSON(message.post)
        : undefined);
    message.user !== undefined &&
      (obj.user = message.user
        ? ClientUserData.toJSON(message.user)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<PostEvent>): PostEvent {
    const message = { ...basePostEvent } as PostEvent;
    if (object.action !== undefined && object.action !== null) {
      message.action = object.action;
    } else {
      message.action = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = ClientPostData.fromPartial(object.post);
    } else {
      message.post = undefined;
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromPartial(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },
};

messageTypeRegistry.set(PostEvent.$type, PostEvent);

const baseReactionEvent: object = {
  $type: "ngmsg.ReactionEvent",
  action: 0,
  postId: 0,
  userId: 0,
  reactionType: 0,
  reaction: "",
};

export const ReactionEvent = {
  $type: "ngmsg.ReactionEvent" as const,

  fromJSON(object: any): ReactionEvent {
    const message = { ...baseReactionEvent } as ReactionEvent;
    if (object.action !== undefined && object.action !== null) {
      message.action = enumReactionEventFromJSON(object.action);
    } else {
      message.action = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.reactionType !== undefined && object.reactionType !== null) {
      message.reactionType = Number(object.reactionType);
    } else {
      message.reactionType = 0;
    }
    if (object.reaction !== undefined && object.reaction !== null) {
      message.reaction = String(object.reaction);
    } else {
      message.reaction = "";
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromJSON(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },

  toJSON(message: ReactionEvent): unknown {
    const obj: any = {};
    message.action !== undefined &&
      (obj.action = enumReactionEventToJSON(message.action));
    message.postId !== undefined && (obj.postId = message.postId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.reactionType !== undefined &&
      (obj.reactionType = message.reactionType);
    message.reaction !== undefined && (obj.reaction = message.reaction);
    message.user !== undefined &&
      (obj.user = message.user
        ? ClientUserData.toJSON(message.user)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<ReactionEvent>): ReactionEvent {
    const message = { ...baseReactionEvent } as ReactionEvent;
    if (object.action !== undefined && object.action !== null) {
      message.action = object.action;
    } else {
      message.action = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.reactionType !== undefined && object.reactionType !== null) {
      message.reactionType = object.reactionType;
    } else {
      message.reactionType = 0;
    }
    if (object.reaction !== undefined && object.reaction !== null) {
      message.reaction = object.reaction;
    } else {
      message.reaction = "";
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromPartial(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },
};

messageTypeRegistry.set(ReactionEvent.$type, ReactionEvent);

const baseTypingEvent: object = {
  $type: "ngmsg.TypingEvent",
  userId: 0,
  channelId: 0,
  typing: false,
  post: "",
  replyTo: 0,
};

export const TypingEvent = {
  $type: "ngmsg.TypingEvent" as const,

  fromJSON(object: any): TypingEvent {
    const message = { ...baseTypingEvent } as TypingEvent;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.typing !== undefined && object.typing !== null) {
      message.typing = Boolean(object.typing);
    } else {
      message.typing = false;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = String(object.post);
    } else {
      message.post = "";
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = Number(object.replyTo);
    } else {
      message.replyTo = 0;
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromJSON(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },

  toJSON(message: TypingEvent): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.typing !== undefined && (obj.typing = message.typing);
    message.post !== undefined && (obj.post = message.post);
    message.replyTo !== undefined && (obj.replyTo = message.replyTo);
    message.user !== undefined &&
      (obj.user = message.user
        ? ClientUserData.toJSON(message.user)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<TypingEvent>): TypingEvent {
    const message = { ...baseTypingEvent } as TypingEvent;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.typing !== undefined && object.typing !== null) {
      message.typing = object.typing;
    } else {
      message.typing = false;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = object.post;
    } else {
      message.post = "";
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = object.replyTo;
    } else {
      message.replyTo = 0;
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromPartial(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },
};

messageTypeRegistry.set(TypingEvent.$type, TypingEvent);

const baseUserStatusEvent: object = {
  $type: "ngmsg.UserStatusEvent",
  userId: 0,
  status: 0,
  clientLastOnline: 0,
};

export const UserStatusEvent = {
  $type: "ngmsg.UserStatusEvent" as const,

  fromJSON(object: any): UserStatusEvent {
    const message = { ...baseUserStatusEvent } as UserStatusEvent;
    message.data = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = enumClientStatusFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (
      object.clientLastOnline !== undefined &&
      object.clientLastOnline !== null
    ) {
      message.clientLastOnline = Number(object.clientLastOnline);
    } else {
      message.clientLastOnline = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromJSON(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },

  toJSON(message: UserStatusEvent): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.status !== undefined &&
      (obj.status = enumClientStatusToJSON(message.status));
    message.clientLastOnline !== undefined &&
      (obj.clientLastOnline = message.clientLastOnline);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    message.user !== undefined &&
      (obj.user = message.user
        ? ClientUserData.toJSON(message.user)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<UserStatusEvent>): UserStatusEvent {
    const message = { ...baseUserStatusEvent } as UserStatusEvent;
    message.data = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (
      object.clientLastOnline !== undefined &&
      object.clientLastOnline !== null
    ) {
      message.clientLastOnline = object.clientLastOnline;
    } else {
      message.clientLastOnline = 0;
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = ClientUserData.fromPartial(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },
};

messageTypeRegistry.set(UserStatusEvent.$type, UserStatusEvent);

const baseUserStatusEvent_DataEntry: object = {
  $type: "ngmsg.UserStatusEvent.DataEntry",
  key: "",
  value: "",
};

export const UserStatusEvent_DataEntry = {
  $type: "ngmsg.UserStatusEvent.DataEntry" as const,

  fromJSON(object: any): UserStatusEvent_DataEntry {
    const message = {
      ...baseUserStatusEvent_DataEntry,
    } as UserStatusEvent_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: UserStatusEvent_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UserStatusEvent_DataEntry>
  ): UserStatusEvent_DataEntry {
    const message = {
      ...baseUserStatusEvent_DataEntry,
    } as UserStatusEvent_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(
  UserStatusEvent_DataEntry.$type,
  UserStatusEvent_DataEntry
);

const baseCustomRequestEvent: object = {
  $type: "ngmsg.CustomRequestEvent",
  userId: 0,
  channelId: 0,
  postId: 0,
  customRequestType: "",
  request: "",
  responseData: "",
};

export const CustomRequestEvent = {
  $type: "ngmsg.CustomRequestEvent" as const,

  fromJSON(object: any): CustomRequestEvent {
    const message = { ...baseCustomRequestEvent } as CustomRequestEvent;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (
      object.customRequestType !== undefined &&
      object.customRequestType !== null
    ) {
      message.customRequestType = String(object.customRequestType);
    } else {
      message.customRequestType = "";
    }
    if (object.request !== undefined && object.request !== null) {
      message.request = String(object.request);
    } else {
      message.request = "";
    }
    if (object.responseData !== undefined && object.responseData !== null) {
      message.responseData = String(object.responseData);
    } else {
      message.responseData = "";
    }
    return message;
  },

  toJSON(message: CustomRequestEvent): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.postId !== undefined && (obj.postId = message.postId);
    message.customRequestType !== undefined &&
      (obj.customRequestType = message.customRequestType);
    message.request !== undefined && (obj.request = message.request);
    message.responseData !== undefined &&
      (obj.responseData = message.responseData);
    return obj;
  },

  fromPartial(object: DeepPartial<CustomRequestEvent>): CustomRequestEvent {
    const message = { ...baseCustomRequestEvent } as CustomRequestEvent;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (
      object.customRequestType !== undefined &&
      object.customRequestType !== null
    ) {
      message.customRequestType = object.customRequestType;
    } else {
      message.customRequestType = "";
    }
    if (object.request !== undefined && object.request !== null) {
      message.request = object.request;
    } else {
      message.request = "";
    }
    if (object.responseData !== undefined && object.responseData !== null) {
      message.responseData = object.responseData;
    } else {
      message.responseData = "";
    }
    return message;
  },
};

messageTypeRegistry.set(CustomRequestEvent.$type, CustomRequestEvent);

const baseEventInternal: object = {
  $type: "ngmsg.EventInternal",
  eventId: 0,
  time: 0,
  ip: "",
  source: 0,
  actor: 0,
  userId: 0,
  channelId: 0,
  clientMessageId: 0,
  processed: false,
  typeId: 0,
};

export const EventInternal = {
  $type: "ngmsg.EventInternal" as const,

  fromJSON(object: any): EventInternal {
    const message = { ...baseEventInternal } as EventInternal;
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = Number(object.eventId);
    } else {
      message.eventId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = Number(object.time);
    } else {
      message.time = 0;
    }
    if (object.ip !== undefined && object.ip !== null) {
      message.ip = String(object.ip);
    } else {
      message.ip = "";
    }
    if (object.source !== undefined && object.source !== null) {
      message.source = enumEventSourceFromJSON(object.source);
    } else {
      message.source = 0;
    }
    if (object.actor !== undefined && object.actor !== null) {
      message.actor = Number(object.actor);
    } else {
      message.actor = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (
      object.clientMessageId !== undefined &&
      object.clientMessageId !== null
    ) {
      message.clientMessageId = Number(object.clientMessageId);
    } else {
      message.clientMessageId = 0;
    }
    if (object.processed !== undefined && object.processed !== null) {
      message.processed = Boolean(object.processed);
    } else {
      message.processed = false;
    }
    if (object.typeId !== undefined && object.typeId !== null) {
      message.typeId = typeIdFromJSON(object.typeId);
    } else {
      message.typeId = 0;
    }
    if (object.request !== undefined && object.request !== null) {
      message.request = Any.fromJSON(object.request);
    } else {
      message.request = undefined;
    }
    if (object.response !== undefined && object.response !== null) {
      message.response = Any.fromJSON(object.response);
    } else {
      message.response = undefined;
    }
    return message;
  },

  toJSON(message: EventInternal): unknown {
    const obj: any = {};
    message.eventId !== undefined && (obj.eventId = message.eventId);
    message.time !== undefined && (obj.time = message.time);
    message.ip !== undefined && (obj.ip = message.ip);
    message.source !== undefined &&
      (obj.source = enumEventSourceToJSON(message.source));
    message.actor !== undefined && (obj.actor = message.actor);
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.clientMessageId !== undefined &&
      (obj.clientMessageId = message.clientMessageId);
    message.processed !== undefined && (obj.processed = message.processed);
    message.typeId !== undefined && (obj.typeId = typeIdToJSON(message.typeId));
    message.request !== undefined &&
      (obj.request = message.request ? Any.toJSON(message.request) : undefined);
    message.response !== undefined &&
      (obj.response = message.response
        ? Any.toJSON(message.response)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<EventInternal>): EventInternal {
    const message = { ...baseEventInternal } as EventInternal;
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = object.time;
    } else {
      message.time = 0;
    }
    if (object.ip !== undefined && object.ip !== null) {
      message.ip = object.ip;
    } else {
      message.ip = "";
    }
    if (object.source !== undefined && object.source !== null) {
      message.source = object.source;
    } else {
      message.source = 0;
    }
    if (object.actor !== undefined && object.actor !== null) {
      message.actor = object.actor;
    } else {
      message.actor = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (
      object.clientMessageId !== undefined &&
      object.clientMessageId !== null
    ) {
      message.clientMessageId = object.clientMessageId;
    } else {
      message.clientMessageId = 0;
    }
    if (object.processed !== undefined && object.processed !== null) {
      message.processed = object.processed;
    } else {
      message.processed = false;
    }
    if (object.typeId !== undefined && object.typeId !== null) {
      message.typeId = object.typeId;
    } else {
      message.typeId = 0;
    }
    if (object.request !== undefined && object.request !== null) {
      message.request = Any.fromPartial(object.request);
    } else {
      message.request = undefined;
    }
    if (object.response !== undefined && object.response !== null) {
      message.response = Any.fromPartial(object.response);
    } else {
      message.response = undefined;
    }
    return message;
  },
};

messageTypeRegistry.set(EventInternal.$type, EventInternal);

const baseUserInternal: object = {
  $type: "ngmsg.UserInternal",
  userId: 0,
  apiId: "",
  username: "",
  name: "",
  avatar: "",
  images: "",
  email: "",
  phone: "",
  tags: "",
  deleted: false,
  banned: false,
  role: 0,
};

export const UserInternal = {
  $type: "ngmsg.UserInternal" as const,

  fromJSON(object: any): UserInternal {
    const message = { ...baseUserInternal } as UserInternal;
    message.images = [];
    message.data = {};
    message.tags = [];
    message.channelRoles = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = String(object.username);
    } else {
      message.username = "";
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = "";
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = String(object.avatar);
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(String(e));
      }
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = String(object.email);
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = String(object.phone);
    } else {
      message.phone = "";
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        message.data[key] = String(value);
      });
    }
    if (object.tags !== undefined && object.tags !== null) {
      for (const e of object.tags) {
        message.tags.push(String(e));
      }
    }
    if (object.deleted !== undefined && object.deleted !== null) {
      message.deleted = Boolean(object.deleted);
    } else {
      message.deleted = false;
    }
    if (object.banned !== undefined && object.banned !== null) {
      message.banned = Boolean(object.banned);
    } else {
      message.banned = false;
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = appUserRoleFromJSON(object.role);
    } else {
      message.role = 0;
    }
    if (object.channelRoles !== undefined && object.channelRoles !== null) {
      Object.entries(object.channelRoles).forEach(([key, value]) => {
        message.channelRoles[Number(key)] = value as number;
      });
    }
    return message;
  },

  toJSON(message: UserInternal): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    message.username !== undefined && (obj.username = message.username);
    message.name !== undefined && (obj.name = message.name);
    message.avatar !== undefined && (obj.avatar = message.avatar);
    if (message.images) {
      obj.images = message.images.map((e) => e);
    } else {
      obj.images = [];
    }
    message.email !== undefined && (obj.email = message.email);
    message.phone !== undefined && (obj.phone = message.phone);
    obj.data = {};
    if (message.data) {
      Object.entries(message.data).forEach(([k, v]) => {
        obj.data[k] = v;
      });
    }
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.deleted !== undefined && (obj.deleted = message.deleted);
    message.banned !== undefined && (obj.banned = message.banned);
    message.role !== undefined && (obj.role = appUserRoleToJSON(message.role));
    obj.channelRoles = {};
    if (message.channelRoles) {
      Object.entries(message.channelRoles).forEach(([k, v]) => {
        obj.channelRoles[k] = enumChannelRoleToJSON(v);
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<UserInternal>): UserInternal {
    const message = { ...baseUserInternal } as UserInternal;
    message.images = [];
    message.data = {};
    message.tags = [];
    message.channelRoles = {};
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    if (object.username !== undefined && object.username !== null) {
      message.username = object.username;
    } else {
      message.username = "";
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = "";
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = object.avatar;
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(e);
      }
    }
    if (object.email !== undefined && object.email !== null) {
      message.email = object.email;
    } else {
      message.email = "";
    }
    if (object.phone !== undefined && object.phone !== null) {
      message.phone = object.phone;
    } else {
      message.phone = "";
    }
    if (object.data !== undefined && object.data !== null) {
      Object.entries(object.data).forEach(([key, value]) => {
        if (value !== undefined) {
          message.data[key] = String(value);
        }
      });
    }
    if (object.tags !== undefined && object.tags !== null) {
      for (const e of object.tags) {
        message.tags.push(e);
      }
    }
    if (object.deleted !== undefined && object.deleted !== null) {
      message.deleted = object.deleted;
    } else {
      message.deleted = false;
    }
    if (object.banned !== undefined && object.banned !== null) {
      message.banned = object.banned;
    } else {
      message.banned = false;
    }
    if (object.role !== undefined && object.role !== null) {
      message.role = object.role;
    } else {
      message.role = 0;
    }
    if (object.channelRoles !== undefined && object.channelRoles !== null) {
      Object.entries(object.channelRoles).forEach(([key, value]) => {
        if (value !== undefined) {
          message.channelRoles[Number(key)] = value as number;
        }
      });
    }
    return message;
  },
};

messageTypeRegistry.set(UserInternal.$type, UserInternal);

const baseUserInternal_DataEntry: object = {
  $type: "ngmsg.UserInternal.DataEntry",
  key: "",
  value: "",
};

export const UserInternal_DataEntry = {
  $type: "ngmsg.UserInternal.DataEntry" as const,

  fromJSON(object: any): UserInternal_DataEntry {
    const message = { ...baseUserInternal_DataEntry } as UserInternal_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = String(object.value);
    } else {
      message.value = "";
    }
    return message;
  },

  toJSON(message: UserInternal_DataEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  fromPartial(
    object: DeepPartial<UserInternal_DataEntry>
  ): UserInternal_DataEntry {
    const message = { ...baseUserInternal_DataEntry } as UserInternal_DataEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = "";
    }
    return message;
  },
};

messageTypeRegistry.set(UserInternal_DataEntry.$type, UserInternal_DataEntry);

const baseUserInternal_ChannelRolesEntry: object = {
  $type: "ngmsg.UserInternal.ChannelRolesEntry",
  key: 0,
  value: 0,
};

export const UserInternal_ChannelRolesEntry = {
  $type: "ngmsg.UserInternal.ChannelRolesEntry" as const,

  fromJSON(object: any): UserInternal_ChannelRolesEntry {
    const message = {
      ...baseUserInternal_ChannelRolesEntry,
    } as UserInternal_ChannelRolesEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = Number(object.key);
    } else {
      message.key = 0;
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = enumChannelRoleFromJSON(object.value);
    } else {
      message.value = 0;
    }
    return message;
  },

  toJSON(message: UserInternal_ChannelRolesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = enumChannelRoleToJSON(message.value));
    return obj;
  },

  fromPartial(
    object: DeepPartial<UserInternal_ChannelRolesEntry>
  ): UserInternal_ChannelRolesEntry {
    const message = {
      ...baseUserInternal_ChannelRolesEntry,
    } as UserInternal_ChannelRolesEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = 0;
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = object.value;
    } else {
      message.value = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(
  UserInternal_ChannelRolesEntry.$type,
  UserInternal_ChannelRolesEntry
);

const baseChannelInternal: object = {
  $type: "ngmsg.ChannelInternal",
  channelId: 0,
  apiId: "",
  title: "",
  description: "",
  owner: 0,
  avatar: "",
  images: "",
  direct: false,
  public: 0,
};

export const ChannelInternal = {
  $type: "ngmsg.ChannelInternal" as const,

  fromJSON(object: any): ChannelInternal {
    const message = { ...baseChannelInternal } as ChannelInternal;
    message.images = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = String(object.apiId);
    } else {
      message.apiId = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = String(object.title);
    } else {
      message.title = "";
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = String(object.description);
    } else {
      message.description = "";
    }
    if (object.owner !== undefined && object.owner !== null) {
      message.owner = Number(object.owner);
    } else {
      message.owner = 0;
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = String(object.avatar);
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(String(e));
      }
    }
    if (object.direct !== undefined && object.direct !== null) {
      message.direct = Boolean(object.direct);
    } else {
      message.direct = false;
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = enumChannelPublicFromJSON(object.public);
    } else {
      message.public = 0;
    }
    return message;
  },

  toJSON(message: ChannelInternal): unknown {
    const obj: any = {};
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.apiId !== undefined && (obj.apiId = message.apiId);
    message.title !== undefined && (obj.title = message.title);
    message.description !== undefined &&
      (obj.description = message.description);
    message.owner !== undefined && (obj.owner = message.owner);
    message.avatar !== undefined && (obj.avatar = message.avatar);
    if (message.images) {
      obj.images = message.images.map((e) => e);
    } else {
      obj.images = [];
    }
    message.direct !== undefined && (obj.direct = message.direct);
    message.public !== undefined &&
      (obj.public = enumChannelPublicToJSON(message.public));
    return obj;
  },

  fromPartial(object: DeepPartial<ChannelInternal>): ChannelInternal {
    const message = { ...baseChannelInternal } as ChannelInternal;
    message.images = [];
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.apiId !== undefined && object.apiId !== null) {
      message.apiId = object.apiId;
    } else {
      message.apiId = "";
    }
    if (object.title !== undefined && object.title !== null) {
      message.title = object.title;
    } else {
      message.title = "";
    }
    if (object.description !== undefined && object.description !== null) {
      message.description = object.description;
    } else {
      message.description = "";
    }
    if (object.owner !== undefined && object.owner !== null) {
      message.owner = object.owner;
    } else {
      message.owner = 0;
    }
    if (object.avatar !== undefined && object.avatar !== null) {
      message.avatar = object.avatar;
    } else {
      message.avatar = "";
    }
    if (object.images !== undefined && object.images !== null) {
      for (const e of object.images) {
        message.images.push(e);
      }
    }
    if (object.direct !== undefined && object.direct !== null) {
      message.direct = object.direct;
    } else {
      message.direct = false;
    }
    if (object.public !== undefined && object.public !== null) {
      message.public = object.public;
    } else {
      message.public = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(ChannelInternal.$type, ChannelInternal);

const basePostInternal: object = {
  $type: "ngmsg.PostInternal",
  postId: 0,
  channelId: 0,
  userId: 0,
  time: 0,
  post: "",
  isJson: false,
  replyTo: 0,
  ip: "",
  source: 0,
  actor: 0,
};

export const PostInternal = {
  $type: "ngmsg.PostInternal" as const,

  fromJSON(object: any): PostInternal {
    const message = { ...basePostInternal } as PostInternal;
    message.history = [];
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = Number(object.time);
    } else {
      message.time = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = String(object.post);
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = Boolean(object.isJson);
    } else {
      message.isJson = false;
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = Number(object.replyTo);
    } else {
      message.replyTo = 0;
    }
    if (object.ip !== undefined && object.ip !== null) {
      message.ip = String(object.ip);
    } else {
      message.ip = "";
    }
    if (object.source !== undefined && object.source !== null) {
      message.source = enumEventSourceFromJSON(object.source);
    } else {
      message.source = 0;
    }
    if (object.actor !== undefined && object.actor !== null) {
      message.actor = Number(object.actor);
    } else {
      message.actor = 0;
    }
    if (object.history !== undefined && object.history !== null) {
      for (const e of object.history) {
        message.history.push(PostInternal.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: PostInternal): unknown {
    const obj: any = {};
    message.postId !== undefined && (obj.postId = message.postId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.time !== undefined && (obj.time = message.time);
    message.post !== undefined && (obj.post = message.post);
    message.isJson !== undefined && (obj.isJson = message.isJson);
    message.replyTo !== undefined && (obj.replyTo = message.replyTo);
    message.ip !== undefined && (obj.ip = message.ip);
    message.source !== undefined &&
      (obj.source = enumEventSourceToJSON(message.source));
    message.actor !== undefined && (obj.actor = message.actor);
    if (message.history) {
      obj.history = message.history.map((e) =>
        e ? PostInternal.toJSON(e) : undefined
      );
    } else {
      obj.history = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<PostInternal>): PostInternal {
    const message = { ...basePostInternal } as PostInternal;
    message.history = [];
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = object.time;
    } else {
      message.time = 0;
    }
    if (object.post !== undefined && object.post !== null) {
      message.post = object.post;
    } else {
      message.post = "";
    }
    if (object.isJson !== undefined && object.isJson !== null) {
      message.isJson = object.isJson;
    } else {
      message.isJson = false;
    }
    if (object.replyTo !== undefined && object.replyTo !== null) {
      message.replyTo = object.replyTo;
    } else {
      message.replyTo = 0;
    }
    if (object.ip !== undefined && object.ip !== null) {
      message.ip = object.ip;
    } else {
      message.ip = "";
    }
    if (object.source !== undefined && object.source !== null) {
      message.source = object.source;
    } else {
      message.source = 0;
    }
    if (object.actor !== undefined && object.actor !== null) {
      message.actor = object.actor;
    } else {
      message.actor = 0;
    }
    if (object.history !== undefined && object.history !== null) {
      for (const e of object.history) {
        message.history.push(PostInternal.fromPartial(e));
      }
    }
    return message;
  },
};

messageTypeRegistry.set(PostInternal.$type, PostInternal);

const baseUploadInternal: object = {
  $type: "ngmsg.UploadInternal",
  uploadId: 0,
  userId: 0,
  channelId: 0,
  time: 0,
  storageId: "",
  storageKey: "",
  ip: "",
  filename: "",
  mediaType: "",
  size: 0,
};

export const UploadInternal = {
  $type: "ngmsg.UploadInternal" as const,

  fromJSON(object: any): UploadInternal {
    const message = { ...baseUploadInternal } as UploadInternal;
    if (object.uploadId !== undefined && object.uploadId !== null) {
      message.uploadId = Number(object.uploadId);
    } else {
      message.uploadId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = Number(object.time);
    } else {
      message.time = 0;
    }
    if (object.storageId !== undefined && object.storageId !== null) {
      message.storageId = String(object.storageId);
    } else {
      message.storageId = "";
    }
    if (object.storageKey !== undefined && object.storageKey !== null) {
      message.storageKey = String(object.storageKey);
    } else {
      message.storageKey = "";
    }
    if (object.ip !== undefined && object.ip !== null) {
      message.ip = String(object.ip);
    } else {
      message.ip = "";
    }
    if (object.filename !== undefined && object.filename !== null) {
      message.filename = String(object.filename);
    } else {
      message.filename = "";
    }
    if (object.mediaType !== undefined && object.mediaType !== null) {
      message.mediaType = String(object.mediaType);
    } else {
      message.mediaType = "";
    }
    if (object.size !== undefined && object.size !== null) {
      message.size = Number(object.size);
    } else {
      message.size = 0;
    }
    return message;
  },

  toJSON(message: UploadInternal): unknown {
    const obj: any = {};
    message.uploadId !== undefined && (obj.uploadId = message.uploadId);
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.time !== undefined && (obj.time = message.time);
    message.storageId !== undefined && (obj.storageId = message.storageId);
    message.storageKey !== undefined && (obj.storageKey = message.storageKey);
    message.ip !== undefined && (obj.ip = message.ip);
    message.filename !== undefined && (obj.filename = message.filename);
    message.mediaType !== undefined && (obj.mediaType = message.mediaType);
    message.size !== undefined && (obj.size = message.size);
    return obj;
  },

  fromPartial(object: DeepPartial<UploadInternal>): UploadInternal {
    const message = { ...baseUploadInternal } as UploadInternal;
    if (object.uploadId !== undefined && object.uploadId !== null) {
      message.uploadId = object.uploadId;
    } else {
      message.uploadId = 0;
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.time !== undefined && object.time !== null) {
      message.time = object.time;
    } else {
      message.time = 0;
    }
    if (object.storageId !== undefined && object.storageId !== null) {
      message.storageId = object.storageId;
    } else {
      message.storageId = "";
    }
    if (object.storageKey !== undefined && object.storageKey !== null) {
      message.storageKey = object.storageKey;
    } else {
      message.storageKey = "";
    }
    if (object.ip !== undefined && object.ip !== null) {
      message.ip = object.ip;
    } else {
      message.ip = "";
    }
    if (object.filename !== undefined && object.filename !== null) {
      message.filename = object.filename;
    } else {
      message.filename = "";
    }
    if (object.mediaType !== undefined && object.mediaType !== null) {
      message.mediaType = object.mediaType;
    } else {
      message.mediaType = "";
    }
    if (object.size !== undefined && object.size !== null) {
      message.size = object.size;
    } else {
      message.size = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(UploadInternal.$type, UploadInternal);

const baseDataInternal: object = {
  $type: "ngmsg.DataInternal",
  dataId: 0,
  type: "",
  key: "",
  data: "",
  userId: 0,
  channelId: 0,
  postId: 0,
};

export const DataInternal = {
  $type: "ngmsg.DataInternal" as const,

  fromJSON(object: any): DataInternal {
    const message = { ...baseDataInternal } as DataInternal;
    if (object.dataId !== undefined && object.dataId !== null) {
      message.dataId = Number(object.dataId);
    } else {
      message.dataId = 0;
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = String(object.type);
    } else {
      message.type = "";
    }
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = "";
    }
    if (object.data !== undefined && object.data !== null) {
      message.data = String(object.data);
    } else {
      message.data = "";
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = Number(object.userId);
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = Number(object.channelId);
    } else {
      message.channelId = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = Number(object.postId);
    } else {
      message.postId = 0;
    }
    return message;
  },

  toJSON(message: DataInternal): unknown {
    const obj: any = {};
    message.dataId !== undefined && (obj.dataId = message.dataId);
    message.type !== undefined && (obj.type = message.type);
    message.key !== undefined && (obj.key = message.key);
    message.data !== undefined && (obj.data = message.data);
    message.userId !== undefined && (obj.userId = message.userId);
    message.channelId !== undefined && (obj.channelId = message.channelId);
    message.postId !== undefined && (obj.postId = message.postId);
    return obj;
  },

  fromPartial(object: DeepPartial<DataInternal>): DataInternal {
    const message = { ...baseDataInternal } as DataInternal;
    if (object.dataId !== undefined && object.dataId !== null) {
      message.dataId = object.dataId;
    } else {
      message.dataId = 0;
    }
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type;
    } else {
      message.type = "";
    }
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = "";
    }
    if (object.data !== undefined && object.data !== null) {
      message.data = object.data;
    } else {
      message.data = "";
    }
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = 0;
    }
    if (object.channelId !== undefined && object.channelId !== null) {
      message.channelId = object.channelId;
    } else {
      message.channelId = 0;
    }
    if (object.postId !== undefined && object.postId !== null) {
      message.postId = object.postId;
    } else {
      message.postId = 0;
    }
    return message;
  },
};

messageTypeRegistry.set(DataInternal.$type, DataInternal);

export interface ApiService {
  ping(request: PingRequest): Promise<PongResponse>;
  /** -> UserNewEvent or UserUpdatedEvent */
  upsertUser(request: UserDataRequest): Promise<UserDataResponse>;
  /** -> UserNewEvent */
  createUser(request: UserDataRequest): Promise<UserDataResponse>;
  /** -> UserUpdatedEvent */
  updateUser(request: UserDataRequest): Promise<UserDataResponse>;
  getUser(request: UserGetRequest): Promise<UserDataResponse>;
  /** -> UserDeletedEvent */
  deleteUser(request: UserDeleteRequest): Promise<GenericResponse>;
  /** -> UserMutedEvent */
  muteUser(request: UserMuteRequest): Promise<GenericResponse>;
  /** -> UserBannedEvent */
  banUser(request: UserBanRequest): Promise<GenericResponse>;
  /** -> UserEnbannedEvent */
  unbanUser(request: UserUnbanRequest): Promise<GenericResponse>;
  /** -> ChannelNewEvent or ChannelUpdatedEvent */
  upsertChannel(request: ChannelDataRequest): Promise<ChannelDataResponse>;
  /** -> ChannelNewEvent */
  createChannel(request: ChannelDataRequest): Promise<ChannelDataResponse>;
  /** -> */
  updateChannel(request: ChannelDataRequest): Promise<ChannelDataResponse>;
  getChannel(request: ChannelGetRequest): Promise<ChannelDataResponse>;
  /** -> */
  deleteChannel(request: ChannelDeleteRequest): Promise<GenericResponse>;
  /** -> ChannelTruncated */
  truncateChannel(request: ChannelTruncateRequest): Promise<GenericResponse>;
  /** -> ChannelPublic */
  setChannelPublic(request: ChannelPublicRequest): Promise<GenericResponse>;
  /** ChannelMemberNew, ChannelMemberUpdated, ChannelMemberDeleted */
  channelSetMember(request: ChannelMemberRequest): Promise<GenericResponse>;
  /** name it "post" not "message" to distinquish from protobuf "message" in code */
  post(request: PostRequest): Promise<PostResponse>;
  /** -> Post.Updated */
  updatePost(request: PostRequest): Promise<PostResponse>;
  /** -> Post.Deleted */
  deletePost(request: PostDeleteRequest): Promise<GenericResponse>;
  postReaction(request: ReactionRequest): Promise<GenericResponse>;
  updateReaction(request: ReactionRequest): Promise<GenericResponse>;
  deleteReaction(request: ReactionRequest): Promise<GenericResponse>;
  /** new channel will be created if invite accepted */
  inviteDirect(request: InviteDirectRequest): Promise<GenericResponse>;
  sendInvite(request: SendInviteRequest): Promise<GenericResponse>;
  acceptInvite(request: ReplyInviteRequest): Promise<GenericResponse>;
  rejectInvite(request: ReplyInviteRequest): Promise<GenericResponse>;
  channelMarkRead(request: ChannelMarkReadRequest): Promise<GenericResponse>;
  muteChannel(request: ChannelMuteRequest): Promise<GenericResponse>;
  unmuteChannel(request: ChannelMuteRequest): Promise<GenericResponse>;
  /** emits CustomEvent */
  custom(request: CustomRequest): Promise<CustomResponse>;
}

export interface ClientSevice {
  /**
   * includes ApiService
   * after login
   */
  clientGetChannels(
    request: ClientGetChannelsRequest
  ): Promise<ClientGetChannelsResponse>;
  clientFetchPosts(
    request: ClientFetchPostsRequest
  ): Promise<ClientFetchPostsResponse>;
  clientWatch(request: ClientWatchRequest): Promise<GenericResponse>;
  /** UserStatus  events */
  clientSetStatus(request: ClientStatusRequest): Promise<GenericResponse>;
  /** UserStartTyping UserStopTyping */
  clientTyping(request: ClientTypingRequest): Promise<GenericResponse>;
  clientWantsUpload(
    request: ClientUploadRequest
  ): Promise<ClientUploadResponse>;
}

declare var self: any | undefined;
declare var window: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== "undefined") return globalThis;
  if (typeof self !== "undefined") return self;
  if (typeof window !== "undefined") return window;
  if (typeof global !== "undefined") return global;
  throw "Unable to locate global object";
})();

type Builtin =
  | Date
  | Function
  | Uint8Array
  | string
  | number
  | boolean
  | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
