import React, { useState} from "react";
import styled from "styled-components";
import {UtilModal} from "../Modal/UtilModal";
import {MediaUploader} from "../Upload/MediaUploader";
import {UploaderState} from "../../store/UploaderState";
import {observer} from "mobx-react";
import {useChatContext} from "../../context/ChatContext";
import {CustomRequest, CustomResponse} from "../../client/ngcht";


// that is uploader for left-side media browser
// admin-only
// uploads not to channel but to s3 media store

const ContentDiv = styled.div`
  font-size: 16px;

  h2 { display: inline; padding-right: 10px; font-size: 22px; }
  .head-input { font-size: 22px; border: solid 1px lightgrey; }

  @media (max-width: 787px) {
    .head-input { width: 87%; }
  }

  h3 { display: inline; padding-right: 10px; font-size: 18px; }

  .error-input { border: solid 2px red; }

  .close_btn {
    //position: absolute;
    //right: 20px;
    //top: 20px;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    margin-right: 10px;
  }

  .sendBtn {
    //position: absolute;
    //right: 20px;
    //bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
    padding-left: 20px;
    padding-right: 20px;
    color: black;
    border: solid 1px lightgrey;
  }

  .error-msg {
    color: red;
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    line-height: 1.2em;
  }
    
`

export const MediaBrowserUpload = observer((props: {
    onClose: () => void,
}) => {
    
    const [state] = useState(new UploaderState())
    const { chat } = useChatContext()

    state.setDoneCallback((it) => {
        state.postProcessStart(it.id)

        setTimeout(() => {
            state.postProcessStart(it.id)

            const msg = CustomRequest.fromJSON({})
            msg.customRequestType = "s3-offload"
            msg.request = "move-from-local"
            msg.requestData = it.uploadResponse!.main.uri

            chat.client.send(msg).then(r => {
                const res = r as CustomResponse
                if (res.ok)
                    state.postProcessOk(it.id)
                else
                    state.error(it.id, res.errorMessage)
            }).catch(e => {
                state.error(it.id, e)
            })
        }, 100)

    })

    const onClose = () => {
        chat.uploadsStore.invalidate('/')
        chat.uploadsStore.load('/')
        props.onClose()
    }

    return <UtilModal>
        <ContentDiv>
            <h2>
            <button className={"close_btn"} onClick={onClose} title="close">x</button>

             Add Library Media Content</h2>

            <MediaUploader
                showProgress={true}
                state={state}
                largePreview={true}
            />

        </ContentDiv>
    </UtilModal>
})