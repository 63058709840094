import React, {useEffect, useRef} from "react"
import styled from "styled-components";
import {UserRecord} from "../store/UserRecord";

const TypingDiv = styled.div`
  max-width: 100%;
  text-align: start;
  padding-left: 12px;
  padding-right: 6px;
  
  
  .message_body {
    background: var(--foreign-message-color);
    display: inline-flex;
    max-width: 70%;
    margin-left: 6px;
    justify-content: start;
    line-height: normal;
    border-radius: 6px;
  }
  
  .message_body p {
    max-width: 100%;
    overflow: hidden;
    padding: 4px;
    padding-right: 8px;
  }
  
  
  .message_time {
    vertical-align: middle;
    padding-top: 0;
    color: var(--gray-color);
    font-size: 12px;
    margin-left: 56px;
  }

  .message_avatar_img {
    border-radius: 1000px;
    float: left;
    width: 50px;
  }
`

export const ChannelMessagesTyping = (props: {users: UserRecord[]} ) => {

    const usersString = props.users.map(u => u.username).join(", ")

    const tDiv = useRef<HTMLDivElement>(null)

    useEffect(() => {
        tDiv.current!.scrollIntoView()
    }, [usersString])


    return (
        <TypingDiv ref={tDiv}>
            <div className="message_body">
                <p>&nbsp;&nbsp;{usersString} typing...&nbsp;&nbsp;</p>
            </div>
            <br />
        </TypingDiv>
    )
}