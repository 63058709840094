import React from 'react';
import './App.css';
import {OfLayout} from "./layouts/OfLayout";
import {ChatContext } from './context/ChatContext';
import {ChatStore} from "./store/ChatStore";

export default function App(props: { chat: ChatStore}) {
    const {chat} = props
    return (
      <ChatContext.Provider value={{chat}}>
        <OfLayout/>
      </ChatContext.Provider>
    );
}


