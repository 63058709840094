import { makeAutoObservable} from "mobx";
import {ChatStore} from "./ChatStore";
import {ClientUserData} from "../client/ngcht";

import siteAvatarImg from '../layouts/site-images/avatar.jpg'


export class UserRecord {
    userId: number
    username: string
    avatar: string
    tags: string[]
    images: string[]
    name: string
    private chatStore: ChatStore;
    // received from server
    // 0 - long ago
    // 1 - online
    // >1 - minutes since logged-out
    private clientLastOnline: number = 0
    private clientLastOnlineSetAt ?: Date

    constructor(chatStore: ChatStore, source: ClientUserData) {
        this.chatStore = chatStore;

        this.userId = source.userId
        this.username = source.username
        this.avatar = source.avatar
        this.name = source.name
        this.images = source.images
        this.tags = source.tags
        this.setClientLastOnline(source.clientLastOnline || 0)

        // site-custom : fixed avatar for admin user
        if (this.userId === 1) {
            const channel_settings = JSON.parse((chatStore.getPongData('channel_settings') as string) || '{}') as { [key: string] : string|number}
            const avatar_url = channel_settings.avatar_url ? (channel_settings.avatar_url as any).toString() : null

            this.avatar = avatar_url ? avatar_url : siteAvatarImg
        }

        makeAutoObservable(this)
    }

    setClientLastOnline(clientLastOnline: number) {
        this.clientLastOnline = clientLastOnline
        this.clientLastOnlineSetAt = new Date()
    }

    getIsOnline() : boolean {
        return this.clientLastOnline === 1
    }

    getOnlineDateOrString() : string|Date {
        if (this.clientLastOnline === 0) {
            return "Long ago"
        } else if (this.clientLastOnline === 1) {
            return "Online"
        } else if (this.clientLastOnline === 2) {
            return new Date(this.clientLastOnlineSetAt!.getTime())
        }
        return new Date(this.clientLastOnlineSetAt!.getTime() + this.clientLastOnline * 60 * 1000)
    }

    getUserCan(feature: string) : boolean {
        return this.tags && this.tags.includes("can_" + feature)
    }
}

