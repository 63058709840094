import React, {useState} from "react"
import styled from "styled-components";
import {GeneratedAvatar} from "./GeneratedAvatar";
import {PostBlocks} from "./PostBlocks/PostBlocks";
import {RelTime} from "./RelTime";
import {EnumPostRecordStatus} from "src/store/PostRecord";
import Linkify from "react-linkify"
import {
    MenuItem,
    SubMenu,
    useMenuState,
    ControlledMenu,
    MenuHeader,
    MenuDivider, MenuRadioGroup
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css"

type ChannelMessagesItemProps = {
    // item: ChatMessage
    avatar: string,
    userName: string,
    userUsername: string,

    mine: boolean,
    mineStatus: EnumPostRecordStatus,
    isAdminMessage: boolean,
    body: string,
    time: string,
    isJson: boolean,
    doDeleteMessage : null | (() => void),
}

const ChannelMessageDiv = styled.div`
  max-width: 100%;
  text-align: start;
  padding-left: 12px;
  padding-right: 6px;
  
  
  .message_body {
    background: var(--foreign-message-color);
    display: inline-flex;
    max-width: 70%;
    margin-left: 6px;
    justify-content: start;
    line-height: normal;
    border-radius: 6px;
  }
  
  .message_body p {
    max-width: 100%;
    overflow: hidden;
    padding: 4px;
    padding-right: 8px;
  }

  .message_body img {
    max-width: 100%;
  }
  
  .message_time {
    vertical-align: middle;
    padding-top: 0;
    color: var(--gray-color);
    font-size: 12px;
    margin-left: 56px;
  }

  .message_avatar_img {
    border-radius: 1000px;
    float: left;
    width: 50px;
  }
`

const ChannelMyMessageDiv = styled(ChannelMessageDiv)`
  text-align: end;
  
  .message_body {
    margin-right: 6px;
    padding-left: 40px;
    background: var(--my-message-color);
  }
  
  
  .message_time {
    
  }
`


export const ChannelMessagesItem = (props : ChannelMessagesItemProps) => {

    const [menuProps, toggleMenu] = useMenuState();
    const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });


//format={"MMM d, YYYY HH:mm"}
    const moment = <RelTime date={props.time}/>

    let bodyMsg = props.isJson ?
        <PostBlocks body={JSON.parse(props.body)} /> :
        <p>{props.body}</p>

    // hide messages about user payments from himself
    if (props.mine && props.body.match(/^{"blocks":\[{"type":"customer_paid"/)) {
        return null
    }

    let mineIcon = null
    if (props.mine) {
        switch (props.mineStatus) {
            case EnumPostRecordStatus.Error:
                mineIcon = <span style={{color: "red", fontSize: "200%", fontWeight: "bold"}}>&#9888;</span>
                break
            case EnumPostRecordStatus.Submitting:
                mineIcon = <span>&#128336;</span>
                break
            default:
                mineIcon = <span>&#10003;</span>
        }
    }

    const hrefDecorator = (decoratedHref: any, decoratedText: any, key: any) => (
        (!props.isAdminMessage || props.mine) ? <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
        </a> : <>{decoratedText}</>
    )

    return props.mine ?
        (
            <ChannelMyMessageDiv onContextMenu={ev => {
                ev.preventDefault()
                setAnchorPoint({x: ev.clientX, y: ev.clientY })
                toggleMenu(true)
            }}>
                { props.doDeleteMessage ? <ControlledMenu {...menuProps} anchorPoint={anchorPoint} onClose={() => toggleMenu(false)} >
                    <MenuHeader><i color="lightgray">Message Actions</i></MenuHeader>
                    <MenuDivider />
                    <SubMenu label="Delete">
                        <MenuRadioGroup>
                            <MenuItem type="radio" onClick={() => props.doDeleteMessage!() }><b>Yes, Delete</b></MenuItem>
                            <MenuItem type="radio" onClick={() => toggleMenu(false)}>No, Cancel</MenuItem>
                        </MenuRadioGroup>
                    </SubMenu>
                </ControlledMenu> : null }

                    <div className="message_body">
                        <Linkify componentDecorator={hrefDecorator}>{bodyMsg}</Linkify>
                    </div>
                <div className="message_time">
                    {moment}
                    &nbsp;
                    {mineIcon}
                    &nbsp;&nbsp;
                    { /* <i className="fas fa-check-double"></i> */ }
                </div>
            </ChannelMyMessageDiv>
        ) : (
            <ChannelMessageDiv>
                {props.avatar ?
                    <img className="message_avatar_img" alt={props.userName} src={props.avatar} width={50} height={50} /> :
                    <GeneratedAvatar str={props.userName}/> }
                <div className="message_body">
                    <Linkify componentDecorator={hrefDecorator}>{bodyMsg}</Linkify>
                </div>
                <div className="message_time">
                    {moment}
                </div>
            </ChannelMessageDiv>

        )
}