import {
    ChannelMarkReadRequest,
    ClientChannelData,
    ClientFetchPostsRequest,
    ClientFetchPostsResponse,
    ClientPostData,
    ClientTypingRequest, CustomRequest, CustomResponse,
    EnumChannelPublic,
    GenericResponse,
    PostDeleteRequest,
    PostEvent,
    PostResponse
} from "../client/ngcht";
import {ChatStore} from "./ChatStore";
import {action, makeAutoObservable, runInAction} from "mobx";
import {EnumPostRecordStatus, PostRecord} from "./PostRecord";
import {UserRecord} from "./UserRecord";
import {MessageDraft} from "./MessageDraft";

import siteAvatarImg from '../layouts/site-images/avatar.jpg'

export class ChannelRecord {
    channelId: number
    title: string
    description: string
    unread: number = 0
    lastMsg?: number = 0 // last msg time
    images: string[]
    avatar: string
    directUserId: number = 0
    isPublic: boolean = false
    isLive:   boolean = false
    isMuted:  boolean = false

    isDirect: boolean = false

    sendTypingPost: boolean = false

    posts: PostRecord[] = []

    _lastPost: PostRecord|null = null

    lastReadId: number

    private chatStore: ChatStore

    private startedTyping: boolean = false
    private startedTypingAt: Date|null = null
    private typingResendInterval = 5

    private sentMessages:{[msg:string]:Date} = {}

    public typingUsers: { [userId:number]: UserRecord } = {}

    public draft : MessageDraft

    constructor(chatStore: ChatStore, source: ClientChannelData, directUserId: number|undefined = undefined) {
        this.chatStore = chatStore

        this.channelId = source.channelId
        this.title = source.title
        this.description = source.description
        this.avatar = source.avatar
        this.images = source.images
        this.lastReadId = source.lastReadId
        this.isDirect = false
        if (source.lastPosts && source.lastPosts.length) {
            const u = this.chatStore.users[source.lastPosts[0].userId]
            this._lastPost = new PostRecord(chatStore, this, source.lastPosts[0], u)
        }

        if (directUserId) {
            this.directUserId = directUserId
            this.isDirect = true
            const directUser = this.getDirectUser()!
            this.title = directUser.name
            this.avatar = directUser.avatar
            this.images = directUser.images
        }
        this.isPublic = source.public === EnumChannelPublic.Public

        this.unread = 0

        this.draft = new MessageDraft(this.onTyping.bind(this))

        /// site-custom - same avatars for all channels from client-side
        if (!this.chatStore.me?.getUserCan("create_paid_posts")) {
            this.avatar = siteAvatarImg
        }

        makeAutoObservable(this, {
            postEvent: action
        })
    }

    onTyping(isTyping: boolean, text ?: string) {
        if (isTyping)
            this.startTyping(text || "")
        else
            this.stopTyping()
    }


    getDirectUser() : UserRecord|null {
        if (this.directUserId) {
            return this.chatStore.users[this.directUserId]
        } else {
            return null
        }
    }

    loadPosts() {
        const req = ClientFetchPostsRequest.fromJSON({})
        req.channelId = this.channelId
        this.chatStore.send(req).then((r) => {
            runInAction<void>(() => {
                const reply = r as ClientFetchPostsResponse
                this.chatStore.cacheUsers(reply.users)

                this.setPosts(reply.posts.map((p: ClientPostData) => {
                    return new PostRecord(this.chatStore, this, p,
                        this.chatStore.users[p.userId]
                    )
                }))
            })
        })
    }

    setPosts(posts: PostRecord[]) {
        this.posts = posts
    }

    private async post(draft: MessageDraft){

        const req = draft.getPostRequest()
        req.channelId = this.channelId
        req.userId = this.chatStore.myUserId

        const postRecord = new PostRecord(this.chatStore, this,
            ClientPostData.fromJSON(req), this.chatStore.me!)
        postRecord.mineMessageStatus = EnumPostRecordStatus.Submitting
        postRecord.mine = true
        postRecord.time = new Date()
        this.posts.push(postRecord)

        // buggy fix me later
        this.sentMessages[postRecord.post] = new Date()

        let doAttemps = 3

        do {
            doAttemps--
            console.log('sending', {doAttemps})

            // eslint-disable-next-line
            await this.chatStore.send(req).then(r => {
                const reply = r as PostResponse
                console.log('got post response', {reply})
                doAttemps = 0;
                if (reply) {
                    if (reply.ok && reply.post) {
                        postRecord.updateFromPostResponse(
                            reply.post,
                            reply.ok ? EnumPostRecordStatus.Submitted : EnumPostRecordStatus.Error
                        )
                        this.setLastReadId(postRecord.postId)
                    } else {
                        postRecord.updateFromPostResponse(postRecord.post, EnumPostRecordStatus.Error)
                    }
                }
            }).catch(e => {
                console.log('sending error', {e, req})
            })

        } while (doAttemps>0);
    }


    siteCheckPostResponse(r: PostResponse) {
        if (!r.isJson) return
        const msg = JSON.parse(r.post) as any
        if (!msg.blocks || !msg.blocks.forEach) return
        msg.blocks.forEach((b : any) => {
            if (typeof b === "object" && b.type === "customer_tip") {
                if (!b.paid && b.paymentLink) {
                    this.chatStore.redirect(b.paymentLink)
                }
            }
        })
    }

    postEvent(m: PostEvent, user: UserRecord) {
        // console.log(this.sentMessages)
        if ((m.post!.userId! === this.chatStore.myUserId) && this.sentMessages[m.post!.post!]) {
            return // ignore that is my own message
        }
        this.posts.push(new PostRecord(this.chatStore, this, m.post!, user))
    }

    public startTyping(currentMessage: string) {
        if (this.startedTyping) {
            const diff = (new Date()).getTime() - this.startedTypingAt!.getTime()
            if (diff < this.typingResendInterval * 1000) {
                // do not flood server often than 5 seconds
                return
            }
        }
        this.startedTyping = true
        this.startedTypingAt = new Date()
        const req = ClientTypingRequest.fromJSON({})
        req.channelId = this.channelId
        req.typing = true
        if (this.sendTypingPost) req.post = currentMessage
        this.chatStore.send(req).catch(e => {})
    }

    public stopTyping() {
        if (!this.startedTyping) return // do not flood server
        const req = ClientTypingRequest.fromJSON({})
        req.channelId = this.channelId
        req.typing = false
        this.chatStore.send(req).then(() => {
            this.startedTyping = false
            this.startedTypingAt = null
        })
    }

    public typingEvent(typing: boolean, userId: number, user: UserRecord) {
        if (typing) {
            this.typingUsers[userId] = user
        } else {
            delete this.typingUsers[userId]
        }
    }

    private lastPost() : PostRecord|undefined {
        if (this.posts.length)
            return this.posts.slice(-1)[0]
        else if (this._lastPost)
            return this._lastPost
    }

    // 36 chars max
    getLastMessageInfo() : [msg: string, date: Date, hasMedia: boolean, unread: boolean] {
        const shorten = (s: string) : string => {
            if (!s) return s
            if (s.length>36)
                return s.substr(0, 33) + "..."
            else
                return s
        }

        const post = this.lastPost()
        if (!post) {
            return ["", new Date(), false, false]
        }

        return [shorten(post.getPlainText()), post.time, post.getHaveMedia(), this.getUnread() ]
    }

    getUnread() : boolean {
        const p = this.lastPost()
        if (!p) return false
        if (p.userId === this.chatStore.myUserId) return false
        return this.lastReadId !== p.postId
    }

    markRead() {
        const p = this.lastPost()
        if (!p) return
        if (p.postId !== this.lastReadId) {
            const m = ChannelMarkReadRequest.fromJSON({})
            m.channelId = this.channelId
            m.readPostId = p.postId
            this.chatStore.send(m).then( () => this.setLastReadId(p.postId) )
        }
    }

    setLastReadId(lastReadId: number) {
        this.lastReadId = lastReadId
    }

    getLastPostTime() : number {
        const p = this.lastPost()
        return p ? p.time.getTime() : 0
    }

    sendDraft() {
        if (this.draft.isEmpty)
            throw new Error("could not send empty draft")
        this.post(this.draft)
        this.draft.clear()
    }

    sendDeleteRequest(postId: number) {
        const req = PostDeleteRequest.fromJSON({
            postId,
            userId: this.chatStore.myUserId,
        })

        const postIndex = this.posts.findIndex((p) => p.postId === postId)
        if (this.posts[postIndex]) this.posts[postIndex].mineMessageStatus = EnumPostRecordStatus.Submitting
        this.chatStore.send(req).then( r => {
            const rr = r as GenericResponse
            if (!rr.ok) throw new Error("error post deletion")
            runInAction(() => this.posts = this.posts.filter(p => p.postId !== postId) )
        }).catch(e => {
            runInAction(() => {
                const postIndex = this.posts.findIndex((p) => p.postId === postId)
                if (this.posts[postIndex]) this.posts[postIndex].mineMessageStatus = EnumPostRecordStatus.Error
            })
            console.log('post delete error', {e, req})
        })

    }


    sendRenameRequest(newDescription: string) {
        let req = CustomRequest.fromJSON({})
        req.customRequestType = "channel_rename"
        req.channelId = this.channelId
        req.request = newDescription

        this.chatStore.send(req).then( r => {
            const rr = r as CustomResponse
            if (!rr.ok) {
                this.description = "ERROR!"
                throw new Error("error channel rename")
            }
            runInAction(() => this.description = rr.responseData)
        }).catch(e => {
            runInAction(() => this.description = newDescription + " (error editing)" )
            console.error('channel rename error', {e, req})
        })

    }


}

