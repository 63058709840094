import React, {useState} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components";
import {observer} from "mobx-react";
import {useChatContext} from "../context/ChatContext";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {tagToDisplay} from "src/utils/tagUtils";

// import Loader from "react-loader-spinner";

const ChannelsListHeaderDiv = styled.div`
  line-height: 40px;
  margin-left: 21px;
  text-decoration: none;

  font-size: 18px;
  font-weight: 800;
  padding: 10px 10px;
  border-bottom: solid 1px var(--border-color);
  
  .buttons { float: right;  }
  
  .channel_search_input {
    font-size: 18px;
    color: gray;
  }
  
  .chat_channel_tag {
    padding: 2px;
    padding-left: 5px; padding-right:5px;
    margin: 1px;
    border-radius: 5px;
    background-color: #ccced2;
    color: #999;
    margin-left: 10px;
    font-weight: normal;
    font-size: 20px;
    font-style: italic;
    border: solid 1px green;
    white-space: nowrap;
  }

  .chat_channel_tag_selected {
    color: black;
  }
  
  .chat_tags_filter {
    width: 100%;
    max-height: 120px;
    overflow-y: scroll;
    line-height: 40px;
  }
  
  .icon-wrapper {
    display: inline-block;
    padding: 0px 10px;
    border: solid 1px transparent;
  }
  .icon-wrapper:hover {
    border: solid 1px #eee;
  }
`

const SearchTags = (props: {
    knownTags: string[],
    tags : string[],
    onSetTags : (tags: string[]) => void
} ) => {

    const clickTag = (t: string, prevState: boolean) => {
        if (prevState) {
            props.onSetTags(props.tags.filter(tag => t !== tag))
        } else {
            props.onSetTags([...props.tags, t])
        }
    }

    return <div className={"chat_tags_filter"}>
        {props.knownTags.map(t => {
            const selected = props.tags?.includes(t) || false
            return <span key={t}><span
                    className={selected ?
                        "chat_channel_tag chat_channel_tag_selected" :
                        "chat_channel_tag"
                    } onClick={() => clickTag(t, selected)}>
                <FontAwesomeIcon icon={"tag"}/>{tagToDisplay(t)}
            </span> </span>
            }
        )
        }
    </div>
}

const ChatConnectionStatus = observer( () => {
    const {chat} = useChatContext()

    let circleColor = "limegreen"
    let title = "connected"

    if (!chat.connected) {
        circleColor = "lightgray"
        title = "disconnected"
    }

    return <span title={title}><svg height="18" width="26" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="60" r="40" fill={circleColor}/>
    </svg></span>

}
)

export const ChannelsListHeader = observer((props: {}) => {

    const { chat } = useChatContext()

    const SEARCH_NONE = 0;
    const SEARCH_TEXT = 1;
    const SEARCH_TAGS = 2;

    const [search, _toggleSeach] = useState(SEARCH_NONE)

    const setTextSearch = (text: string) => {
        chat.setChannelsFilter({ "filter" : "string", name: text })
    }

    const toggleSearch = (flag: number) => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 10)
        if (!flag) setTextSearch("")
        if (!flag) chat.setChannelsFilter(undefined)
        _toggleSeach(flag)
    }

    const searchInput = <input className={"channel_search_input"} size={16}
                               placeholder={"Filter by name"}
                               onChange={ev => setTextSearch(ev.target.value) }/>


    const tags = chat?.channelsFilter?.filter === "tags" ? chat.channelsFilter.tags : undefined

    const onSetTags = (tags: string[]) => {
        if (tags)
        {
            chat.setChannelsFilter({ filter: "tags", tags, })
        } else {
            chat.setChannelsFilter(undefined)
        }
    }

    let canBroadcast = false
    let messagesTitle = "MESSAGES"
    if (chat.channelsFilter) {
        const found = chat.getListChannels().length
        messagesTitle += " (" + chat.getListChannels().length + "+)"
        if (found) {
            canBroadcast = true
        }
    }

    const toggleBroadcastInput = () => {
        chat.setOpenComposeBroadcast( true )
    }

    return (
        <ChannelsListHeaderDiv>
            {chat.me?.getUserCan("broadcast") || chat.me?.getUserCan("create_paid_posts") ? <span className="buttons">
                <div className="icon-wrapper" onClick={() => toggleSearch(search ? SEARCH_NONE : SEARCH_TEXT )}>
                <FontAwesomeIcon icon="search" />
                </div>
                <div className="icon-wrapper" onClick={() => toggleSearch(search ? SEARCH_NONE : SEARCH_TAGS ) }>
                    <FontAwesomeIcon icon="tags" />
                </div>
                <div className="icon-wrapper" onClick={() => canBroadcast ? toggleBroadcastInput() : null} >
                    <FontAwesomeIcon icon="plus"
                                     style={{ color: canBroadcast ? "black" : "gray" }}
                    />
                </div>
            </span> : null }
            {/*<FontAwesomeIcon icon="arrow-left" />*/}
            <div className={"channel_headers_title"}>
                <ChatConnectionStatus />
                { search === SEARCH_TEXT ? searchInput : messagesTitle  }
                { search !== SEARCH_TAGS ? null :
                    <SearchTags
                        knownTags={ chat.getTagsList() }
                        tags={tags || []}
                        onSetTags={ onSetTags }
                    />
                }
            </div>
            {/*<div>*/}
            {/*    { chat.channelsLoading ?*/}
            {/*        <Loader type="Oval" color="#00BFFF" height={30} width={30} />*/}
            {/*        : null }*/}
            {/*</div>*/}
        </ChannelsListHeaderDiv>
    )
})