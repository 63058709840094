import {TPcchtClient} from "../store/ChatStore";
import {messageTypeRegistry, UnknownMessage} from "./typeRegistry";
import {
    ConnectResponseMessage,
    IncomingConnectMessage, IncomingPostMessage, SomeOutgoingMessage
} from "../../../worker/src/msg-types";
import {PongResponse} from "./ngcht";
import {PcchtClientEvent, PcchtClientMessageEvent} from "./PcchtClient";


type TWaitReply = {
    resolve: (mm: UnknownMessage) => PromiseLike<UnknownMessage> | UnknownMessage | void ,
    reject: (reason?: any) => void
}

type WebWorkerWithPort = Worker & { port : MessagePort }


export class SharedChatClient implements TPcchtClient {

    private debug: boolean = false
    private token: string
    private chatKey: string
    private wsUrl: string

    private options: any
    private instanceId: string

    private worker: /*SharedWorker |*/ WebWorkerWithPort
    private clientId: string | undefined;
    
    private connectPromise ?: Promise<PongResponse>
    private connectPromiseResolve ?: (value: (PromiseLike<PongResponse> | PongResponse)) => void;
    private connectPromiseReject ?: (reason?: any) => void;

    private waitPosts : Map<number, TWaitReply> = new Map()

    protected listeners : {[eventType: string] : EventListenerOrEventListenerObject[] } = {}

    constructor(options: {chatKey: string, token: string, wsUrl: string, debug?: boolean, clientId ?: string, userId: string}) {

        this.debug = !!options.debug
        this.chatKey = options.chatKey
        this.token = options.token
        this.wsUrl = options.wsUrl
        this.clientId = options.clientId

        this.instanceId = options.chatKey + "#" + options.userId /// todo fixit

        // if (window.SharedWorker) {
        //     this.worker = new SharedWorker("/worker.js")
        //     console.debug('using SharedWorker')
        // } else
        if (window.Worker) {
            const w = new Worker("./worker.js") as any
            const channel = new MessageChannel()
            w.port = channel.port2
            this.worker = w
            this.worker.postMessage({ port: channel.port1} , [ channel.port1 ])
            console.debug('using Worker')
        } else {
            throw new Error('upgrade browser, WebWorker support is required')
        }

        this.worker.addEventListener("error", (e) => {
            console.error('worker error', {e})
        })
        this.worker.port.start();
        this.worker.port.addEventListener('message', this.onMessage.bind(this))
        this.worker.port.addEventListener('messageerror', (e) => {
            console.error('sharedWorker messageerror: ', {e});
        })
    }

    addEventListener(type: "open" | "close" | "message" | "error", callback: EventListenerOrEventListenerObject) {
        this.listeners[type] = [...this.listeners[type]??[], callback]
    }

    dispatchEvent(event: PcchtClientEvent ) {
        if (!this.listeners[event.type]) return
        for (const listener of this.listeners[event.type]) {
            if ((listener as any).handleEvent) {
                (listener as any).handleEvent.apply(listener, [event])
            } else {
                (listener as any).apply(listener, [event])
            }
        }
    }

    onMessage(ev: MessageEvent) {
        const msgRaw = ev.data as SomeOutgoingMessage

        switch (msgRaw.cmd) {
            case "open_result":
                const m = ev.data as ConnectResponseMessage
                if (m.ok) {
                    if (this.connectPromiseResolve) {
                        this.connectPromiseResolve(m.message as PongResponse)
                    }
                } else {
                    if (this.connectPromiseReject) this.connectPromiseReject(m.message)
                }
                return
            case "error":
                if (msgRaw.seqId>0) {
                    const pr = this.waitPosts.get(msgRaw.seqId)
                    if (pr) {
                        pr.reject(msgRaw.message)
                    }
                } else {
                    console.error('Unhandled error message from client', {msgRaw})
                }
                return
            case "received":
                const mm = msgRaw.message as UnknownMessage
                // console.log(this.waitPosts, { seq: msgRaw.seqId})
                let isReply = false
                if (msgRaw.seqId>0) {
                    const pr = this.waitPosts.get(msgRaw.seqId)
                    if (pr) {
                        isReply = true
                        pr.resolve(mm)
                    }
                }
                const t = messageTypeRegistry.get(mm.$type)
                if (typeof t != 'undefined') {
                    this.dispatchEvent(new PcchtClientMessageEvent(this, mm, t, isReply))
                } else {
                    console.error('unrecognized message received from chat client', {mm})
                }
                return
            case "disconnect":
                this.dispatchEvent(new PcchtClientEvent("close"))
                return
        }
        console.error(this.clientId, 'UNHANDLED, received from sharedWorker', {ev, data:ev.data})
    }

    async connect() : Promise<PongResponse> {
        const seqId = Math.floor((Math.random() * 2147483648))
        if (!this.connectPromise) {
            this.connectPromise = new Promise<PongResponse>((resolve, reject) => {
                const msg : IncomingConnectMessage = {
                    cmd: "open",
                    instanceId: this.instanceId,
                    seqId,
                    options: {
                        chatKey: this.chatKey,
                        token: this.token,
                        wsUrl: this.wsUrl,
                    }
                }
                this.connectPromiseResolve = resolve
                this.connectPromiseReject = reject
                this.worker.port.postMessage(msg)
            }).then(x => {
                console.log({x})
                this.dispatchEvent(new PcchtClientEvent("open"))
                //console.log('resolved', x)
                return x
            }).catch(e => {
                /// console.error('rejected', e)
                throw e
            }).finally(() => {
                this.connectPromiseResolve = undefined
                this.connectPromiseReject = undefined
            })
        }
        return this.connectPromise
    }

    async send(message: UnknownMessage) : Promise<UnknownMessage> {
        const seqId = Math.floor((Math.random() * 2147483648))
        return new Promise<UnknownMessage>((resolve, reject) => {
            const m : IncomingPostMessage = {
                cmd: "post",
                seqId,
                message,
            }
            this.waitPosts.set(seqId, {resolve, reject})
            this.worker.port.postMessage(m)
        }).finally(() => {
            this.waitPosts.delete(seqId)
        })
    }

}
