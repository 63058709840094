import React from "react"
import {TEditorProps} from "./ChannelMessageInput";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as PostBlocks from '../../post-blocks/BlockTypes'
import {observer} from "mobx-react";

const TipDiv = styled.div`
  font-size: 20px;
  font-weight: bold;
  height: 3em;
  
  input {
    font-size: 20px;
    border: solid 1px lightgrey;
    margin-left: 8px;
  }
 
  small {
    font-weight: lighter;
    font-size: 12px;
    color: lightgray;
  }
`


export const EditorCustomerTip = observer( (props: TEditorProps) => {
    const {setBlocks, getBlocks} = props

    let price="30"
    const blocks : PostBlocks.CustomerTip[] = getBlocks!() as any
    if (blocks && blocks[0] && blocks[0].price) {
        price = blocks[0].price
    }

    const setDraft = (x: string) => {
        const block : PostBlocks.CustomerTip = {
            "type" : "customer_tip",
            "price" : x,
        }
        setBlocks!([block])
    }

    const min = 5
    const max = 500

    return <TipDiv>
        <FontAwesomeIcon icon={"money-check-alt"} style={{"color":"#6B8068"}}/>
        &nbsp;
        <label>Attach Tip</label>
        <input type="range" className="slider" min={min} max={max} step="1" value={price}
            onChange={(ev)=>setDraft(ev.target.value)}
        />
        <label>${price}</label>
    </TipDiv>

})