import React, { ReactNode, useRef} from "react"
import BigPicture from "bigpicture";

interface Props {
    children: ReactNode
    type: "image" | "audio" | "video" | "youtube" | "iframe" | "vimeo"
    src: string
    caption?: string
    className?: string
    style?: object
    onClick?: () => void
    animationStart?: () => void
    animationEnd?: () => void
    onClose ?: () => void
    onError ?: () => void
    onChangeImage ?: () => void
    noLoader ?: () => void
}

export function ReactBigPicture(props: Props) {
    const {
        type,
        src,
        caption,
        children,
        className,
        style,
        onClick,
        animationStart,
        animationEnd,
        onClose,
        onError,
        onChangeImage,
        noLoader,
        ...rest} = props

    const elRef = useRef(null);

    const zoomHandle = () => {
        let options : any = {
            el: elRef.current
        };

        switch (type) {
            case "image":
                options.imgSrc = src;
                break;
            case "video":
                options.vidSrc = src;
                break;
            case "youtube":
                options.ytSrc = src.split("=")[1];
                break;
            case "iframe":
                options.iframeSrc = src;
                break;
            case "audio":
                options.audio = src;
                break;
            case "vimeo":
                options.vimeoSrc = src.replace(/https:\/\/vimeo\.com\//, "");
                break;
            default:
                options.imgSrc = src;
                break;
        }

        if (typeof onError === "function") {
            options.onError = onError;
        }

        if (typeof animationStart === "function") {
            options.animationStart = animationStart;
        }
        if (typeof animationEnd === "function") {
            options.animationEnd = animationEnd;
        }
        if (typeof onClose === "function") {
            options.onClose = onClose;
        }
        if (typeof onChangeImage === "function") {
            options.onChangeImage = onChangeImage;
        }
        if (noLoader) {
            options.noLoader = true;
        }

        BigPicture(options);
    }

    return <div
        className={`bigpicture link picture ${className || ""}`}
        onClick={() => {
            if (onClick) onClick();
            zoomHandle();
        }}
        ref={elRef}
        title={caption || ""}
        style={{ display: "inline-block", ...style }}
        {...rest}
    >
        {props.children}
    </div>
}
