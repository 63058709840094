import ReactModal from "react-modal";
import {ReactChild } from "react";

import './UtilModal.css'
//
// ReactModal.defaultStyles = {
//     overlay: {
//         position: 'fixed',
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         backgroundColor: 'rgba(100, 100, 100, 0.75)'
//     },
//     content: {
//         position: 'absolute',
//         top: '40px',
//         left: '40px',
//         right: '40px',
//         bottom: '40px',
//         border: '1px solid #ccc',
//         background: '#fff',
//         overflow: 'auto',
//         WebkitOverflowScrolling: 'touch',
//         borderRadius: '8px',
//         outline: 'none',
//         padding: '20px',
//         maxWidth: '95%',
//     }
// }

export const UtilModal = (props: { children: ReactChild | ReactChild[] }) => {
    return <ReactModal isOpen={true} ariaHideApp={false}
                       className={"chat__editor__modal__window"}
                       overlayClassName={"chat__editor__modal__overlay"}
        contentLabel="Inline Styles Modal Example">
        {props.children}
    </ReactModal>
}
