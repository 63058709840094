import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorkerRegistration'
import {addLibraryIcons} from "./icons";
import {ChatStore} from "./store/ChatStore";
import {SharedChatClient} from "./client/SharedChatClient";
import jwtDecode from "jwt-decode";
import {LiveChatStore} from "./store/LiveChatStore";
import {ChatContext, LiveChatContext} from "./context/ChatContext";
import {LiveChatBadgeLayout, LiveChatIconLayout} from "./layouts/LiveChatIconLayout";
import {LiveChatLayout} from "./layouts/LiveChatLayout";
import {FullIframeLayout} from "./layouts/FullIframeLayout";
import {UserMediaViewLayout} from "./layouts/UserMediaViewLayout";


// import remotedev from 'mobx-remotedev'
//// sw start

///if (!('serviceWorker' in navigator)) { throw new Error('Please upgrade your browser'); }
/// console.log(serviceWorker)
/// if (!('sharedWorker' in navigator)) { throw new Error('please upgrade your browser ("sharedWorker" support required)'); }

///  we end
serviceWorker.unregister()

// disable right click
document.oncontextmenu = document.body.oncontextmenu = function() {return false;}

type TChatMode = "fullchat" | "livechat" | "livechat-widget" | "fulliframe" | "usermediaview"
type TExtConfig = {
    autoconnect: boolean
    autorender: boolean
    token: string
    userId: string
    wsUrl: string
    apiUrl: string
    debug: boolean
    mode:  TChatMode
    chatKey: string
    containerId: string
    livechatRefresh: number
    livechatIconShow: boolean,
    livechatBadgeSelector ?: string,
}

const args = new URLSearchParams(window.location.search)
const config : TExtConfig = {
    autoconnect: true,
    autorender: true,
    chatKey: "pcchat",
    livechatIconShow: true,
    mode: (args.get('chatMode') as TChatMode) || "fullchat",
    token: args.get('jwt') || "",
    userId: "",
    wsUrl: args.get('wsUrl') || "",
    apiUrl: args.get('apiUrl') || "",
    debug: true,
    containerId: 'chat-wrapper',
    livechatRefresh: 10, /// fixme set to 60 or 120
}

const chatInit = (config: TExtConfig) => {

    if (!config.userId && config.token) {
        config.userId = (jwtDecode(config.token) as any)?.userId
    }
    const uniqId = config.chatKey + "#" + config.userId

    if (window.location.href.match(/fullchat/)) {
        config.mode = "fullchat"
    }

    addLibraryIcons()

    if (!config.token || !config.wsUrl) {
        if (config.autorender) {
            ReactDOM.render(
                <>
                    <div style={{color: "red", paddingLeft: '30%', paddingTop: '5em'}}>Chat error: invalid URL used,
                        access token and url is required
                    </div>
                </>,
                document.getElementById(config.containerId)
            )
        }
        return
    }

    if ((config.mode === "livechat" || config.mode === "fulliframe" || config.mode === "usermediaview" )) {
        // normal flow
        const sclient = new SharedChatClient({...config, clientId: uniqId})
        let chat = new ChatStore(sclient, {
            autoselectFirstChannel: true
        })
        // chat = remotedev(chat, {global: true, name: "PcChat"}) // mobx debug
        if (config.autoconnect) {
            chat.connect()
        }
        if (config.autorender) {

            let el = null
            switch (config.mode) {
                case 'fulliframe' : el = <FullIframeLayout />;  break
                case 'livechat' : el = <LiveChatLayout />; break
                case "usermediaview": el = <UserMediaViewLayout />; break
            }

            ReactDOM.render(
                <React.StrictMode>
                    <ChatContext.Provider value={{chat}}>
                        { el }
                    </ChatContext.Provider>
                </React.StrictMode>,
                document.getElementById(config.containerId)
            );
        }
    } else if (config.mode === "livechat-widget") {
        let livechat = new LiveChatStore({
            ...config,
            clientId: uniqId,
        })

        if (config.livechatIconShow) {
            ReactDOM.render(
                <React.StrictMode>
                    <LiveChatContext.Provider value={{livechat}}>
                        <LiveChatIconLayout/>
                    </LiveChatContext.Provider>
                </React.StrictMode>,
                document.getElementById(config.containerId)
            )
        }

        if (config.livechatBadgeSelector) {
            const el = document.querySelector(config.livechatBadgeSelector) as HTMLElement
            // console.log('using livechatBadgeSelector', config.livechatBadgeSelector, el);
            if (el) {
                // we will replace livechatBadgeSelector with static text + rendered node
                ReactDOM.render(
                    <React.StrictMode>
                        <LiveChatContext.Provider value={{livechat}}>
                            <LiveChatBadgeLayout text={el.innerText}/>
                        </LiveChatContext.Provider>
                    </React.StrictMode>,
                    el
                )
            } else {
                console.error("cannot insert livechatBadgeSelector, element with id ", config.livechatBadgeSelector, "not found");
            }
        }

        if (config.autoconnect) {
            livechat.connect()
        }
    } else { // fullchat
        // normal flow
        const sclient = new SharedChatClient({...config, clientId: uniqId})
        let chat = new ChatStore(sclient)
        // chat = remotedev(chat, {global: true, name: "PcChat"}) // mobx debug
        if (config.autoconnect) {
            chat.connect()
        }

        if (config.autorender) {
            ReactDOM.render(
                <React.StrictMode>
                    <App chat={chat}/>
                </React.StrictMode>,
                document.getElementById(config.containerId)
            );
        }
    }


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}

type TPcchtCallback = (
    setConfig: (configKey: string, configValue: string|number) => void
) => void

if (typeof (window as any).pcchat === "function") {
    const cb : TPcchtCallback = (window as any).pcchat
    cb((k,v) => (config as any)[k] = v)
    if (config.debug) console.debug({config})
}
chatInit(config)
