

const ucfirst = (text:string) => text.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

export const tagToDisplay = (tag: string) : string => {
    if (!tag.length) return ''
    tag = tag.replace(/_/g, ' ')
    return ucfirst(tag)
}

export const tagsDisplay = (tags: string[]) : string => {
    const t = tags.filter(t => !t.match(/^(can_|paid_for)/))
    return t.map(tagToDisplay).join(' ')
}