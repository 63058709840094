import {makeAutoObservable, runInAction} from "mobx";
import * as Blocks from '../post-blocks/BlockTypes'
import {TUploadJsonResponse} from "./UploaderStore";


export type UploadItem = {
    added: Date,
    type: "image" | "video",
    id: string,
    name: string,
    progress: number,
    thumbUrl: string,
    error ?: boolean,
    errorMsg  ?: string,
    uploading ?: boolean,
    uploadResponse ?: TUploadJsonResponse,
    blockTemp ?: Blocks.MediaFile,
    blockInsert ?: Blocks.MediaFile,
}

const hashCode = (...strings : string[]) : string => {
    let hash = 0;
    for (const str of strings) {
        for (var i = 0; i < str.length; i++) {
            hash = ((hash<<5)-hash)+str.charCodeAt(i);
            hash = hash & hash; // Convert to 32bit integer
        }
    }
    return Math.abs(hash).toString();
}

type TFileLike = {
    name: string
    type ?: string
    lastModified ?: number
    size ?: number
}

const fileId = (file: TFileLike) => hashCode(file.name + file?.lastModified + file?.size)


export class UploaderState {

    items: { [id:string] : UploadItem } = {}

    private blocksCallback ?: (items: Blocks.MediaFile[]) => void

    private doneCallback ?: (it: UploadItem) => void

    constructor() {
        makeAutoObservable(this)
    }

    setBlocksCallback(c: (items: Blocks.MediaFile[]) => void) {
        this.blocksCallback = c
    }

    setDoneCallback(c : (it: UploadItem) => void) {
        this.doneCallback = c
    }

    start(file: File) : string {
        const id = fileId(file)

        let thumbUrl = ""
        if (file.type.startsWith("image") && file.size<1048576) {
            thumbUrl = URL.createObjectURL(file)
        }

        this.items[id] = {
            added: new Date(),
            type: file.type.startsWith("video") ? "video" : "image",
            progress: 0,
            error: false,
            errorMsg: "",
            id,
            name: file.name,
            thumbUrl: thumbUrl,
            uploading: true,
        }

        return id
    }

    progress(id: string, progress: number) {
        if (this.items[id])
            this.items[id].progress = progress
    }

    done(id: string, item: Partial<UploadItem>) {
        if (item.uploadResponse) {
            item.blockInsert = this.items[id].type === "image" ?
                { type: "image" , image_url: item.uploadResponse.main.uri, thumb_url: item.uploadResponse.thumb.uri } :
                { type: "video" , video_url: item.uploadResponse.main.uri,
                    poster_url: item.uploadResponse.thumb?.uri,
                    thumb_url: item.uploadResponse.thumb?.uri }
            item.blockTemp = this.items[id].type === "image" ?
                { type: "image" , image_url: item.uploadResponse.main.temp,
                    thumb_url: item.uploadResponse.thumb.temp
                } :
                { type: "video" , video_url: item.uploadResponse.main.temp,
                    poster_url: item.uploadResponse.thumb?.temp,
                    thumb_url: item.uploadResponse.thumb?.temp
                }
        }
        this.items[id] = {...this.items[id], ...item, progress: 100, error: false, }
        if (this.blocksCallback) this.blocksCallback(this.blocks())
        if (this.doneCallback) this.doneCallback(this.items[id])
    }

    postProcessStart(id: string) {
        runInAction(() => this.items[id].uploading = true)
    }

    postProcessOk(id: string) {
        runInAction(() => this.items[id].uploading = false )
    }

    delete(id: string) {
        delete this.items[id]
        if (this.blocksCallback) this.blocksCallback(this.blocks())
    }

    finished(id: string) {
        this.items[id].uploading = false
    }

    error(id:string, e:Error|string) {
        this.items[id].errorMsg = e.toString()
        this.items[id].error = true
    }

    getSorted() : UploadItem[] {
        return Object.values(this.items)
            .sort((a,b) => a.added.getTime()-b.added.getTime())
    }

    blocks() : Blocks.MediaFile[] {
        return this.getSorted().filter(it => !!it.blockInsert).map(it => it.blockInsert) as any
    }

    isLoading() : boolean {
        return Object.values(this.items).some(it => it.uploading)
    }

    addBlocks(insert: Blocks.MediaFile, preview: Blocks.MediaFile) {
        const file : TFileLike = {
            name: insert.type === "video" ? insert.video_url : insert.image_url,
            type: insert.type + '/x-some',
        }
        const id = fileId(file)
        this.items[id] = {
            id,
            progress: 100,
            type: insert.type,
            added: new Date(),
            blockInsert: insert,
            blockTemp: preview,
            thumbUrl: preview.thumb_url || "",
            name: file.name,
        }
    }

}

