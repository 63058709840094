import React, {ReactElement, ReactNode, useRef, useState} from "react"
import {observer} from "mobx-react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useChatContext} from "../../context/ChatContext";
import {ChannelBroadcast} from "../../store/ChannelBroadcast";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {MessageDraft} from "../../store/MessageDraft";

import * as BlockTypes from '../../post-blocks/BlockTypes'

const PostDiv = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  
    padding-top: 12px;
    border-top: solid 1px var(--border-color);
    padding-left: 12px;
    max-height: 200px;
  
    &.pccht-with-media-uploader {
      max-height: 400px;
    }
  
    .post_send_buttons {
      vertical-align: bottom;
      height: 40px;
      color: var(--gray-color);
      font-size: 22px;
    }

  .post_send_buttons svg {
    position: relative;
    bottom: -8px;
  }
  
  
  .post-disabled {
    color: gray;
    height: 10em;
    padding-top: 40px;
    text-align: center;
    font-weight: bold;
  }
`


const PostButton = styled.button`
    color: var(--bg-color);
    background-color: var(--gray-color);
    border: none;
    font-size: 20px;
    font-weight: 800;
    padding: 8px 16px;
    border-radius: 1000px;
  
    float: right;
    margin-right: 4px;
    margin-bottom: 4px;
  
    &.chat-input-not-empty {
      background-color: var(--btn-active-color)  
    }
  
`

    // const noPostContent = <>
    //     <div className={"post-disabled"}>Please renew your subscription to post any messages</div>
    // </>

export type EditorDefinition = {
    id: string
    title: string
    icon ?: ReactNode | IconProp // ignored for first editor
    editor: ReactElement,
    modal ?: boolean
}

export type TEditorProps = {
    key    ?: string|number,
    _draft ?: MessageDraft,
    sendMsg ?: () => void,
    editorId ?: string,

    setState ?: (state: unknown) => void,
    getState ?: () => unknown,

    setBlocks ?: (blocks: BlockTypes.Any[]) => void,
    getBlocks ?: () => BlockTypes.Any[] | undefined,

    close ?: () => void,
}


export const ChannelMessageInput = observer((props: {
    children: EditorDefinition[],
}) => {

    const editors = props.children
    if (!editors.length) throw new Error('at least one editor must be provided')

    const __chat = useChatContext().chat

    const activeChannel = __chat.getActiveChannel()!
    const activeChannelId = __chat.getActiveChannelId()

    const buttonRef = useRef<HTMLButtonElement>(null)

    const [msgKey, setMsgKey] = useState(1000) // DIRTY ! REWORK IT TODO

    const activeEditors = activeChannel?.draft.getActiveEditors()

    const toggleActiveEditors = (editorId: string) => {
        if (activeEditors.includes(editorId)) {
            activeChannel?.draft.setActiveEditors(activeEditors.filter(x => x !== editorId))
        } else {
            const rec = editors.find(def => def.id === editorId)
            if (rec?.modal)
                activeChannel?.draft.setActiveEditors([editorId])
            else
                activeChannel?.draft.setActiveEditors([...activeEditors, editorId])
        }
    }

    let sendBtnLabel = "SEND"
    if (activeChannel instanceof ChannelBroadcast) {
        sendBtnLabel = "SEND BROADCAST"
    }

    const sendMsg = () => {
        if (!activeChannel.draft.isEmpty) {
            activeChannel.draft.setActiveEditors(activeEditors)
            activeChannel.sendDraft()
            setMsgKey(msgKey + 1)
            activeChannel?.draft.setActiveEditors([])
        }
    }

    const renderEditor = (n: EditorDefinition) => {
        const props : Required<TEditorProps> = {
            key: n.id,
            editorId: n.id,
            _draft: activeChannel?.draft,
            sendMsg,
            close: () => toggleActiveEditors(n.id),
            setState: (st) => activeChannel.draft.setEditorState(n.id, st),
            getState: () => activeChannel.draft.getEditorState(n.id),
            setBlocks: (bl) => activeChannel.draft.setEditorBlocks(n.id, bl),
            getBlocks: () => activeChannel.draft.getEditorBlocks(n.id),
        }
        return n ? React.cloneElement(n.editor, props) : n
    }

    const inputNotEmptyClass = "chat-input-not-empty"
    const content = <>
        {
            editors.slice(1).filter(rec => activeEditors?.includes(rec.id)).map(rec => {
                return renderEditor(rec)
            }).filter(Boolean)
        }
        { renderEditor(editors[0]) }
        <div className="post_send_buttons">
        {
        editors.slice(1).map(editorDef => {
            const icon = typeof editorDef.icon === "string" ?
                <FontAwesomeIcon icon={editorDef.icon as IconProp} /> :
                editorDef.icon
            return <span key={editorDef.id} onClick={() => { toggleActiveEditors(editorDef.id) } }>{icon}&nbsp;&nbsp;</span>
        })
        }
        <PostButton
            className={ activeChannel?.draft.isEmpty ? "" : inputNotEmptyClass }
            ref={buttonRef} onClick={() => { sendMsg() }}>{sendBtnLabel}</PostButton>
        </div>
    </>

    return (<>
        {activeChannel ?
            <PostDiv key={activeChannelId} className={"pccht-message-input"}>
                { content }
            </PostDiv> : null}
    </>)

})