import React from "react"
import styled from "styled-components";
import {RelTime} from "./RelTime";

const CenteredDiv = styled.div`
  height: 100%;
  
  display: flex;
  justify-content: center;
  flex-direction: column;

  font-size: 22px;
  font-weight: bold;
  text-align: center;
  
  .chat-retry-small {
    font-size: 14px;
  }
  
  .note {
    font-size: 12px;
  }
`

export const ChannelsNotConnected = (props: {error: "auth_error" | "server_error" | undefined, retryAt: Date|null} ) => {

    let errText = "Connection Error"
    let errNote = ""
    if (props.error === "auth_error") {
        errText = "Authentication Failed"
        errNote = "try refresh page"
    } else if (props.error === "server_error") {
        errText = "Server Error"
        errNote = "try refresh page"
    }

    if (props.error) {
        return <CenteredDiv>
            <span style={{color: 'red'}}>{errText}
                <div className="note">{errNote}</div>
                {props.retryAt ?
                    <div className="chat-retry-small"> Will retry in&nbsp;
                        <RelTime interval={1} date={props.retryAt} />
                        ...
                    </div>    :
                    null
                }
                <br/>
            </span>
        </CenteredDiv>
    } else {
        return (
            <CenteredDiv>
                <div>
                    Establishing connection....
                </div>
            </CenteredDiv>
        )
    }
}