/// livechat iframe contents

import React from "react"

import "./FullIframeLayout.scss"
import {observer} from "mobx-react";
import {Channel} from "../components/Channel";
import {ChannelMessages} from "../components/ChannelMessages";
import {ChannelMessageInput} from "../components/ChannelMessageinput/ChannelMessageInput";
import {EditorPlaintext} from "../components/ChannelMessageinput/EditorPlaintext";
import {EditorCustomerTip} from "../components/ChannelMessageinput/EditorCustomerTip";
import {EditorMediaUploader} from "../components/ChannelMessageinput/EditorMediaUploader";
import {EditorNoEditor} from "../components/ChannelMessageinput/EditorNoEditor";
import {useChatContext} from "../context/ChatContext";

import siteHeaderImg from './site-images/header.jpg'
import siteAvatarImg from './site-images/avatar.jpg'
import styled from "styled-components";


const customerEditors = [
    { id: "text", title: "Text", editor: <EditorPlaintext /> },
    { id: "customer-tip", icon: "dollar-sign", title: "Send Tip", editor: <EditorCustomerTip /> },
    { id: "media-upload", icon: "image", title: "Media Upload",   editor: <EditorMediaUploader /> }
]

const CustomHeaderDiv = styled.div`
    background-color: #f0f0f0;
    padding: 10px;
  
    vertical-align: middle;

    .mobile_head_image {
      margin-top: 4px;
      border: solid 1px red;
      float: left;
    }

  .mobile_head_content {
    text-align: center;
  }
`


const CustomHeader = observer((props: {
    mobile ?: boolean,
}) => {

    const { chat } = useChatContext()

    const ch = chat.getActiveChannel()
    if (!ch) return <></>

    const channel_settings = JSON.parse((chat.getPongData('channel_settings') as string) || '{}') as { [key: string] : string|number}

    const bg_url = channel_settings.bg_url ? (channel_settings.bg_url as any).toString() : siteHeaderImg
    const avatar_url = channel_settings.bg_avatar_url ? (channel_settings.bg_avatar_url as any).toString() : siteAvatarImg

    if (props.mobile) {
        // mobile header
        return <CustomHeaderDiv>

            <img alt="" className="mobile_head_image" src={avatar_url ? avatar_url : siteAvatarImg } width={50} height={50} style={{
                borderRadius: '60px',
                border: 'solid 6px white',
            }}/>

            <div className={"mobile_head_content"}>
                <div style={{fontSize: '12px'}}>
                    {ch.getDirectUser()?.getIsOnline() ? <b style={{color: "green"}}>online</b> : <span style={{color: "gray"}}>away</span> }
                </div>
                <span style={{ fontSize: '20px', fontWeight: 'bold', }}>
                            &nbsp;{ch.title}
                        </span>
                <div style={{ fontSize: "14px",}}>
                    {channel_settings.desc || "Lets Chat!"}
                </div>
            </div>

        </CustomHeaderDiv>
    } else {
        // left side header
        return <>
            <img alt="" src={bg_url ? bg_url : siteHeaderImg} width={233} style={{
                border: 'solid 3px white',
                // filter: 'blur(2px)',
            }} />
            <img alt="" src={avatar_url ? avatar_url : siteAvatarImg } width={100} height={100} style={{
                borderRadius: '100px',
                position: "relative",
                left: 60,
                top: -40,
                border: 'solid 6px white',
            }}/>

            <div style={{textAlign: 'center', width: '100%', position: "relative", top: channel_settings.bg_url ? '0' : '-20px'}}>
                <div style={{fontSize: '100%'}}>
                    {ch.getDirectUser()?.getIsOnline() ? <b style={{color: "green"}}>online</b> : <span style={{color: "gray"}}>away</span> }
                </div>
                <div style={{ fontSize: '180%', fontWeight: 'bold'}}>
                    {ch.title}
                </div>
                <div style={{ fontWeight: 'bold', }}>
                    {channel_settings.desc || "Lets Chat!"}
                </div>
            </div>
        </>
    }
})

export const FullIframeLayout = observer(() => {
    const {chat} = useChatContext()

    let editors : React.ReactNode = null
    if (chat.me?.getUserCan("message")) {
        editors = <ChannelMessageInput>{customerEditors}</ChannelMessageInput>
    } else {
        editors = <EditorNoEditor />
    }

    return <div className={"chat__full__iframe"}>
        {/*<div className="header">*/}
        {/*    /!*<MenuHeader />*!/*/}
        {/*</div>*/}
        <main className="content">
            <div className={"chat__channels " + (chat.getHaveActiveChannel() ? "" : "chat__active__page")}>
                <CustomHeader />
            </div>
            <div className={"chat__channel " + (chat.getHaveActiveChannel() ? "chat__active__page" : "")}>
                <div className="chat__mobile__header">
                    <CustomHeader mobile={true}/>
                </div>
                <Channel>
                    <ChannelMessages />
                    { editors }
                </Channel>
            </div>
        </main>
    </div>
})
