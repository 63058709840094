import {MediaBrowseDiv,  MediaBrowserPickerItself} from "./MediaBrowserPickerItself";
import * as Blocks from '../../post-blocks/BlockTypes'
import {observer} from "mobx-react";
import {useChatContext} from "../../context/ChatContext";
import {TEditorMediaBrowserPreviewState} from "../ChannelMessageinput/EditorMediaBrowserPreview";

export const MediaBrowserPickerPanel = observer( () => {

    const { chat } = useChatContext()

    if (!chat.getActiveChannel()) return <></>

    const onClick = (insert : Blocks.Video|Blocks.Image, preview: Blocks.Video|Blocks.Image) => {

        // bad solution, but need it quick
        // better to rework and avoid using global state
        if (chat.mediaBrowser.haveListener()) {
            chat.mediaBrowser.listenerCallback(insert, preview)
            chat.mediaBrowser.clearListener()
            return
        }

        const draft = chat.getActiveChannel()?.draft
        if (!draft) return
        const editors = draft.getActiveEditors()
        if (!editors.includes("media-picker"))
            draft.setActiveEditors([...editors, 'media-picker'])
        const st : TEditorMediaBrowserPreviewState = (draft.getEditorState('media-picker') || [] ) as []
        draft.setEditorState('media-picker', [...st, [ insert, preview ]])
        const bl  = draft.getEditorBlocks('media-picker') || []
        draft.setEditorBlocks('media-picker', [...bl, insert])
    }

    return <MediaBrowseDiv className={"panel"}>
        <MediaBrowserPickerItself btnLabel={"+"}
                                  onClick={onClick} />
    </MediaBrowseDiv>
})