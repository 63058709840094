import React, {useEffect, useRef} from "react"
import {ChannelsListItem} from "./ChannelsListItem";
//import Scrollbar from "react-scrollbars-custom";
import { observer} from "mobx-react";
import {useChatContext} from "../context/ChatContext";
import {ChannelRecord} from "../store/ChannelRecord";
import InfiniteScroll from "react-infinite-scroll-component";


export const ChannelsListItems = observer( (props: {}) => {
    const {chat} = useChatContext()

    const renderChannel = (ch: ChannelRecord) => {
            const [last, dd, lastHasMedia, lastUnread] = ch.getLastMessageInfo()
            return <ChannelsListItem
                    key={ch.channelId} name={ch.title}
                            tags={ch.getDirectUser()?.tags || []}
                              username={ch.getDirectUser()?.username} channelId={ch.channelId} images={ch.images}
                              avatar={ch.avatar}
                              unread={lastUnread ? 1 : 0}
                              active={ch.channelId === chat.getActiveChannelId()}
                              isDirect={ch.isDirect}
                              lastTime={dd} lastMessage={last}
                              lastMessageUnread={lastUnread}
                              lastMessageContainsMedia={lastHasMedia}
            />
    }


    // the following necessary to calculate height of container
    const channelsContainerRef = useRef(null)
    const [dimensions, updateDimensions] = React.useState<DOMRect[]>()
    useEffect(() => {
        const getDim = () => {
            //@ts-ignore
            const [parentChannelsDiv] = document.body.getElementsByClassName('chat__channels')
            if (parentChannelsDiv && parentChannelsDiv.getBoundingClientRect()) {
                const rect = parentChannelsDiv.getBoundingClientRect()
                // @ts-ignore
                const rheader = parentChannelsDiv.firstChild?.getBoundingClientRect()
                if (rheader) rect.height -= rheader.height
                updateDimensions([rect])
            }
        }
        getDim()
        window.addEventListener('resize', getDim)
        return () => {
            window.removeEventListener('resize', getDim)
        }
    }, [])

    return <InfiniteScroll
        ref={channelsContainerRef}
        height={dimensions ? dimensions[0].height : 200 }
        dataLength={chat.channelsArray.length}
        next={chat.loadMoreChannels.bind(chat)}
        hasMore={chat.channelsHasMore}
        loader={<h4>Loading...</h4>}
        scrollableTarget="chat_scrollbar_channels"
        endMessage={
            <p style={{ textAlign: "center" }}>
                {/*<b>nore more channels..</b>*/}
            </p>
        }>
        {chat.channelsArray.map(renderChannel)}
    </InfiniteScroll>

    //
    //     // const [itemCount, setItemCount] = useState(100)
    //     // const [itemsLoading, setItemsLoading] = useState(true)
    //     //
    //     // const isItemLoaded = (index: number) : boolean => {
    //     //     return !itemsLoading
    //     // }
    //
    //     // const loadMoreItems = (startIndex: number, stopIndex: number) : Promise<void> => {
    //     //     setItemsLoading(true)
    //     //     return new Promise<void>(resolve => {
    //     //         setTimeout(() => {
    //     //             setItemCount(itemCount+20);
    //     //             setItemsLoading(false);
    //     //             resolve()
    //     //         }, 500)
    //     //     })
    //     // }
    //
    //     //
    //     //
    //     //
    // const hasNextPage = chat.channelsHasMore
    // const isNextPageLoading = chat.channelsLoading
    // const itemCount = hasNextPage ? chat.channelsArray.length + 1 : chat.channelsArray.length;
    //
    // // Only load 1 page of items at a time.
    // // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    // const loadMoreItems = isNextPageLoading ? () => {} : chat.loadMoreChannels.bind(chat);
    //
    // // Every row is loaded except for our loading indicator row.
    // const isItemLoaded = (index : number) => !hasNextPage || index < chat.channelsArray.length;
    //
    // const ChildEl = (props: ListChildComponentProps) => {
    //     const {index, isScrolling, style, data} = props
    //
    //     const ch = isItemLoaded(index) ? chat.channelsArray[index] : null
    //
    //     return <ChannelsListItem key={props.index}
    //                              name={ch ? ch.name : "LOADING..." }
    //          username={"12312"} channelId={123} images={[]}
    //          avatar={""}
    //          unread={1}
    //          active={true}
    //          isDirect={false}
    //          lastTime={new Date} lastMessage={"xxxx"}
    //          lastMessageUnread={false}
    //          lastMessageContainsMedia={false}
    //     />
    // }
    //
    // // // Render an item or a loading indicator.
    // // const Item = ({ index: any, style: any }) => {
    // //     let content;
    // //     if (!isItemLoaded(index)) {
    // //         content = "Loading...";
    // //     } else {
    // //         content = items[index].name;
    // //     }
    // //
    // //     return <div style={style}>{content}</div>;
    // // };
    //
    //     return <div ref={channelsContainerRef}>
    //         <InfiniteLoader
    //             isItemLoaded={isItemLoaded}
    //             loadMoreItems={loadMoreItems}
    //             itemCount={itemCount}
    //         >
    //             {({ onItemsRendered, ref }) => (
    //                 <FixedSizeList
    //                     itemCount={itemCount+1}
    //                     onItemsRendered={onItemsRendered}
    //                     ref={ref}
    //                     itemSize={50}
    //                     height={dimensions ? dimensions[0].height : 100 }
    //                     width={ dimensions ? dimensions[0].width : 100 }
    //                     children={ChildEl}
    //                 />
    //                 )}
    //         </InfiniteLoader>
    //     </div>
    //
    //     // return <InfiniteLoader
    //     //     isItemLoaded={() => chat.channelsLoading}
    //     //     itemCount={() => Object.length(chat.channels)}
    //     //     loadMoreItems={loadMoreItems}
    //     // >
    //     //         {({ onItemsRendered, ref }) => (
    //     //             <FixedSizeList
    //     //                 itemCount={itemCount}
    //     //                 onItemsRendered={onItemsRendered}
    //     //                 ref={ref}
    //     //                 {...props}
    //     //             >
    //     //                     {}
    //     //             </FixedSizeList>
    //     //         )}
    //     // </InfiniteLoader>
    //     //
    //     // return (<Scrollbar noScrollX={true}>
    //     // {
    //     //         ctx.chat.getListChannels().map(ch => renderChannel(ch))
    //     // }
    //     // </Scrollbar>
    //     // )
})
