import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {ChangeEvent} from "react";


export const UploadFileSelector = (props: {
    onSelected: (file: File) => void,
    multiple ?: boolean,
}) => {

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files as FileList
        if (!files.length) return
        [...files].forEach( f => props.onSelected(f) )
    }

    return <label>
        <FontAwesomeIcon icon={"cloud-upload-alt"} size={"2x"}/>
        <input multiple={props.multiple} type="file" onChange={onChange} accept="image/*,video/*" id='file-upload' style={{display: "none"}}/>
    </label>
}

