import React from "react"
import {observer} from "mobx-react";
import {useChatContext} from "../context/ChatContext";
import {ChannelsNotConnected} from "./ChannelsNotConnected";

export const Channels = observer((props: {
    children?: React.ReactNode
}) => {
    const {chat} = useChatContext()

    return <>
        { chat.wasConnected ? props.children : <ChannelsNotConnected
            error={chat.connectionError}
            retryAt={chat.connectionRetryAt}
        /> }
        </>
})