import React from "react"

export const GeneratedAvatar = (props: {str: string} ) => {
    const width = '50px'
    const fill = "#0000ee"
    const stroke = "#ffffff"

    return (
        <svg version="1.1"
             baseProfile="full"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0, 0, 100, 100" width={width}
             className="letterAvatar2 li-la2-gxmikey">
            <circle cx="50" cy="50" r="49" fill={fill} stroke={stroke} strokeWidth="1" />
            <text x="50" y="63" fontFamily="Arial"
                  fontSize="40" fontWeight="800" textAnchor="middle"
                  fill={stroke}>{props.str.substr(0, 2)}
            </text>
        </svg>
    )
}