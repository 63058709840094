import React from "react";
import {observer} from "mobx-react";

import {TEditorProps} from "./ChannelMessageInput";
import {MediaUploader} from "../Upload/MediaUploader";
import {UploaderState} from "../../store/UploaderState";


export type TUploaderProps = TEditorProps & {
    largePreview ?: boolean,
    mediaBrowserContainer ?: string, // dom selector
}

export const EditorMediaUploader = observer((props: TEditorProps & {
    largePreview ?: boolean,
    // onUpdateState ?: (newFiles: UploadItemMap)
}) => {
    const uploaderState = props.getState!() as UploaderState | undefined
    if (!uploaderState) {
        const newState = new UploaderState()
        newState.setBlocksCallback((bl) => props.setBlocks!(bl))
        props.setState!(newState)
        return null
    }

    return <MediaUploader state={uploaderState} {...props} />
})