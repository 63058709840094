import React from "react"
import {observer} from "mobx-react";
import {ChannelHeaderView} from "./ChannelHeaderView";
import {useChatContext} from "../context/ChatContext";

export const ChannelHeader = observer(() => {
    const {chat} = useChatContext()

    const onBackClick = () => chat.setActiveChannel(0)

    if (chat.getComposeBroadcastOpen()) {
        let label = "BROADCAST MESSAGE"
        label += " (" + chat.getListChannels().length.toString() + "+ channels)"
        return <ChannelHeaderView isDirectUser={false}
                                  name={label}
                                  onBackClick={onBackClick}
                                  avatar={""} avatarSize={50}
                                  />
    } else {
        const channel = chat.getActiveChannel()
        if (channel !== null) {
            const user = channel.getDirectUser()
            if (user) {
                const onSetDescription = chat.me?.getUserCan("create_paid_posts") ? (n: string) => {
                    channel.sendRenameRequest(n)
                } : undefined
                return <ChannelHeaderView isDirectUser={true} name={channel.title} username={user.username}
                                          onBackClick={chat.isSingleChannel() ? undefined : onBackClick }
                                          avatar={channel.avatar} avatarSize={50}
                                          description={channel.description} onSetDescription={ onSetDescription }
                                          statusOnline={user.getIsOnline()} status={user.getOnlineDateOrString()}/>
            } else {
                return <ChannelHeaderView isDirectUser={false} name={channel.title}
                                          onBackClick={chat.isSingleChannel() ? undefined : onBackClick }
                                          avatar={channel.avatar} avatarSize={50}
                />
            }
        }
    }
    return null
})