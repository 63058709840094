import {makeAutoObservable} from "mobx";
import * as Blocks from '../post-blocks/BlockTypes'

export type TMediaBrowserCallback = (insert: Blocks.Image|Blocks.Video, preview: Blocks.Video|Blocks.Image) => void

export class MediaBrowser {
    private listener ?: TMediaBrowserCallback;
    private callerRef ?: Object;

    constructor() {
        makeAutoObservable(this)
    }

    registerListener(listener: TMediaBrowserCallback, callerRef: Object) {
        console.log('reg')
        this.listener = listener
        this.callerRef = callerRef;
        document.body.classList.add('with_chat__editor__modal__window_shift')
    }

    sameListener(callerRef: Object) {
        return this.callerRef === callerRef
    }

    haveListener() {
        return this.listener !== undefined
    }

    clearListener(callerRef ?: Object) {
        if (callerRef === undefined || (this.callerRef === callerRef)) {
            document.body.classList.remove('with_chat__editor__modal__window_shift')
            this.listener = undefined
            this.callerRef = undefined
        }
    }


    listenerCallback(insert: Blocks.Image|Blocks.Video, preview: Blocks.Image|Blocks.Video) {
        if (this.listener) this.listener(insert, preview)
    }


}