import {action, configure, makeAutoObservable} from 'mobx';

import * as ChannelFilters from '../channel-filters'

import {
    ChannelEvent,
    ClientChannelData,
    ClientGetChannelsRequest,
    ClientGetChannelsResponse,
    ClientUserData,
    EnumChannelEvent,
    EnumUserEvent,
    PostEvent,
    TypingEvent,
    UserEvent,
    UserStatusEvent, PongResponse,
} from "../client/ngcht";
import {PcchtClientMessageEvent} from "../client/PcchtClient";
import {MessageType, UnknownMessage} from "../client/typeRegistry";
import {ChannelRecord} from "./ChannelRecord";
import {UserRecord} from "./UserRecord";
import {ChannelBroadcast} from "./ChannelBroadcast";
import {SharedChatClient} from "../client/SharedChatClient";

import {UploadsStore} from "./UploadsStore";
import {MediaBrowser} from "./MediaBrowser";
import {UploaderStore} from "./UploaderStore";

configure({
    // enforceActions: "always",
    // computedRequiresReaction: true,
    // // reactionRequiresObservable: true,
    // observableRequiresReaction: true,
    // disableErrorBoundaries: true
})

// const DEFAULT_RETRY_INTERVAL = 300 // 0.3seconds
// const MAX_RETRY_INTERVAL = 2000 // 10 seconds

// type Timeout = ReturnType<typeof setTimeout>

export type TFilterByName = {filter: "string", name: string }
export type TFilterByTags = {filter: "tags",   tags: string[] }

export interface TPcchtClient {
    addEventListener: (type: "open" | "close" | "message" | "error", callback: EventListenerOrEventListenerObject) => void
    connect: () => Promise<PongResponse>
    send: (message: UnknownMessage) => Promise<UnknownMessage>
}

type TChatStoreOptions = {
    autoselectFirstChannel ?: boolean
}


export class ChatStore {

    options: TChatStoreOptions

    connected: boolean = false
    connectionError?: "auth_error" | "server_error"
    connectionRetryAt: Date | null = null
    authError: boolean = false
    wasConnected: boolean = false // if we got fetched something from server, that is true

//    connectionRetryInterval: number = DEFAULT_RETRY_INTERVAL
//    private reconnectionTimeout: Timeout|undefined

    name: string = "Chat"

    channels: { [channelId: number] : ChannelRecord} = {}

    channelsArray: ChannelRecord[] = []
    channelsHasMore: boolean = false
    channelsLoading ?: Promise<any>

    users: { [userId: number] : UserRecord } = {}

    protected activeChannelId: number | "compose_broadcast" = 0
    protected activeChannel: ChannelRecord|ChannelBroadcast|null = null

    // will be set from PongResponse.me
    me: UserRecord|null = null
    myUserId: number = -1

    client: TPcchtClient

    uploadsStore: UploadsStore

    // client2: TPcchtClient

    /// ui
    channelsFilter ?: TFilterByName | TFilterByTags

    ///////////// settings

    channelAvatarSize: number = 50
    userAvatarSize: number = 50
    channelImageSize: number = 300
    userImageSize: number = 300
    private tagsList: string[] = []
    private pongData: { [p: string]: string } = {}

    mediaBrowser: MediaBrowser
    uploaderStore: UploaderStore

    constructor(client: SharedChatClient, options: TChatStoreOptions = {}) {

        this.client = client
        this.options = options

        this.uploadsStore = new UploadsStore(this.client)
        this.uploaderStore = new UploaderStore(this.client)
        this.mediaBrowser = new MediaBrowser()

        this.client.addEventListener('open', () => {
            this.setConnected(true)
        })
        this.client.addEventListener('close', () => {
            this.setConnected(false)
        } )
        this.client.addEventListener('message', (ev) => {
            this.processMessage(
                (ev as PcchtClientMessageEvent).message,
                (ev as PcchtClientMessageEvent).messageType,
                (ev as PcchtClientMessageEvent).isReply
            )
        } )
        makeAutoObservable(this, {
            channels: true,
            processMessage: action,
            client: false,
        })
    }

    getPongData(key: string) : unknown {
        return this.pongData[key]
    }

    // this.connectionRetryInterval = DEFAULT_RETRY_INTERVAL
    // if (this.reconnectionTimeout) {
    // clearTimeout(this.reconnectionTimeout)
    // this.reconnectionTimeout = undefined
    // }
    // private scheduleReconnect() {
    //     this.connectionRetryAt = new Date()
    //     this.connectionRetryAt.setTime(this.connectionRetryAt.getTime()+this.connectionRetryInterval)
    //
    //     window.setTimeout(() => {
    //         this.reconnectionTimeout = undefined
    //         this.connect()
    //     }, this.connectionRetryInterval)
    //
    //     if (this.connectionRetryInterval<MAX_RETRY_INTERVAL)
    //         this.connectionRetryInterval *= 1.5
    // }


    public setConnected(connected: boolean) : this {
        this.connected = connected
        if (connected) this.wasConnected = true
        if (this.connected) this.connectionError = undefined
        return this
    }

    public setConnectionError(error : "server_error" | "auth_error" | undefined) : this {
         this.connectionError = error
         this.connected = false
         return this
    }

    private notifyParentWindow(event: string, details = {}) {
        if (window.parent) {
            window.parent.postMessage({
                event,
                ...details,
            }, '*')
        }
    }

    public connect() : Promise<any> {
        const doAutoselect = this.options.autoselectFirstChannel && !this.wasConnected
        return this.client.connect().then((pm: PongResponse) => {
                this.setConnected(true)
                this.processMessage(pm, PongResponse, false) // get /me information
            this.notifyParentWindow('connected')
            return this.loadMoreChannels().then(x => {
                if (doAutoselect && this.channelsArray && this.channelsArray.length ) {
                    this.setActiveChannel(this.channelsArray[0].channelId)
                }
                return x
            })
        }).catch(e => {
            if (e.errorCode && e.errorCode === 401 ) {
                console.log('auth error')
                this.setConnectionError("auth_error")
                return
            }
            throw e
        })
    }

    public send(message: UnknownMessage) : Promise<UnknownMessage> {
        return this.client.send(message)
    }

    public gotClientChannel(ch: ClientChannelData) {
        if (ch.directUserId>0) {
            if (this.users[ch.directUserId!]) {
                this.channels[ch.channelId] = new ChannelRecord(this, ch, ch.directUserId)
            } else {
                console.error("Got a direct channel %s#%d, but related user #%d not submitted by server",
                    ch.title, ch.channelId, ch.directUserId);
                return
            }
        } else {
            this.channels[ch.channelId] = new ChannelRecord(this, ch)
        }
        if (!this.channelsArray.some(cc => cc.channelId === ch.channelId)) // fix it - need optimization!
            this.channelsArray.push(this.channels[ch.channelId])
    }

    public renderFilterRules() : string {
        if (this.channelsFilter) {
            const ff : any[] = []
            if (this.channelsFilter.filter === "tags") {
                const x : ChannelFilters.FilterByUserTags = ["user_tags", "$in", ...this.channelsFilter.tags]
                ff.push(x)
            } else if (this.channelsFilter.filter === "string") {
                const x : ChannelFilters.FilterByChannelTitle = ["channel_title", "$autocomplete", this.channelsFilter.name]
                ff.push(x)
            }
            return JSON.stringify(ff)
        }
        return ""
    }

    public loadMoreChannels() : Promise<any> {
        if (this.channelsLoading) return this.channelsLoading; // do nothing if already loading

        const chReq = ClientGetChannelsRequest.fromJSON({})

        chReq.filters = this.renderFilterRules()
        chReq.start = this.channelsArray.length
        chReq.limit = 100

        // chReq.limit = 100
        this.channelsLoading = this.client.send(chReq).then(m => {
            return this.processMessage(m, ClientGetChannelsResponse, false)
        }).finally(() => {
            this.channelsLoading = undefined
        })
        return this.channelsLoading
    }

    public processMessage(message: UnknownMessage, messageType: MessageType, isReplyEvent: boolean) {
        // console.log({message})
        if (isReplyEvent) return;

        switch (messageType) {
            case PongResponse:
                const pongr = message as PongResponse
                if (pongr.me) {
                    this.me = new UserRecord(this, pongr.me)
                    this.myUserId = this.me.userId
                }
                if (pongr.data) {
                    this.pongData = pongr.data
                }
                if (pongr.data && pongr.data.tags) {
                    this.tagsList = pongr.data.tags.split(',').filter(s => s.length > 0)
                }

            break

            case ClientGetChannelsResponse:
                // todo FIXIT! delay 1second
                // setTimeout(() => {
                    const chresp = message as ClientGetChannelsResponse
                console.log({message})
                    this.cacheUsers(chresp.users)

                    chresp.channels.forEach(ch => this.gotClientChannel(ch))
                    this.channelsHasMore = chresp.hasMore
                // }, 1000)
            break

            case PostEvent:
                const pev = message as PostEvent
                const ch = this.getChannel(pev.post!.channelId)
                if (!ch) {
                    console.debug('got post for unknown channel',pev.post!.channelId, ch, pev)
                    return
                }

                const pu = pev.user
                if (!this.users[pu!.userId]) {
                    this.cacheUsers([pev.user!])
                }
                ch.postEvent(pev, this.users[pu!.userId])
            break
            case TypingEvent:
                const tev = message as TypingEvent
                const ch0 = this.getChannel(tev.channelId)
                if (!ch0) return
                if (!this.users[tev!.userId]) {
                    this.cacheUsers([tev.user!])
                }
                ch0.typingEvent(tev.typing, tev!.userId, this.users[tev!.userId])
            break

            case UserStatusEvent:
                const usev = message as UserStatusEvent
                if (this.users[usev.userId]) {
                    this.users[usev.userId].setClientLastOnline(usev.clientLastOnline)
                }
            break

            case UserEvent:
                const userev = message as UserEvent
                if (userev.action === EnumUserEvent.UserDeleted) {
                    // todo delete user info
                } else {
                    if (userev.user)
                        this.cacheUsers([userev.user])
                }
            break

            case ChannelEvent:
                const chev = message as ChannelEvent
                if (chev.action === EnumChannelEvent.ChannelDeleted) {
                    this.deleteChannel(chev.channel?.channelId || 0)
                } else {
                    if (chev.channel)
                        this.gotClientChannel(chev.channel)
                }
            break

        }
    }

    public deleteChannel(channelId: number) {
        if (this.activeChannelId === channelId) {
            this.activeChannelId = 0
            this.activeChannel = null
        }
        delete this.channels[channelId]
    }

    public getChannel(channelId: number) : ChannelRecord | null {
        return this.channels[channelId]
    }

    public setActiveChannel(channelId: number) {
        const prev = this.activeChannelId
        if (channelId === 0) {
            this.activeChannelId = 0
            this.activeChannel = null
        } else {
            this.activeChannelId = channelId
            this.activeChannel = this.channels[channelId]
            this.activeChannel.loadPosts()
        }
        this.notifyParentWindow('setActiveChannel', {
            channelId: this.activeChannelId,
            prevChannelId: prev,
        })
    }

    // return true if there is only one channel and we do not expect more
    // like LiveChat or similar usage
    public isSingleChannel() : boolean {
        return this.channelsArray.length === 1
    }

    public setOpenComposeBroadcast(flag: boolean) {
        this.setActiveChannel(0)
        if (flag) {
            this.activeChannelId = "compose_broadcast"
            this.activeChannel = new ChannelBroadcast(this)
        }
    }

    public getComposeBroadcastOpen() : boolean {
        return this.activeChannelId === "compose_broadcast"
    }

    public getActiveChannelId() : number {
        return typeof this.activeChannelId === "number" ? this.activeChannelId : 0
    }

    getHaveActiveChannel() : boolean {
        return !!this.activeChannelId
    }

    public getActiveChannel() : ChannelRecord|ChannelBroadcast|null {
        return this.activeChannel
    }

    public cacheUsers(ucd : ClientUserData[]) {
        ucd.map((u: ClientUserData) => {
            this.users[ u.userId ] = new UserRecord(this, u)
            return null
        })
    }

    getTagsList() : string[] {
        return this.tagsList.filter(t => !t.match(/^paid_for|can_read|can_create_paid_posts|no_active_subscription/))
        // const ret : { [ tag: string ] : Boolean } = {}
        // Object.values(this.channels).forEach(ch => {
        //     ch.getDirectUser()?.tags.forEach(t => ret[t] = true )
        // })
        // return Object.keys(ret)
        //     .filter(t => !t.match(/^_/) )
        //     .filter(t => !t.match(/_(product|category)_id_\d+$/)) // site-specific
    }

    setChannelsFilter(filter: TFilterByName|TFilterByTags|undefined ) {
        this.channels = {}
        this.channelsArray = []
        this.activeChannel = null
        if (this.activeChannelId>0) this.activeChannelId = 0
        this.channelsFilter = filter
        this.loadMoreChannels()
    }

    // return list of channels to display on the screen
    // filtered and sorted
    getListChannels() : ChannelRecord[] {
        let channels = Object.values(this.channels)

        if (this.channelsFilter) {
            switch (this.channelsFilter.filter) {
                case "string":
                    const n = (this.channelsFilter.name).toLowerCase().trim()
                    channels = channels.filter(
                        ch =>
                            ch.title.toLowerCase().includes(n) ||
                            ch.getDirectUser()?.username.toLowerCase().includes(n)
                    )
                break
                case "tags":
                    const tags = this.channelsFilter.tags
                    channels = channels.filter(
                        ch => ch.getDirectUser()?.tags.some(t => tags.includes(t))
                    )
                break
            }
        }

        return channels.sort(
            (a, b) => b.getLastPostTime() - a.getLastPostTime()
        )
    }


    redirect(url: string) {
        console.log('redirect', {url}, window.parent)
        if (window.parent) {
            // this.notifyParentWindow("redirect", { url })
            // setTimeout(() => {
                window.parent.location.href = url
            // }, 800)
        } else {
            window.location.href = url
        }
    }

}
