import React from "react"
import {observer} from "mobx-react";
import {useChatContext} from "../context/ChatContext";
import { ChannelNotSelected } from "./ChannelNotSelected";

export const Channel = observer((props: {
    children?: React.ReactNode,
    parentForActiveCss ?: React.ReactNode,
}) => {
    const {chat} = useChatContext()

    if (!chat.wasConnected) {
        return <div className={"chat-not-connected"}></div>
    }

    return ( <>
        { !chat.getActiveChannel() && !chat.getComposeBroadcastOpen() ?
        (<ChannelNotSelected />) :
        props.children }
    </> )
})