import {PostRecord} from "./PostRecord";
import {UserRecord} from "./UserRecord";
import {MessageDraft} from "./MessageDraft";
import {makeAutoObservable} from "mobx";
import {ChatStore} from "./ChatStore";

function uuidv4() : string {
    return Math.random().toString(36).replace(/[^a-zA-Z0-9]+/g, '').substr(0, 5) +
        Math.random().toString(36).replace(/[^a-zA-Z0-9]+/g, '').substr(0, 5) +
        Math.random().toString(36).replace(/[^a-zA-Z0-9]+/g, '').substr(0, 5)
}

export class ChannelBroadcast {
    readonly channelId: number = -1
    readonly title: string = "BROADCAST MESSAGE"
    readonly description: string = ""
    readonly avatar: string = ""
    readonly images: string[] = []

    readonly posts: PostRecord[] = []
    readonly typingUsers: UserRecord[] = []

    private chat: ChatStore

    public getDirectUser() { return null }

    public startTyping() { }
    public stopTyping() { }
    public markRead() { }

    public draft: MessageDraft

    constructor(chat: ChatStore) {
        this.draft = new MessageDraft(()=>{})
        this.chat = chat
        makeAutoObservable(this)
    }

    sendDraft() {
        if (this.draft.isEmpty)
            throw new Error("could not send empty draft")

        const req = this.draft.getPostRequest()
        req.data["broadcast"] = "true"
        req.data["broadcast_filter"] = this.chat.renderFilterRules()
        req.data["broadcast_id"] = uuidv4()

        this.chat.send(req).then(resp => {
            console.debug('broadcast post response', resp)
        }).catch(e => console.error(e))

        this.draft.clear()
    }

    sendDeleteRequest(postId: number) {

    }
    sendRenameRequest(newName: string) {

    }

}



