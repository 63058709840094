import React, {useState} from "react"
import styled from "styled-components";
import {observer} from "mobx-react";
import {TEditorProps} from "./ChannelMessageInput";
import * as BlockTypes from "../../post-blocks/BlockTypes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useChatContext} from "../../context/ChatContext";
import {CustomRequest, CustomResponse} from "../../client/ngcht";
import {UtilModal} from "../Modal/UtilModal";
import {MediaUploader} from "../Upload/MediaUploader";
import {UploaderState} from "../../store/UploaderState";
import {PaidVideo} from "../../post-blocks/BlockTypes";

const ContentDiv = styled.div`
    font-size: 16px;
  
    h2 { display: inline; padding-right: 10px; font-size: 22px; }
    .head-input { font-size: 22px; border: solid 1px lightgrey; }
  
    @media (max-width: 787px) {
      .head-input { width: 87%; }
    }

    h3 { display: inline; padding-right: 10px; font-size: 18px; }
  
   .error-input { border: solid 2px red; }

  
  .close_btn {
    //position: absolute;
    //right: 20px;
    //top: 20px;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    margin-right: 10px;
  }
  
  .sendBtn {
    //position: absolute;
    //right: 20px;
    //bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
    padding-left: 20px; 
    padding-right: 20px;
    color: black;
    border: solid 1px lightgrey;
  }
  
  .error-msg {
    color: red;
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    line-height: 1.2em;
  }
`

export const EditorPaidPost = observer(( props: TEditorProps) => {
    const {getBlocks, setBlocks, sendMsg, close, _draft } = props

    const { chat } = useChatContext()

    const [sendError, setSendError ] = useState("")

    let blocks = getBlocks!()
    let block : BlockTypes.PaidVideo | undefined
    if (blocks && blocks.length) {
        block = blocks[0] as BlockTypes.PaidVideo
    } else {
        block = {
            "type": "paid_video",
            "title": "My Video",
            "protected": [],
            "previews": [],
            "price": "25",
            "id" : "",
        }
    }

    const updateBlock = (values: Partial<BlockTypes.PaidVideo>) => {
        setBlocks!( [{...block!, ...values}])
    }

    let state = props.getState!() as [UploaderState, UploaderState] | undefined
    if (!state) {
        state = [new UploaderState(), new UploaderState()]
        props.setState!(state)
        return null
    }

    const sendBtnActive = !(!block?.title
        || (parseFloat(block?.price || "")<5)
        || !state[0].blocks().length || !state[1].blocks().length )

    const sendClick = () => {
        setSendError("")

        _draft?.clear()
        _draft!.setActiveEditors([props.editorId!])
        block!.previews = state![1].blocks()
        block!.protected = state![0].blocks()
        setBlocks!([block as PaidVideo])

        const req = _draft!.getPostRequest()

        const ccr = CustomRequest.fromJSON({})
        ccr.customRequestType = "paid_video"
        ccr.request = "insert"
        ccr.requestData = req!.post

        chat.send(ccr).then((m) => {
             const ccresp = m as CustomResponse
             if (!ccresp.ok) {
                 setSendError("Post Creation Error: " + ccresp.errorMessage)
             } else {
                 sendMsg!();
             }
        }).finally(() => {
            close!()
        })
        // validate first
    }

    return <UtilModal>
        <ContentDiv>
           <h2>
                <button className={"close_btn"} onClick={close} title="close">x</button>
                Create Paid Post</h2>
            <FontAwesomeIcon icon={"dollar-sign"} style={{color: "gray"}}/>
            <input type="number"
            className={ "price-input head-input" + (parseFloat(block?.price)>=5 ? "" : " error-input" )}
                   min="5" max="200"
                   size={5}
                   onChange={ev => updateBlock({price: ev.target.value})}
                   value={block.price}
                   step="0.01" placeholder={"price"}
            />
            &nbsp;
            <input type="text"
                   placeholder={"enter post title"}
                   required={true}
                   value={block.title} onChange={ev => updateBlock({title: ev.target.value })}
                   className={"title-input head-input " + (block.title.length ? "" : " error-input" )} size={40} maxLength={1024} />

            <button className={"sendBtn"} disabled={!sendBtnActive} onClick={sendClick}>SEND</button>
            <div className="error-msg">{sendError}</div>


            <br />
            <br />
            <h3>Paid Content</h3><small>visible to paid customers</small>
            <MediaUploader largePreview={true} state={state[0]} useMediaBrowser={true}/>

            <hr />
            <br />
            <h3>Preview Content</h3><small>visible before payment</small>
            <MediaUploader largePreview={true} state={state[1]} useMediaBrowser={true} />


        </ContentDiv>
    </UtilModal>
})