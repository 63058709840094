import React, {useContext} from "react"
import {ChatStore} from "../store/ChatStore";
import {LiveChatStore} from "../store/LiveChatStore";

export type TChatContext = {
    chat: ChatStore,
}

export type TLiveChatContext = {
    livechat: LiveChatStore
}

export const ChatContext = React.createContext<TChatContext>(undefined!)
export const useChatContext = () => useContext<TChatContext>(ChatContext)

export const LiveChatContext = React.createContext<TLiveChatContext>(undefined!)
export const useLiveChatContext = () => useContext<TLiveChatContext>(LiveChatContext)

