import React, {useEffect} from "react"

import "./LiveChatIconLayout.scss"
import {observer} from "mobx-react";
import { useLiveChatContext} from "../context/ChatContext";
import { UtilLiveChatIcon } from "./UtilLiveChatIcon";

const IframeWindow = (props: { src: string }) => {
    return <>
        <div className={"chat__live__window"}>
            <iframe title="LiveChat" className={"chat__live__iframe"} src={props.src}
                    frameBorder="0" />
        </div>
        <div className={"chat__live__window__overlay"}>
        </div>
    </>
}

export const LiveChatBadgeLayout = observer((props: { text: string }) => {
    // const [unreadCount, setUnreadCount] = useState<number|null>(null);
    const {livechat } = useLiveChatContext()

    const unread = livechat.getUnreadMessages()
    return <>
        { props.text }
        { unread ?
            <span className="menu-item-alert">{unread}</span>
            : null
        }
    </>
        // <span style={{position: "relative"}}>
        //     <span style={{
                    //         position: "absolute",
                    //         top: '-20px',
                    //         left: '-3px',
                    //         padding: '4px 10px',
                    //         backgroundColor: "red",
                    //         color: "white",
                    //         fontWeight: "bold",
                    //         fontSize: "100%",
                    //         borderRadius: '20px',
                    //     }}>
                    //         { unread }
                    //     </span>
                    // </span>
})

export const LiveChatIconLayout = observer(() => {
    const {livechat } = useLiveChatContext()

    const toggleOpen = livechat.toggleOpen.bind(livechat)

    useEffect(() => {
        if (livechat.open) {
            const onMessage = (ev: MessageEvent) => {
                const msg = ev.data
                if (msg.event === "setActiveChannel")
                    if (msg.channelId === 0 && msg.prevChannelId>0) {
                        toggleOpen(false)
                    }
            }
            window.addEventListener('message', onMessage)
            return () => {
                window.removeEventListener('message', onMessage)
            }
        }
    }, [livechat.open, toggleOpen])

    return (<>
       { livechat.open && livechat.iframeUrl ?
           <IframeWindow src={livechat.iframeUrl}/>
           : null   }
            <div className={"chat__live__closed__icon "
            + (livechat.getUnreadMessages() ? "chat__live__closed__icon__active" : "")} onClick={
                () => livechat.toggleOpen()
            }>
                <UtilLiveChatIcon width={60} height={60} />
            </div>
        </>
    )
})