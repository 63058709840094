import * as Blocks from './BlockTypes'

export const composeBlocks = (...blocks: (Blocks.Any)[]) : Blocks.Message => {
    return { blocks: blocks, }
}

// pending uploads
export const CHATUPLOAD_PREFIX = "chatupload://"
// processed
export const CHATSTORAGE_PREFIX = "chatstorage://"

