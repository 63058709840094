import React, {useEffect} from "react"
import styled from "styled-components";
import {observer} from "mobx-react";
import {TEditorProps} from "./ChannelMessageInput";
import {useChatContext} from "../../context/ChatContext";
import {UtilModal} from "../Modal/UtilModal";
import {PaidVideo} from "../../post-blocks/BlockTypes";
import {CustomRequest, CustomResponse} from "../../client/ngcht";
import {PaidVideoDiv} from "../PostBlocks/PaidVideoDiv";

import * as BlockTypes from '../../post-blocks/BlockTypes'

const ContentDiv = styled.div`
    font-size: 16px;
    
    h2 { display: inline; padding-right: 10px; font-size: 22px; }
   // .head-input { font-size: 22px; border: solid 1px lightgrey; }
   //
   // @media (max-width: 787px) {
   //   .head-input { width: 87%; }
   // }
   //
   // h3 { display: inline; padding-right: 10px; font-size: 18px; }
   //
   //.error-input { border: solid 2px red; }
   //
  
  .closeBtn {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
  }
  
  .sendBtn {
    //position: absolute;
    //right: 20px;
    //bottom: 20px;
    font-size: 20px;
    font-weight: bold;
    margin-left: 5px;
    padding-left: 20px; 
    padding-right: 20px;
    //color: black;
    border: solid 1px lightgrey;
  }
  
  .error-msg {
    color: red;
    font-size: 20px;
    font-weight: bold;
    text-align: right;
    line-height: 1.2em;
  }
`

const PaidVideoContainerDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100%;
    flex-basis: 100%;
  
    .chat__paid__block__list__item {
      position: relative;;
      min-width: 200px;
      min-height: 200px;
      flex: 1 1 0;
      border: solid 1px lightgray;      
    }
    .chat__paid__block__list__item__selected {
      background-color: lightyellow;
    }

  .chat__paid__block__list__item__price {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 4em;
    text-align: right;
    color: green;
    font-size: 16px;
    border: solid 1px transparent;;
  }
  input.chat__paid__block__list__item__price {
    border: solid 1px lightgray;
  }

  .chat__paid__block__list__item__btn {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }

  .chat__paid__block__list__item__send {
    position: absolute;
    right: 5px;
    bottom: 32px;
    font-weight: bold;
  }
`

type TSelectState = {
    error: string,
    postKey: string,
    isPostsLoaded: boolean,
    paidPosts : { [key:string] : BlockTypes.Message }
    priceOverride: {[key:string] : string},
    titleOverride: {[key:string] : string},
}

const defaultState : TSelectState = {
    error: "",
    postKey: "",
    isPostsLoaded: false,
    paidPosts: {},
    priceOverride: {},
    titleOverride: {},
}

export const EditorSelectPaidPost = observer(( props: TEditorProps) => {
    const {setState, getState, setBlocks, sendMsg, close } = props

    const { chat } = useChatContext()

    let state : TSelectState = (getState!() as any)
    if (!state ) state = { ...defaultState }

    useEffect(() => {
        const customreq = CustomRequest.fromJSON({})
        customreq.customRequestType = "paid_video"
        customreq.request = "browse"
        chat.send(customreq).then((r) => {
            const resp = r as CustomResponse
            const x = JSON.parse(resp.responseData)
            let state1 : TSelectState = (getState!() as any)
            if (!state1 ) state1 = { ...defaultState }
            setState!({...state1, paidPosts: x, isPostsLoaded: true})
        }).catch(e => {
            let state1 : TSelectState = (getState!() as any)
            if (!state1 ) state1 = { ...defaultState }
            setState!({...state1, error: "loading error: " + e.toString(), isPostsLoaded: true})
        })
    }, [chat, getState, setState])

    // const sendBtnActive = (!!state.postKey)

    const setPrice = (postKey: string, s: string) => {
        let state : TSelectState = (getState!() as any)
        console.log({...state, priceOverride : {...state.priceOverride, postKey: s}})
        setState!({...state, priceOverride : {...state.priceOverride, [postKey]: s}})
    }
    const setTitle = (postKey: string, s: string) => {
        let state : TSelectState = (getState!() as any)
        console.log('setTitle', postKey, s)
        setState!({...state, titleOverride : {...state.titleOverride, [postKey]: s}})
    }

    const getPrice = (k: string) : string => {
        // @ts-ignore
        return state.priceOverride[k] !== undefined ? state.priceOverride[k] : state.paidPosts[k].blocks[0].price
    }
    const getTitle = (k: string) : string => {
        // @ts-ignore
        return state.titleOverride[k] !== undefined ? state.titleOverride[k] : state.paidPosts[k].blocks[0].title
    }

    const sendClick = () => {
        const st = (getState!() as TSelectState)
        const selectedPost = st.paidPosts[st.postKey]
        const price = getPrice(st.postKey)
        const title = getTitle(st.postKey)
        selectedPost.blocks = selectedPost.blocks.map(bl => {
            (bl as PaidVideo).price = price;
            (bl as PaidVideo).title = title;
            return bl
        })
        setBlocks!(selectedPost.blocks)
        sendMsg!()
    }

    const clickBlock = (k: string, target: EventTarget) => {
        if (((target as any).type==="submit") && ((getState!() as TSelectState)?.postKey === k)) {
            k = ""
        }
        setState!({...state, postKey: k})
    }

    const posts = Object.entries(state.paidPosts).map(([k, pv]) => {
        const [paidBlock] = pv.blocks
        let x: any = "error"
        if (typeof paidBlock === "object") {
            const pb = paidBlock as PaidVideo
            const selected = k === state.postKey
            x = selected ?
                <PaidVideoDiv blk={pb} key={k} titleEditable={true} onTitleChange={(s) => setTitle(k, s)}/>
                : <PaidVideoDiv blk={pb} key={k}/>
            return <div
                className={"chat__paid__block__list__item " + (selected?" chat__paid__block__list__item__selected":"")}
                onClick={(ev) => clickBlock(k, ev.target)}>
                {x}
                {selected ?
                    <input className={"chat__paid__block__list__item__price"}
                           value={getPrice(k)} title={"Price, $$"}
                           onChange={(ev) => setPrice(k, ev.target.value)}
                    /> :
                    <div className={"chat__paid__block__list__item__price"}>${getPrice(k)}</div>
                }
                { selected ?
                    <button className={"chat__paid__block__list__item__send"} onClick={sendClick}>Send</button>
                    : null }
                <button className={"chat__paid__block__list__item__btn"}>Choose</button>
            </div>
        } else {
            return 'invalid object'
        }
    })

    return <UtilModal>
        <ContentDiv>
            <h2>Send Paid Post</h2>
            <span>choose a post, change price if necessary and click "Send"</span>
            {/*<button className={"sendBtn"} disabled={!sendBtnActive} onClick={sendClick}>SEND</button>*/}
            <br /><br />

            <PaidVideoContainerDiv>
            {
                !state.isPostsLoaded ?
                <span>Loading posts....</span> :
                posts
            }
            </PaidVideoContainerDiv>

            <button className={"closeBtn"} onClick={close} title="close">x</button>

        </ContentDiv>
    </UtilModal>
})