/// livechat iframe contents

import React from "react"

import "./LiveChatLayout.scss"
import {observer} from "mobx-react";
import {Channel} from "../components/Channel";
import {ChannelHeader} from "../components/ChannelHeader";
import {ChannelMessages} from "../components/ChannelMessages";
import {ChannelMessageInput} from "../components/ChannelMessageinput/ChannelMessageInput";
import {EditorPlaintext} from "../components/ChannelMessageinput/EditorPlaintext";
import {EditorCustomerTip} from "../components/ChannelMessageinput/EditorCustomerTip";
import {EditorMediaUploader} from "../components/ChannelMessageinput/EditorMediaUploader";
import {EditorNoEditor} from "../components/ChannelMessageinput/EditorNoEditor";
import {useChatContext} from "../context/ChatContext";

const customerEditors = [
    { id: "text", title: "Text", editor: <EditorPlaintext /> },
    { id: "customer-tip", icon: "dollar-sign", title: "Send Tip", editor: <EditorCustomerTip /> },
    { id: "media-upload", icon: "image", title: "Media Upload",   editor: <EditorMediaUploader /> }
]


export const LiveChatLayout = observer(() => {
    const {chat} = useChatContext()

    let editors : React.ReactNode = null
    if (chat.me?.getUserCan("message")) {
        editors = <ChannelMessageInput>{customerEditors}</ChannelMessageInput>
    } else {
        editors = <EditorNoEditor />
    }

    return <div className={"chat__live__window__in__iframe"}>
            <Channel>
                <ChannelHeader />
                <ChannelMessages />
                { editors }
            </Channel>
    </div>
})