import React from "react"

import { formatDistance } from 'date-fns'

export const RelTime = (props: {
    date : Date | string,
    interval ?: number
}) => {
    let date : Date
    if (typeof props.date === "string") {
        date = new Date(props.date)
    } else {
        date = props.date
    }

    return <>
        {formatDistance(date!, new Date())}
    </>
}