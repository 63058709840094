import {UploadFileSelector} from "./UploadFileSelector";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useChatContext} from "../../context/ChatContext";
import {useEffect} from "react";
import {MediaUploaderFileItem} from "./MediaUploaderFileItem";
import {UploaderState} from "../../store/UploaderState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Puff } from 'react-loading-icons';

export const MediaUploaderDiv = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    width:  98%;
  
  .chat-media-uploader-input { display: none; }
  
  
  .chat-media-post-buttons {
      width: 100%;
      button {
        color: var(--bg-color);
        background-color: var(--gray-color);
        border: none;
        font-size: 20px;
        font-weight: 800;
        padding: 8px 16px;
        border-radius: 1000px;

        margin-right: 4px;
        margin-bottom: 4px;

        &.active {
          background-color: var(--btn-active-color)
        }
      }
    }

  
  .media-uploader-file-items {
    // width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
  }

  .media-uploader-file-add, .media-uploader-file-select {
    height: 56px;
    width: 48px;
    margin: 2px;
    padding-left: 8px;
    padding-top: 8px;
    color: #6c757d;
  }
  
  .media-uploader-file-item {
    position: relative;
    height: 64px;
    min-width: 60px;
    max-width: 128px;
    display: flex;
    justify-content: center;
    float: left;
    box-sizing: border-box;
  }
  .media-uploader-file-item-error {
    border: solid red 5px;
    img, svg {
      filter: grayscale() blur(1px);
    }
  }

  .media-uploader-file-item img {
    height: 64px;
    min-width: 40px;
    max-width: 128px;
  }

  .media-uploader-file-item img, .media-uploader-file-item svg {
    height: 60px;
  }
  
  @keyframes upload_blink {
    from { color: #eee; }
    to { color #555; }
  }
  .icon-uploading-progress {
    color: #eee;
    animation-name: upload_blink;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
  }
 
  .media-uploader-file-item-waiting, .media-uploader-file-item-uploading {
    opacity: 50%;
  }

  .media-uploader-file-succ, .media-uploader-file-del, .media-uploader-file-progress {
    color: gray;
    position: absolute;
    height: 1em !important;
    padding: 1px;
    mix-blend-mode: exclusion;
  }
  .media-uploader-file-del {
    bottom: 2px;
    right: 2px;
  }
  .media-uploader-file-progress {
    top: 2px;
    left: 2px;
  }
  .media-uploader-file-succ {
    left: 2px;
    bottom: 2px;
  }

  .media-uploader-file-items-large {
    .media-uploader-file-add, .media-uploader-file-select {
      height: 140px;
      padding-top: 40px;
    }
    .media-uploader-file-select-active {
      color: #0091ea;;
    }
    .media-uploader-file-item {
      height: 140px;
      min-width: 60px;
      max-width: 280px;
    }
    .media-uploader-file-item img, .media-uploader-file-item .upload-video-icon {
      height: 140px;
      min-width: 60px;
      max-width: 280px;
    }
  }
`


export const MediaUploader = observer((props: {
    largePreview ?: boolean,
    state: UploaderState,
    useMediaBrowser ?: boolean,
    showProgress ?: boolean,
}) => {

    const {state} = props

    const chat = useChatContext().chat

    const onSelected = (f: File) => chat.uploaderStore.upload(f, chat.getActiveChannelId(),undefined, state)

    const mediaBrowser = chat.mediaBrowser

    useEffect(() => {
        return () => mediaBrowser.clearListener(state)
    }, [mediaBrowser, state]);

    const toggleMediaSelect = () => mediaBrowser.registerListener(state.addBlocks.bind(state), state)

    const thisMediaBrowseSelected =  mediaBrowser.sameListener(state)

    return <MediaUploaderDiv>
        <div className={
            props.largePreview ? "media-uploader-file-items media-uploader-file-items-large" : "media-uploader-file-items"}
        >
            <label>
                <div className="media-uploader-file-add">
                    {state.isLoading() ?
                    <Puff fill="transparent" stroke="gray" speed={1} strokeWidth={"2"} /> :
                    <UploadFileSelector
                        multiple={true}
                        onSelected={ onSelected }
                    /> }
                </div>
            </label>
            { props.useMediaBrowser ?
            <label onClick={toggleMediaSelect}>
                <div className={"media-uploader-file-select " + (thisMediaBrowseSelected ? " media-uploader-file-select-active " : "")}>
                    <FontAwesomeIcon icon={"plus-square"} size={"2x"} />
                </div>
            </label> : null }
            { state.getSorted().map((fileRec) => <MediaUploaderFileItem
                key={fileRec.id}
                thumbUrl={fileRec.thumbUrl}
                progress={fileRec.progress}
                error={fileRec.error}
                uploading={fileRec.uploading}
                hideSuccess={false}
                onDeleteClick={ () => { state.delete(fileRec.id) } }
            />
            ) }
        </div>
    </MediaUploaderDiv>

})