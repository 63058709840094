import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {FC, ReactNode} from "react"
import styled from "styled-components";
import { ReactBigPicture } from "../ReactBigPicture";

import * as Blocks from '../../post-blocks/BlockTypes'
import {PaidVideoDiv} from "./PaidVideoDiv";
import {PaidTipDiv} from "./PaidTipDiv";

const PostBlocksDiv = styled.div`
    display: flex;  
    margin: 5px;
    flex-flow: column nowrap;
  
    .chat_post_blocks_img_preview {
      max-width: 200px;
      max-height: 200px;
    }
`

type TBlocksJson = {
    blocks?: object[]
}

const notifyImgLoad = (ev: any) => {
    const exv = new CustomEvent("chat.img.load", {
        detail: ev.target,
    })
    document.dispatchEvent(exv)
}

const CustomerPaidForVideo : FC<{blk: Blocks.CustomerPaid}> = ({blk}) => {
    return <span className="tip" style={{color: '#6B8068', fontWeight: 'bold', }}>
            Paid for {blk.for} <FontAwesomeIcon icon={"dollar-sign"} /> {blk.price}
    </span>
}

const renderBlock = (blk: Blocks.Any, key: number, onlyMedia ?: boolean ) : ReactNode|null => {
    if (typeof blk === "string") {
        return <span key={key}>{blk}</span>
    } else {
        switch (blk.type) {
            case "video":
                return <ReactBigPicture key={key} src={blk.video_url} type="video" style={{minHeight: 200, }}>
                    {blk.poster_url ?
                        <img src={blk.poster_url} alt={blk.alt_text}
                             onLoad={notifyImgLoad}
                             loading="lazy"
                             className="chat_post_blocks_video_preview"/> :
                        <FontAwesomeIcon icon={"video"} size={"2x"} style={{color:"gray"}}/>
                    }
                </ReactBigPicture>
            case "image":
                return <ReactBigPicture key={key} src={blk.image_url} type="image" style={{minHeight: 200, }}>
                    <img src={blk.thumb_url ? blk.thumb_url : blk.image_url} alt={blk.alt_text}
                         onLoad={notifyImgLoad}
                         loading="lazy"
                         className="chat_post_blocks_img_preview" />
                </ReactBigPicture>
            case "plain_text":
                return <span key={key}>{blk.text}</span>
            case "paid_video":
                return <PaidVideoDiv blk={blk} key={key} onlyMedia={onlyMedia}/>
                //return <FontAwesomeIcon icon={"lock"} size={"2x"} style={{"color": "lightgray"}}/>
            case "customer_tip":
                return <PaidTipDiv blk={blk} key={key} />
                // return <span>$$$ TIP $$$ {blk.price} {blk.paid ? "SENT" : "PENDING"} {(blk as any).paymentLink}</span>
            //return <FontAwesomeIcon icon={"lock"} size={"2x"} style={{"color": "lightgray"}}/>
            case "customer_paid":
                return <CustomerPaidForVideo blk={blk} key={key} />
            default:
                // ts trick to check that all known types are listed above
                const _exhaustiveCheck: never = blk;
                return _exhaustiveCheck;
        }
    }
}

export function PostBlocks(props: {body: TBlocksJson, onlyMedia ?: boolean }) {
    const { blocks } = props.body

    const elements : ReactNode[] = []

    if (!blocks || !blocks.length) return <>!!invalid-json!!</>

    blocks.forEach((blk: any, idx) => {
        if (typeof blk.type === "string" || typeof blk === "string")
        {
            const el = renderBlock(blk, idx, props.onlyMedia)
            if (el) elements.push(el)
        }
   })

    return <PostBlocksDiv>
        { elements }
    </PostBlocksDiv>
}
