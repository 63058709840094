import {action, makeAutoObservable} from "mobx";
import Cookies from "js-cookie";

//import Cookies from 'js-cookie'

type LiveChatOptions = {
    token: string
    chatKey: string
    userId: string
    clientId: string
    apiUrl: string
    livechatRefresh: number
}

function utf8_to_b64( str: string ) : string {
    return window.btoa(unescape(encodeURIComponent( str )));
}

function b64_to_utf8( str : string ) : string {
    return decodeURIComponent(escape(window.atob( str )));
}

const withCookieCache = async (callback: () => Promise<object> | object , _id : string, seconds: number ) => {
    const id = utf8_to_b64("cache:"+_id)
    const c = seconds > 0 ? Cookies.get(id) : undefined
    if (c !== undefined) {
        return JSON.parse(b64_to_utf8(c))
    }
    const x : any= await callback()
    const d = new Date((new Date()).getTime()+seconds*1000)
    if (seconds > 0) {
        Cookies.set(id, utf8_to_b64(JSON.stringify(x)), {expires: d})
    }
    return x
}

type TLiveChatData = {
    channelTitle ?: string,
    unread : number,
    channelId: number,
    lastMessage ?: {
    }
    iframeUrl: string
}

export class LiveChatStore {
    private token: string
    private chatKey: string
    private userId: string
    private clientid: string
    private apiUrl: string
    private livechatRefresh: number

    private connectError = false



    // receive from api call
    private liveChatData : Partial<TLiveChatData> = {}

    private unread ?: number
    public open = false

    public iframeUrl ?: string

    constructor(options: LiveChatOptions) {
        // console.log(options)

        this.token = options.token
        this.chatKey = options.chatKey
        this.userId = options.userId
        this.clientid = options.clientId
        this.apiUrl = options.apiUrl
        this.livechatRefresh = options.livechatRefresh * 1000

        makeAutoObservable(this)
    }

    async connect() {
        try {
            this.liveChatData = await withCookieCache(
                async () => await this.callApi("livechat-status"),
                this.chatKey+"#"+this.userId, 1)
            if (!this.liveChatData.unread)
                this.liveChatData.unread = 0
            action(() => {
                this.unread = this.liveChatData.unread
                this.iframeUrl = this.liveChatData.iframeUrl
            })()
            if (this.livechatRefresh)
                setTimeout(this.connect.bind(this), this.livechatRefresh )
        } catch (e) {
            console.error(e)
            this.connectError = true
        }
    }

    async callApi(method: string) : Promise<object> {
        // cache

        const ret = await fetch(this.apiUrl + "/livechat/fetch", {
            headers: {
                'authorization' : "token " + this.token,
            }
        })
        if (ret.status !== 200) {
            throw new Error('got error response status: '+ ret.status )
        }
        const json = await ret.json()
        return json
    }


    getUnreadMessages() : number {
        return this.unread || 0
    }

    toggleOpen(value = true) {
        this.open = value
    }
}