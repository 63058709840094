import React from "react"
import styled from "styled-components";

const CenteredDiv = styled.div`
  height: 100%;
  
  display: flex;
  justify-content: center;
  flex-direction: column;

  font-size: 22px;
  font-weight: bold;
  text-align: center;
`

// const SendButton = styled.button`
//     color: var(--bg-color);
//     background-color: var(--btn-active-color);
//     border: none;
//     font-size: 20px;
//     font-weight: 800;
//     padding: 8px 16px;
//     border-radius: 1000px;
//
//     margin-top: 16px;
// `

export const ChannelNotSelected = (props: {} ) => {

    return (
        <CenteredDiv>
            <div>
                Select any Conversation or send a New Message
                {/*<SendButton>New Message</SendButton>*/}
            </div>
        </CenteredDiv>
    )
}