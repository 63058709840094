import React from "react"
import {TEditorProps} from "./ChannelMessageInput";
import {observer} from "mobx-react";

export const EditorNoEditor = observer(( props: TEditorProps) => {
    return <>
    <span style={{
        padding: 5,
        paddingTop: 40,
        paddingBottom: 40,
        maxWidth: '100%',
        backgroundColor: "var(--foreign-message-color)"}}>
        Please <a rel="noreferrer" href={"https://putricinta.com/join"} target={"_blank"}>renew your subscription</a> to continue messaging
    </span>
    </>
})